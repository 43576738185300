import React from 'react';
import { Helmet } from 'react-helmet';
import { FiShoppingCart } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import './CheckoutPage.scss';

export const CheckoutEmpty: React.FC = () => {
  return (
    <div className="ProfilePage">
      <Helmet>
        <title>The Giftery | Checkout</title>
      </Helmet>
      <div className="Profile">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <div className="text-center flex flex-col items-center">
            <h1 className="mt-1 text-4xl font-extrabold font-serif text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
              Your cart is empty
            </h1>
            <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
              Why don't you take a look around?
            </p>
            <Link
              to="/"
              className="mt-4 bg-primary-400 py-3 px-6 text-xl text-white flex flex-row items-center"
            >
              <FiShoppingCart size={24} className="mr-2" />
              Shop
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
