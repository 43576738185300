import { format } from 'date-fns';
import { enNZ } from 'date-fns/locale';
import React, { useEffect, useRef, useState } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { Helmet } from 'react-helmet';
import profilePlaceholder from '../../images/profile-placeholder.png';
import { ImageUpload, ImageUploadMethods } from '@giftery/ui/image-upload';
import './ProfilePage.scss';
import { find, includes } from 'lodash';
import { storage } from '@giftery/firebase';
import { FiUser } from 'react-icons/fi';
import { LoadingButton } from '@giftery/ui/loading-button';
import { User } from '@giftery/api-interface';
import { useFirestore } from 'react-redux-firebase';
import { DBCollection } from '@giftery/enums';
import toast from 'react-hot-toast';
import { validURL } from '@giftery/utils';
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from 'react-google-places-autocomplete';
import { Place } from '@googlemaps/google-maps-services-js';
import { useMe } from '../../hooks';
import { colors } from '@giftery/theme';

const currentYear = new Date().getFullYear();
const fromMonth = new Date(currentYear, 0);
const toMonth = new Date(currentYear + 10, 11);

const YearMonthForm = ({ date, localeUtils, onChange }) => {
  const months = localeUtils.getMonths();

  const years = [];
  for (
    let i = fromMonth.getFullYear();
    i >= toMonth.getFullYear() - 100;
    i -= 1
  ) {
    years.push(i);
  }

  const handleChange = function handleChange(e) {
    const { year, month } = e.target.form;
    onChange(new Date(year.value, month.value));
  };

  return (
    <form className="DayPicker-Caption">
      <select name="month" onChange={handleChange} value={date.getMonth()}>
        {months.map((month, i) => (
          <option key={month} value={i}>
            {month}
          </option>
        ))}
      </select>
      <select name="year" onChange={handleChange} value={date.getFullYear()}>
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </form>
  );
};

const ProfilePage: React.FC = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [month, setMonth] = useState<Date>(null);
  const [birthday, setBirthday] = useState<Date>(null);
  const [email, setEmail] = useState('');
  const [profilePic, setProfilePic] = useState('');
  const [profilePicId, setProfilePicId] = useState('');
  const me = useMe();

  const [googlePlace, setGooglePlace] = useState<{
    label: string;
    value: Place;
  }>();
  const [address, setAddress] = useState(me?.address?.address ?? '');
  const [city, setCity] = useState(me?.address?.city ?? '');
  const [postcode, setPostcode] = useState(me?.address?.postcode ?? '');
  const [region, setRegion] = useState(me?.address?.region ?? '');

  const firestore = useFirestore();

  // In order to gain access to the child component instance,
  // you need to assign it to a `ref`, so we call `useRef()` to get one
  const imageUploadRef = useRef<ImageUploadMethods>();

  useEffect(() => {
    if (!me || isLoaded) return;
    reset();
  });

  const getProfilePic = async (id = me?.avatarUrl) => {
    if (!id) {
      setProfilePic(profilePlaceholder);
      return;
    }
    if (!validURL(id)) {
      const imageUrl = await storage.ref(`/images/${id}`).getDownloadURL();
      setProfilePicId(id);
      setProfilePic(imageUrl);
    } else {
      setProfilePic(id);
    }
  };

  const findAddressComponent = (
    addressComponents: google.maps.GeocoderAddressComponent[],
    type: string | string[]
  ) => {
    return find(addressComponents, (component) =>
      includes(component.types, type)
    );
  };

  useEffect(() => {
    if (googlePlace && googlePlace.value.place_id) {
      geocodeByPlaceId(googlePlace.value.place_id)
        .then((results) => {
          const addressComponents = results[0]?.address_components;
          if (!addressComponents) return;
          // Extract address details from results
          // 1. Get Address
          const streetNumber = findAddressComponent(
            addressComponents,
            'street_number'
          );
          const streetName = findAddressComponent(addressComponents, 'route');
          if (streetNumber && streetName) {
            setAddress(`${streetNumber.long_name} ${streetName.long_name}`);
          } else if (!streetNumber && streetName) {
            setAddress(`${streetName.long_name}`);
          } else if (streetNumber && !streetName) {
            setAddress(`${streetName.long_name}`);
          }

          // 2. Get city
          const city =
            findAddressComponent(addressComponents, 'locality') ||
            findAddressComponent(addressComponents, 'political');
          setCity(city?.long_name ?? null);
          // 3. Get postcode
          const postcode = findAddressComponent(
            addressComponents,
            'postal_code'
          );
          setPostcode(postcode?.long_name ?? null);
          // 4. Get Region
          const region = findAddressComponent(
            addressComponents,
            'administrative_area_level_1'
          );
          setRegion(region?.long_name ?? null);
        })
        .catch((error) => console.error(error));
    }
  }, [googlePlace]);

  const reset = async () => {
    if (!me) return;
    await getProfilePic();
    setName(me.displayName || '');
    setEmail(me.email || '');
    setProfilePicId(me.avatarUrl || '');
    setAddress(me.address?.address || address);
    setCity(me?.address?.city || city);
    setRegion(me.address?.region || region);
    setPostcode(me.address?.postcode || postcode);
    setBirthday((me.birthday as FirebaseFirestore.Timestamp)?.toDate() || null);
    imageUploadRef?.current?.reset();
    setIsLoaded(true);
  };

  const setDate = (day: Date) => {
    setBirthday(day);
  };

  const handleYearMonthChange = (month) => {
    setMonth(month);
  };

  const save = async () => {
    setLoading(true);
    try {
      const update: Partial<User> = {
        address: {
          isRural: false,
          address,
          city,
          postcode,
          region,
          country: 'New Zealand',
        },
        displayName: name,
        email,
        birthday,
        avatarUrl: profilePicId ?? null,
      };

      await firestore.collection(DBCollection.users).doc(me.id).update(update);
      setIsLoaded(false);
      await reset();
      toast.success('Successfully updated your profile');
    } catch (err) {
      console.error(err);
      toast.error(
        err?.response?.message ||
          'An unknown error occurred. Please try again later.'
      );
    }
    setLoading(false);
  };

  return (
    <div className="ProfilePage w-full px-2 md:px-20">
      <Helmet>
        <title>The Giftery | Profile</title>
      </Helmet>
      <div className="Profile">
        <div className="grid grid-cols-12">
          <div className="col-span-12 md:col-span-4 p-2 md:p-6">
            <div className="ProfilePicContainer">
              <div className="ProfilePic">
                {profilePic ? (
                  <img src={profilePic || null} alt={me?.displayName} />
                ) : (
                  <FiUser size={64} />
                )}
              </div>
              <div className="Upload">
                <ImageUpload
                  ref={imageUploadRef}
                  onRequestClear={() => setProfilePic(null)}
                  limit={1}
                  label="Drag and drop your profile picture here or "
                  onRequestRevert={() => setProfilePic(null)}
                  onRequestSave={(id: string) => getProfilePic(id)}
                  defaultFiles={[]}
                />
              </div>
            </div>
          </div>
          <div className="col-span-12 md:col-span-8 p-2 md:p-6 flex flex-col justify-center">
            <div className="mt-8">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-primary-500"
              >
                Name
              </label>
              <div className="mt-1">
                <input
                  id="name"
                  name="name"
                  type="name"
                  autoComplete="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="
                py-2 px-0 shadow-none outline-none
                text-primary-500
                border-l-0 border-t-0 border-r-0 border-b border-primary-300
                focus:border-primary-500 focus:border-b-2
                block w-full sm:text-xl"
                />
              </div>
            </div>
            <div className="mt-8">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-primary-500"
              >
                Email Address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="
                py-2 px-0 shadow-none outline-none
                text-primary-500
                border-l-0 border-t-0 border-r-0 border-b border-primary-300
                focus:border-primary-500 focus:border-b-2
                block w-full sm:text-xl"
                />
              </div>
            </div>
            <div className="mt-8">
              <div>
                <label
                  htmlFor="birthday"
                  className="block text-sm font-medium text-primary-500"
                >
                  Birthday
                </label>
                <div className="mt-1">
                  <DayPickerInput
                    onDayChange={setDate}
                    value={birthday}
                    placeholder="When is your birthday?"
                    inputProps={{
                      name: 'birthday',
                      autoComplete: 'false',
                      className:
                        'text-primary-600 py-2 px-0 shadow-none outline-none text-primary-500 border-l-0 border-t-0 border-r-0 border-b border-primary-300 focus:border-primary-500 focus:border-b-2 block w-full sm:text-xl',
                    }}
                    formatDate={(d) =>
                      format(d, 'dd/MM/yyyy', { locale: enNZ })
                    }
                    dayPickerProps={{
                      month,
                      fromMonth,
                      toMonth,
                      captionElement: ({ date, localeUtils }) => (
                        <YearMonthForm
                          date={date}
                          localeUtils={localeUtils}
                          onChange={handleYearMonthChange}
                        />
                      ),
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="mt-8">
              <div className="bg-primary-100 p-4">
                <label className="text-primary-500 font-bold block">
                  Current Address
                </label>
                <label className="text-primary-500">
                  {address}, {city} {postcode}
                </label>
              </div>
              <label
                htmlFor="first_name"
                className="block text-sm font-medium text-primary-500 mt-4 px-2"
              >
                Update Address
              </label>
              <GooglePlacesAutocomplete
                apiOptions={{ language: 'en', region: 'NZ' }}
                autocompletionRequest={{
                  componentRestrictions: {
                    country: ['nz'],
                  },
                }}
                apiKey="AIzaSyBOt_oMTA05lsT5SgmOFs27iXokXZJAKJo"
                selectProps={{
                  googlePlace,
                  placeholder: 'Search for address...',
                  onChange: setGooglePlace,
                  styles: {
                    container: (existing) => ({
                      ...existing,
                      boxShadow: 'none',
                    }),
                    dropdownIndicator: () => ({
                      display: 'none',
                    }),
                    indicatorSeparator: () => ({
                      display: 'none',
                    }),
                    control: (existing) => ({
                      ...existing,
                      borderRadius: 0,
                      borderWidth: '0 0 1px 0',
                      borderColor: colors.primary[300],
                      boxShadow: 'none',
                      'input:hover': {
                        borderColor: colors.primary[500],
                        boxShadow: 'none',
                      },
                      'input:focus': {
                        outline: 'none',
                        borderColor: colors.primary[500],
                        boxShadow: 'none',
                      },
                    }),
                    singleValue: () => ({
                      outline: 'none',
                      border: 'none',
                      boxShadow: 'none',
                    }),
                    input: (value) => ({
                      ...value,
                      borderRadius: 0,
                      outline: 0,
                      border: 0,
                      boxShadow: 'none',
                      minWidth: '100px',
                      fontSize: '1.25rem',
                      lineHeight: '1.75rem',
                      'input:hover': {
                        outline: 'none',
                        border: 'none',
                        boxShadow: 'none',
                      },
                      'input:focus': {
                        outline: 0,
                        borderWidth: 0,
                        boxShadow: 'none',
                        borderColor: colors.primary[500],
                      },
                    }),
                  },
                  openMenuOnClick: false,
                  noOptionsMessage: () => 'Start typing to find an address',
                }}
              />
            </div>
            <div className="mt-6">
              <button
                className="col-span-2 text-primary-500 py-2 px-4 text-center no-underline hover:no-underline hover:bg-primary-500"
                onClick={reset}
              >
                Reset
              </button>{' '}
              <LoadingButton
                loading={loading}
                feedback="Saving..."
                className="bg-primary-400 col-span-2 text-white py-2 px-4 text-center no-underline hover:no-underline hover:bg-primary-500"
                onClick={save}
              >
                Save My Details
              </LoadingButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
