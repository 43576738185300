import { Product, WithId } from '@giftery/api-interface';
import React, { useEffect, useState } from 'react';
import { storage } from '@giftery/firebase';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { getRelation } from '@giftery/utils';
import { useProductMetadata } from '../../../hooks';
import { Link } from 'react-router-dom';

interface ProductItemProps {
  product: WithId<Product>;
}

const ProductItem = ({ product }: ProductItemProps) => {
  const [productImages, setProductImages] = useState<string[]>([]);
  const [categoryData, hobbyData] = useProductMetadata();
  const [isHovering, setIsHovering] = useState(false);
  const getImage = async () => {
    const mainImage = product.images[0];
    if (!mainImage) return;

    for (const image of product.images) {
      let imageUrl = image;
      if (image.indexOf('http') <= -1) {
        imageUrl = await storage.ref(`/images/${image}`).getDownloadURL();
      }
      setProductImages((existing) => [...existing, imageUrl]);
    }
  };
  const productImage = productImages?.[0];

  useEffect(() => {
    getImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <a
      target="_top"
      href={`https://shop.thegiftery.co.nz/product/${product.id}`}
      className="h-80 object-cover object-center bg-white text-center relative overflow-hidden"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <LazyLoadImage
        effect="blur"
        alt={product.name}
        src={productImage}
        className="h-full object-cover object-center z-10 w-full h-80"
        wrapperClassName="h-full object-cover w-full"
        // If the image we are creating here has the same src than before,
        // we can directly display it with no need to lazy-load.
      />
      {isHovering ? (
        <div className="p-10 h-full w-full bg-grey-800 bg-opacity-80 flex flex-col items-center justify-center text-white z-50 absolute bottom-0 left-0 right-0 top-0">
          <div className="font-sans text-2xl font-normal">{product.name}</div>
          <div className="font-sans text-uppercase font-bold">
            {getRelation(hobbyData, product.hobbies[0], 'name')}
          </div>
        </div>
      ) : null}
    </a>
  );
};

export default ProductItem;
