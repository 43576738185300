import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useRouteMatch } from 'react-router';
import { registerForUpdates } from '../../actions/Orders';
import { useOrder } from '../../hooks';

export const GiftAlreadySelected = () => {
  const [phone, setPhone] = useState('');
  const { params } = useRouteMatch<{ id: string }>();
  const order = useOrder(params.id);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    await registerForUpdates(params.id, phone);
  };

  return (
    <div className="mx-auto px-6 md:px-20">
      <Helmet>
        <title>The Giftery | Select Your Gift</title>
      </Helmet>
      <div className="bg-transparent py-16 sm:py-24">
        <div className="relative sm:py-16">
          <div className="mx-auto max-w-md sm:max-w-3xl lg:max-w-7xl">
            <div className="relative rounded-theme px-6 py-10  overflow-hidden sm:px-12 sm:py-20">
              <div aria-hidden="true" className=""></div>
              <div className="relative">
                <div className="sm:text-center flex flex-col">
                  <h2 className="text-3xl font-extrabold text-primary tracking-tight sm:text-4xl text-primary-500">
                    Your gift has been selected.
                  </h2>
                  <p className="mt-6 mx-auto max-w-2xl text-lg text-primary-400">
                    We'll send you an email once your order is dispatched, in
                    the meantime, have a look around!
                  </p>
                  <a
                    href="/"
                    className="mt-6 flex-shrink-0 border border-transparent px-5 py-3 bg-primary-500 font-medium text-white hover:bg-primary-400 focus:outline-none w-96 inline-block mx-auto"
                  >
                    Shop Now
                  </a>
                </div>
                {/* <form
                  onSubmit={onSubmit}
                  className="mt-12 sm:mx-auto sm:max-w-lg sm:flex align-center justify-center"
                >
                  <div className="min-w-0 flex-1 flex items-center justify-center">
                    <label htmlFor="cta_phone" className="sr-only">
                      Phone Number
                    </label>
                    <input
                      id="cta_phone"
                      type="phone"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      className="block w-full border border-transparent px-5 py-3 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-primary-600"
                      placeholder="Enter your phone number"
                    />
                  </div>
                  <div className="sm:ml-3">
                    <button
                      type="submit"
                      className="block w-full border border-transparent px-5 py-3 bg-primary-500 text-base font-medium text-white hover:bg-primary-400 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-primary-600 sm:px-10"
                    >
                      Notify me
                    </button>
                  </div>
                </form> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
