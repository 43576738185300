import React, { useEffect, useState } from 'react';
import Rating from 'react-rating';
import { AiFillStar } from 'react-icons/ai';
import TextareaAutosize from 'react-textarea-autosize';
import { addProductReview } from '../../actions/Products';
import toast from 'react-hot-toast';
import { Review, User, WithId } from '@giftery/api-interface';
import ProductReview from './ProductReview';
import { FiChevronDown, FiX } from 'react-icons/fi';
import { classNames } from '@giftery/utils';
import useWindowSize from 'react-use/lib/useWindowSize';
import { find } from 'lodash';
import { useMe } from '../../hooks';
import { subDays, isAfter } from 'date-fns';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ProductReviewsProps {
  id: string;
  reviews: WithId<Review & { user: WithId<User> }>[];
}

const ProductReviews: React.FC<ProductReviewsProps> = ({ id, reviews }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [party, setParty] = useState<boolean>(false);
  const [rating, setRating] = useState<number>(0);
  const [ratingHoverValue, setRatingHoverValue] = useState<number>(0);
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const { width, height } = useWindowSize();
  const me = useMe();

  const reset = () => {
    setTitle('');
    setDescription('');
    setRating(0);
    setRatingHoverValue(0);
  };

  const hasRecentReviewFromLoggedInUser = () => {
    const recentUserReview = find(reviews, (review) => {
      const reviewDate = (
        review.audit.updatedAt as FirebaseFirestore.Timestamp
      ).toDate();
      const last24Hours = subDays(new Date(), 1);
      return review.user.id === me?.id && isAfter(reviewDate, last24Hours);
    });
    return !!recentUserReview;
  };

  const isValid = () => {
    return (
      rating &&
      title.trim() !== '' &&
      description.trim() !== '' &&
      !hasRecentReviewFromLoggedInUser()
    );
  };

  const submitReview = async () => {
    const result = await toast.promise(
      addProductReview(id, { rating, title, description }),
      {
        loading: `Submitting your review...`,
        success: `Success! Your review has been received`,
        error: `Error submitting review, please try again`,
      }
    );
    if (result.status >= 200 && result.status <= 204) {
      reset();
    }
  };

  const updateRating = (rating: number) => {
    if (rating === 5 && !party) {
      setParty(true);
      setTimeout(() => setParty(false), 1000);
    }
    setRating(rating);
  };

  return (
    <>
      <div
        className={classNames(
          isOpen ? 'border-primary-200' : 'border-gray-100',
          'bg-white py-5 border-b w-full overflow-hidden'
        )}
      >
        <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
          <div className="ml-4 mt-2">
            <h3 className="text-lg leading-6 font-medium text-primary-400">
              Product Reviews
            </h3>
          </div>
          <div className="ml-4 mt-2 flex-shrink-0">
            <button
              type="button"
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex items-center text-primary-500 hover"
            >
              {isOpen ? (
                <FiX className="stroke-current" />
              ) : (
                <FiChevronDown className="stroke-current" />
              )}
            </button>
          </div>
        </div>
      </div>
      <div
        className={classNames(
          isOpen ? 'h-auto' : 'h-0 overflow-hidden',
          'my-4 mb-16 mt-0 flex flex-col items-between border-t-1 transition-all max-w-full'
        )}
      >
        {reviews.length > 0 && (
          <div className="my-4 flex flex-col items-between border-t-1 p-6 px-0 border-grey-50">
            {reviews.slice(0, 3).map((review) => (
              <ProductReview key={review.id} review={review} />
            ))}
          </div>
        )}
        <div className="mb-3 text-grey-800 p-6 bg-primary-100 w-full">
          <h2 className="text-2xl text-primary-500 font-bold">
            Rate this product
          </h2>
          <div className="flex items-center">
            <div className="text-primary-500 mr-3">Give your rating:</div>

            <Rating
              start={0}
              stop={5}
              step={1}
              readonly={me ? false : true}
              initialRating={rating}
              className="flex items-center mt-2"
              onChange={(rating) => updateRating(rating)}
              onHover={(value) =>
                ratingHoverValue !== value && setRatingHoverValue(value)
              }
              emptySymbol={
                <AiFillStar
                  href="#icon-star-empty"
                  className="fill-current text-primary-300"
                />
              }
              fullSymbol={
                <AiFillStar
                  href="#icon-star-full"
                  className={classNames(
                    rating === 5 || ratingHoverValue === 5
                      ? 'text-yellow-500'
                      : ' text-primary-500',
                    'fill-current'
                  )}
                />
              }
            />
          </div>
          <div className="">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-primary-500"
            >
              Title
            </label>
            <div className="mt-1">
              <input
                id="title"
                name="title"
                type="text"
                value={title}
                disabled={hasRecentReviewFromLoggedInUser() || !me}
                placeholder="Review title"
                onChange={(e) => setTitle(e.target.value)}
                className="
                py-2 px-2 shadow-none outline-none
                border-l-0 border-t-0 border-r-0 border-b border-primary-300
                mb-3 placeholder-primary-200
                focus:border-primary-500 focus:outline-none focus:shadow-none focus:ring-0
                block w-full"
              />
            </div>
          </div>
          <div className="">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-primary-500"
            >
              Review
            </label>
            <div className="mt-1 mb-3">
              <TextareaAutosize
                className="
         py-2 px-2 shadow-none outline-none
         border-l-0 border-t-0 border-r-0 border-b border-primary-300
         mb-3 placeholder-primary-200
         focus:border-primary-500 focus:outline-none focus:shadow-none focus:ring-0
         block w-full"
                name="description"
                id="description"
                disabled={hasRecentReviewFromLoggedInUser() || !me}
                value={description}
                onChange={(e) => setDescription(e.target.value || '')}
                placeholder="Write your review"
                minRows={4}
                maxLength={255}
              />
              <small className="text-primary-400">
                {description.length}/{255}
              </small>
            </div>
          </div>
          <div>
            {hasRecentReviewFromLoggedInUser() ? (
              <div className="text-primary-500">
                You've already submitted a review for this product today.
              </div>
            ) : (
              <button
                disabled={!isValid() || !me}
                onClick={submitReview}
                className="px-6 py-2 text-white bg-primary-500 hover:bg-primary-600 disabled:opacity-50"
              >
                {me ? 'Submit Review' : 'Login To Review'}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductReviews;
