import React, { useEffect, useState } from 'react';
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { completeCheckout } from '../../actions/Checkout';
import toast from 'react-hot-toast';
import { BillingAddress, GiftVoucher } from '@giftery/api-interface';
import { useHistory } from 'react-router';
import { AfterpayPlacement } from '@giftery/ui/afterpay-placement';
import afterpayLogoWhite from '../../images/afterpay-logo-white.svg';
import Loader from 'react-loader-spinner';
import { colors } from '@giftery/theme';
import stripeBadge from '../../images/stripe-black.svg';
import { FiLock } from 'react-icons/fi';
import { FaCcAmex, FaCcMastercard, FaCcVisa } from 'react-icons/fa';
import { maintenanceMode } from './maintenance';

export const PaymentPendingOverlay = () => {
  return (
    <div className="fixed top-0 right-0 bottom-0 left-0 text-center bg-white p-8 flex flex-col items-center justify-center z-50">
      <div className="mb-4">
        <Loader type="Oval" color={colors.primary[500]} />
      </div>
      <h1 className="text-3xl text-primary-500">Processing your payment</h1>
      <h2 className="text-xl text-primary-500">
        Please do not close this window
      </h2>
    </div>
  );
};

interface CheckoutPaymentProps {
  shippingAddress: BillingAddress;
  billingAddress: BillingAddress;
  total: number;
  voucher: GiftVoucher | undefined;
  hasRestrictedItems: boolean;
  voucherCheckInProgress: boolean;
}

export const CheckoutPayment: React.FC<CheckoutPaymentProps> = ({
  shippingAddress,
  billingAddress,
  total,
  voucher,
  voucherCheckInProgress,
  hasRestrictedItems,
  ...props
}) => {
  const [ageConfirmed, setAgeConfirmed] = useState(false);
  const [termsConfirmed, setTermsConfirmed] = useState(false);
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [cardDisabled, setCardDisabled] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [paymentIntentId, setPaymentIntentId] = useState<string>();
  const [totalDue, setTotalDue] = useState(total);
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();

  const isDisabled = () => {
    return (hasRestrictedItems && !ageConfirmed) || !termsConfirmed;
  };

  useEffect(() => {
    const voucherTotal = voucher?.value || 0;
    const newTotal = Math.max(total - voucherTotal, 0);
    setTotalDue(newTotal);
    if (newTotal === 0) {
      setDisabled(false);
    }
  }, [voucher, total]);

  const startCheckout = async () => {
    // Create PaymentIntent
    const res = await completeCheckout();
    const {
      data: { secret, intent },
    } = res;
    return { secret, intent };
  };

  const confirmCardPayment = async (secret: string) => {
    const payload = await stripe.confirmCardPayment(secret, {
      payment_method: {
        card: elements.getElement('cardNumber'),
        billing_details: {
          address: {
            line1: billingAddress.address.address,
            city: billingAddress.address.city,
            state: billingAddress.address.region,
            postal_code: billingAddress.address.postcode,
            country: 'NZ',
          },
          name: `${billingAddress.person.firstName} ${billingAddress.person.lastName}`,
          email: billingAddress.person.email,
        },
      },
    });
    if (payload.error) {
      setError(`Payment failed: ${payload.error.message}`);
      toast.error(error);
      setProcessing(false);
    } else {
      setError(null);
      setProcessing(false);
      setSucceeded(true);
      history.push('/order-confirmation');
    }
  };

  const confirmAfterpayPayment = async (secret: string) => {
    const environment =
      process.env.NODE_ENV === 'production'
        ? 'give-production'
        : 'giftery-development';
    let redirectUrl = `https://shop.thegiftery.co.nz/redirect/afterpay`;
    if (
      environment === 'giftery-development' &&
      window.location.hostname === 'localhost'
    ) {
      redirectUrl = `https://localhost:4100/redirect/afterpay`;
    } else if (environment === 'giftery-development') {
      redirectUrl = `https://shop.beta.thegiftery.nz/redirect/afterpay`;
    }

    // Redirects away from the client
    stripe.confirmAfterpayClearpayPayment(secret, {
      payment_method: {
        billing_details: {
          address: {
            line1: billingAddress.address.address,
            city: billingAddress.address.city,
            state: billingAddress.address.region,
            postal_code: billingAddress.address.postcode,
            country: 'NZ',
          },
          name: `${billingAddress.person.firstName} ${billingAddress.person.lastName}`,
          email: billingAddress.person.email,
        },
      },
      shipping: {
        name: `${
          shippingAddress.person.firstName || billingAddress.person.firstName
        } ${shippingAddress.person.lastName || billingAddress.person.lastName}`,
        address: {
          line1:
            shippingAddress.address.address || billingAddress.address.address,
          city: shippingAddress.address.city || billingAddress.address.city,
          state:
            shippingAddress.address.region || billingAddress.address.region,
          postal_code:
            shippingAddress.address.postcode || billingAddress.address.postcode,
          country: 'NZ',
        },
      },
      // TODO: make this dynamic
      return_url: redirectUrl,
    });
  };

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setCardDisabled(event.empty);
    setError(event.error ? event.error.message : '');
  };
  const handleSubmit = async (type: 'card' | 'afterpay' | 'voucher') => {
    setProcessing(true);
    const { secret, intent } = await startCheckout();
    setPaymentIntentId(intent);
    if (total > 0) {
      switch (type) {
        case 'card':
          return await confirmCardPayment(secret);
        case 'afterpay':
          return await confirmAfterpayPayment(secret);
      }
    }
  };

  const renderPayWithAfterPay = () => {
    return totalDue >= 35 && totalDue <= 1000 ? (
      <button
        className="
      inline-flex items-center justify-center py-2 px-4
      text-white bg-primary-500
      hover:bg-primary-600 focus:outline-none
      disabled:opacity-50 disabled:pointer-events-none"
        disabled={
          voucherCheckInProgress || processing || succeeded || isDisabled()
        }
        onClick={() => handleSubmit('afterpay')}
      >
        <div id="button-text" className="flex items-center justify-center">
          <div>{processing ? 'Redirecting...' : 'Pay with'}</div>
          <img
            src={afterpayLogoWhite}
            className="h-12 md:h-16 -m-4 md:-m-8 -ml-2 md:-ml-6"
            alt="afterpay"
          />
        </div>
      </button>
    ) : (
      <AfterpayPlacement amount={total} size="md" />
    );
  };

  const renderPayWithCard = () => {
    return (
      <button
        onClick={() => handleSubmit('card')}
        className="
  inline-flex items-center justify-center py-2 px-4
  text-white bg-primary-500
  hover:bg-primary-600 focus:outline-none
  disabled:opacity-50 disabled:pointer-events-none"
        disabled={
          voucherCheckInProgress ||
          processing ||
          succeeded ||
          cardDisabled ||
          isDisabled()
        }
      >
        <span id="button-text">
          {processing ? 'Processing payment...' : 'Pay with card'}
        </span>
      </button>
    );
  };
  const renderPayWithVoucher = () => {
    return (
      <button
        onClick={() => handleSubmit('voucher')}
        className="
  inline-flex items-center justify-center py-2 px-4
  text-white bg-primary-500
  hover:bg-primary-600 focus:outline-none
  disabled:opacity-50 disabled:pointer-events-none"
        disabled={processing || succeeded || disabled || isDisabled()}
      >
        <span id="button-text">
          {processing ? 'Processing payment...' : 'Pay with voucher'}
        </span>
      </button>
    );
  };

  const renderConfirmation = () => {
    return (
      <div className="relative flex items-start py-3">
        <div className="flex items-center h-5">
          <input
            id="comments"
            aria-describedby="comments-description"
            name="comments"
            type="checkbox"
            checked={termsConfirmed}
            onChange={() => setTermsConfirmed(!termsConfirmed)}
            className="focus:ring-primary-500 h-5 w-5 text-primary-600 border-gray-300"
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor="comments" className="font-medium text-gray-700">
            I agree to the{' '}
            <a href="https://thegiftery.co.nz/legal/terms-of-trade">
              terms and conditions
            </a>
          </label>
        </div>
      </div>
    );
  };

  const renderAgeConfirmation = () => {
    return (
      <>
        <div className="relative flex items-start py-3">
          <div className="flex items-center h-5">
            <input
              id="comments"
              aria-describedby="comments-description"
              name="comments"
              type="checkbox"
              checked={ageConfirmed}
              onChange={() => setAgeConfirmed(!ageConfirmed)}
              className="focus:ring-primary-500 h-5 w-5 text-primary-600 border-gray-300"
            />
          </div>
          <div className="ml-3 text-sm">
            <label htmlFor="comments" className="font-medium text-gray-700">
              By checking this box, I confirm that I am over the age of 18.
            </label>
          </div>
        </div>
        {renderConfirmation()}
      </>
    );
  };

  const renderCardPaymentInput = () => {
    return (
      <div className="bg-primary-100 px-6 py-8 pt-0 grid grid-cols-1 md:grid-cols-3 text-primary-500 text-2xl font-bold">
        <div></div>
        <div></div>
        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-2">
            <label className="text-primary-300 font-normal text-sm">
              Card Number
            </label>
            <CardNumberElement
              className="
              my-2 py-2 px-2 shadow-none outline-none bg-white
                border-l-0 border-t-0 border-r-0 border-b-2 border-primary-300
                focus:border-primary-500 focus:outline-none focus:shadow-none focus:ring-0
                block w-full"
              id="card-number-element"
              onChange={handleChange}
            />
          </div>

          <div className="">
            <label className="text-primary-300 font-normal text-sm">
              Expiry
            </label>
            <CardExpiryElement
              className="
              my-2 py-2 px-2 shadow-none outline-none bg-white
                border-l-0 border-t-0 border-r-0 border-b-2 border-primary-300
                focus:border-primary-500 focus:outline-none focus:shadow-none focus:ring-0
                block w-full"
              id="card-expiry-element"
              onChange={handleChange}
            />
          </div>

          <div className="">
            <label className="text-primary-300 font-normal text-sm">CVC</label>
            <CardCvcElement
              className="
              my-2 py-2 px-2 shadow-none outline-none bg-white
                border-l-0 border-t-0 border-r-0 border-b-2 border-primary-300
                focus:border-primary-500 focus:outline-none focus:shadow-none focus:ring-0
                block w-full"
              id="card-cvc-element"
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {processing && <PaymentPendingOverlay />}
      <form id="payment-form">
        {processing}
        {totalDue > 0 && renderCardPaymentInput()}
        <div className="py-4 flex flex-col justify-center items-end">
          {hasRestrictedItems ? renderAgeConfirmation() : renderConfirmation()}
        </div>
        <div className="grid grid-cols-1 gap-y-6 gap-x-0 md:gap-x-4 sm:grid-cols-6 px-0">
          <div className="grid grid-cols-2 gap-2 md:flex md:justify-end col-span-1 sm:col-span-6">
            {!maintenanceMode && totalDue > 0 ? renderPayWithAfterPay() : null}
            {!maintenanceMode && totalDue > 0 ? renderPayWithCard() : null}
            {!maintenanceMode && totalDue === 0 ? renderPayWithVoucher() : null}
          </div>
        </div>
        <div className="py-4">
          <div className="flex items-center justify-end py-2">
            <FaCcMastercard size={24} />
            <FaCcVisa size={24} className="ml-2" />
            <FaCcAmex size={24} className="ml-2" />
          </div>
          <div className="flex items-center justify-end pb-12">
            <FiLock size={24} />
            <p className="inline mx-2">Safe and secure checkout</p>
            <img src={stripeBadge} alt="stripe" className="h-6" />
          </div>
        </div>
      </form>
    </>
  );
};
