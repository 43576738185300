import { History, LocationDescriptor } from 'history';
import { omitBy } from 'lodash';
import qs from 'qs';

export type NavigationOptions = {
  preserveQueryStrings?: boolean;
  target?: '_top' | '_blank' | '_self' | '_parent';
  state?: Record<string, string>;
};

export class NavigationService {
  public getQueryStrings(): Record<string, string> {
    const location = window.location;
    const q = qs.parse(location.search, { ignoreQueryPrefix: true });
    return q as Record<string, string>;
  }
  public go(pathname: string, opts: NavigationOptions) {
    const destination: LocationDescriptor = {
      pathname,
    };
    if (opts.preserveQueryStrings) {
      let searchParams: Record<string, string> = {
        ...this.getQueryStrings(),
        ...(opts.state || {}),
      };
      searchParams = omitBy(searchParams, (p) => !p);
      destination.search = '?' + new URLSearchParams(searchParams).toString();
    }
    if (['_top', '_parent'].includes(opts.target)) {
      window.open(
        `https://shop.thegiftery.co.nz/${pathname}` + destination.search,
        opts.target
      );
    } else {
      this.history.push(destination);
    }
  }
  constructor(private history: History) {}
}
