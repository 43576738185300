import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Calendar, CalendarEvent } from './component/Calendar';
import { Giftees } from './component/Giftees';
import './ListPage.scss';
import { PopulatedList, WithId } from '@giftery/api-interface';
import { ViewListModal } from './component/ViewListModal/ViewListModal';
import { useHistory, useRouteMatch } from 'react-router';
import { keyBy, isEmpty } from 'lodash';
import { CreateGifteeModal } from './component/CreateGifteeModal';
import { useLists } from '../../hooks';

const ListPage = () => {
  const [list, setList] = useState<WithId<PopulatedList>>(null);
  const [gifteeModalOpen, setGifteeModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date>(null);
  const { params } = useRouteMatch<{ id: string }>();
  const history = useHistory();
  const allLists = useLists();
  const allIndexedLists: Record<string, WithId<PopulatedList>> = keyBy(
    allLists,
    'id'
  );
  const gifteeLists = allLists;

  useEffect(() => {
    if (params && params.id) {
      const newList = allIndexedLists[params.id];
      if (list && isEmpty(list.products)) {
        return;
      }
      if (list && list.products?.every((p) => typeof p !== 'string')) {
        return;
      }
      if (newList) {
        setList(newList);
        return;
      }
    }
    return setList(null);
  }, [allIndexedLists, params, list]);

  const calendarEvents: CalendarEvent[] =
    allLists
      ?.filter((list) => list.date)
      ?.map((list) => ({
        title: list.name,
        start: (list.date as FirebaseFirestore.Timestamp).toDate(),
        end: (list.date as FirebaseFirestore.Timestamp).toDate(),
        allDay: true,
        resource: list,
      })) ?? [];

  const onEventClick = (event: CalendarEvent) => {
    history.push(`/lists/${event.resource.id}`);
  };

  const onSelectDate = (date: Date) => {
    setSelectedDate(date);
    setGifteeModalOpen(true);
  };

  return (
    <div className="w-full">
      <Helmet>
        <title>The Giftery | Profile</title>
      </Helmet>

      <CreateGifteeModal
        key="CreateGifteeModal"
        initialDate={selectedDate}
        onClose={() => console.log('closing')}
        toggle={() => setGifteeModalOpen(!gifteeModalOpen)}
        open={gifteeModalOpen}
      />
      <ViewListModal
        key="ViewListModal"
        toggle={() => history.push('/lists')}
        list={list}
        open={!!list}
      />
      <div className="grid lg:grid-cols-12 lg:grid-cols-1 gap-2 w-full px-3 py-2 md:px-6 md:py-10">
        <div className="xs:col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-5">
          <Giftees
            lists={gifteeLists}
            toggle={() => setGifteeModalOpen(!gifteeModalOpen)}
          />
        </div>
        <div className="xs:col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-7">
          <Calendar
            events={calendarEvents}
            onEventClick={onEventClick}
            onSelectDate={onSelectDate}
          />
        </div>
      </div>
    </div>
  );
};

export default ListPage;
