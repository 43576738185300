import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { find } from 'lodash';
import { loadStripe } from '@stripe/stripe-js';
import { CheckoutPayment } from './CheckoutPayment';
import { CheckoutAddress } from './CheckoutAddress';
import { BillingAddress, GiftVoucher } from '@giftery/api-interface';
import { FiEdit2, FiX } from 'react-icons/fi';
import { useCart, useMe, useLists } from '../../hooks';
import Select from 'react-select';
import { selectStyleFactory } from '@giftery/theme';
import { OrderSummary } from './OrderSummary';
import { calculateShipping, classNames } from '@giftery/utils';
import { getVoucher } from '../../actions/Checkout';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { maintenanceMode } from './maintenance';
import { RadioGroup } from '@headlessui/react';
import { AddressSummary } from './AddressSummary';

const stripePromise = loadStripe(
  process.env.NODE_ENV === 'production'
    ? process.env.NX_STRIPE_KEY_PROD
    : process.env.NX_STRIPE_KEY_DEV
);

interface Step {
  id: number;
  name: string;
  status: string;
}

const initialSteps: Step[] = [
  { id: 0, name: 'Billing', status: 'current' },
  { id: 1, name: 'Shipping', status: 'upcoming' },
  { id: 2, name: 'Payment', status: 'upcoming' },
];

const giftDestinationOptions = [
  { id: 1, name: 'Myself', description: 'This gift is for myself' },
  {
    id: 2,
    name: 'Someone else',
    description: 'This gift is for a giftee I have saved.',
  },
];

export const CheckoutFlow = () => {
  const [giftDestination, setGiftDestination] = useState(
    giftDestinationOptions[0]
  );
  const [voucherLoaded, setVoucherLoaded] = useState(false);
  const [voucher, setVoucher] = useState<GiftVoucher>();
  const [voucherChecking, setVoucherChecking] = useState(false);
  const [steps, setSteps] = useState<Step[]>(initialSteps);
  const [activeStep, setActiveStep] = useState<Step>(steps[0]);
  const [shippingAddress, setShippingAddress] = useState<BillingAddress>();
  const [billingAddress, setBillingAddress] = useState<BillingAddress>();
  const [selectedList, setSelectedList] = useState<string>();
  const [total, setTotal] = useState<number>();
  const {
    cart,
    cartTotal,
    updateCartAddress,
    updateCartList,
    updateCartVoucher,
    allowAllItems,
  } = useCart();
  const me = useMe();
  const lists = useLists();

  const listOptions = lists?.map((list) => ({
    value: list.id,
    label: list.name,
  }));

  const updateShippingAddress = (address: BillingAddress) => {
    setShippingAddress(address);
    const [shippingRate] = calculateShipping(cart, address);
    const grandTotal = shippingRate + cartTotal;
    setTotal(grandTotal);
  };

  const updateAddress = (
    details: BillingAddress,
    type: 'billing' | 'shipping'
  ) => {
    switch (type) {
      case 'billing':
        setBillingAddress(details);
        break;
      case 'shipping':
        updateShippingAddress(details);
        break;
      default:
        break;
    }
    updateCartAddress(type, details, giftDestination.id === 2);
    const newSteps = [...steps];
    newSteps[activeStep.id].status = 'complete';
    setSteps(newSteps);
    setActiveStep(steps[activeStep.id + 1]);
  };

  useEffect(() => {
    if (cart.voucherCode?.trim() !== '' && !voucherLoaded) {
      setVoucherLoaded(true);
      setImmediate(() => applyVoucherToCart(cart.voucherCode));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);

  useEffect(() => {
    if (!billingAddress && me && !cart?.logistics?.billing?.address) {
      const displayName = me?.displayName?.split(' ');
      const firstName = displayName?.[0] || '';
      const lastName = displayName?.[1] || '';
      const email = me?.email || '';
      setBillingAddress({
        person: { firstName, lastName, email },
        address: {
          isRural: false,
          address: null,
          city: null,
          region: null,
          postcode: null,
          country: 'New Zealand',
        },
      });
    }
    if (!billingAddress && me && cart?.logistics?.billing?.address) {
      setBillingAddress(cart.logistics.billing);
      setActiveStep(steps[1]);
    }
    if (!shippingAddress && me && cart?.logistics?.shipping?.address) {
      updateShippingAddress(cart.logistics.shipping);
      setActiveStep(steps[2]);
    }
    if (cart && cart.listId && !selectedList) {
      updateSelectedList({ label: null, value: cart.listId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart, shippingAddress, billingAddress, me, steps]);

  const updateSelectedList = (
    option: { label: string; value: string } | undefined
  ) => {
    if (option) {
      console.log('updateSelectedList', option, lists);
      const list = find(lists, (l) => l.id === option.value);
      if (list) {
        const listAddress: BillingAddress = {
          person: {
            firstName: list.name,
            lastName: '',
            email: null,
          },
          address: {
            isRural: false,
            address: null,
            city: null,
            postcode: null,
            region: null,
            country: null,
          },
        };
        updateShippingAddress(listAddress);
      }
    }
    setSelectedList(option?.value || undefined);
    updateCartList(option?.value || null);
  };

  const goNext = () => {
    const newSteps = [...steps];
    newSteps[activeStep.id].status = 'complete';
    setSteps(newSteps);
    setActiveStep(steps[activeStep.id + 1]);
  };

  const applyVoucher = async (code: string) => {
    setVoucherChecking(true);
    try {
      const results = await getVoucher(code);
      setVoucher(results.data);
      await updateCartVoucher(code);
    } catch (err) {
      toast.error(`Invalid voucher code, please try again`);
    } finally {
      setVoucherChecking(false);
    }
  };

  const clearVoucher = async () => {
    setVoucherChecking(true);
    try {
      setVoucher(undefined);
      await updateCartVoucher(null);
    } catch (err) {
      toast.error(`Invalid voucher code, please try again`);
    } finally {
      setVoucherChecking(false);
    }
  };

  const applyVoucherToCart = async (code: string | null) => {
    if (!code) {
      return clearVoucher();
    }
    return applyVoucher(code);
  };

  const checkForRestrictedItems = () => {
    let hasRestrictedItems = false;
    for (const product of Object.values(cart.products)) {
      if (product.isRestricted) hasRestrictedItems = true;
    }
    return hasRestrictedItems;
  };

  const renderMaintenanceBanner = () => {
    return (
      <div className="mx-auto px-2 md:px-20 flex flex-col p-48 px-8 max-w-4xl items-center justify-start text-center text-primary-500">
        <h1 className="text-2xl py-4">
          We're currently working hard to improve our checkout process and fix a
          couple of things, so we've disabled checkout temporarily.
        </h1>
        <p className="py-4">
          All the items in your cart will remain there until you come back. In
          the meantime, have a look around.
        </p>
        <a className="px-6 py-4 bg-primary-500 text-white" href="/">
          Back To Store
        </a>
      </div>
    );
  };

  if (maintenanceMode === true) {
    return renderMaintenanceBanner();
  }

  const chooseDestination = (destination: any) => {
    updateSelectedList(undefined);
    setGiftDestination(destination);
  };

  const renderGifteeOptions = () => {
    return (
      <RadioGroup value={giftDestination} onChange={chooseDestination}>
        <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
        <div className="flex justify-start items-start my-4">
          {giftDestinationOptions.map((destination, index) => (
            <RadioGroup.Option
              key={destination.name}
              value={destination}
              className={`relative block rounded-lg border border-gray-300 m-0
              bg-white px-6 py-4 cursor-pointer ${index > 0 ? 'ml-3' : 'ml-0'}
              hover:border-gray-400 sm:flex sm:justify-between focus:outline-none`}
            >
              {({ checked }) => (
                <>
                  <div className="flex items-center">
                    <div className="text-sm">
                      <RadioGroup.Label
                        as="p"
                        className="font-medium text-gray-900"
                      >
                        {destination.name}
                      </RadioGroup.Label>
                      <RadioGroup.Description
                        as="div"
                        className="text-gray-500"
                      >
                        <p className="sm:inline">{destination.description}</p>
                      </RadioGroup.Description>
                    </div>
                  </div>
                  <div
                    className={classNames(
                      checked ? 'border-primary-500' : 'border-transparent',
                      'absolute -inset-px rounded-lg border-2 pointer-events-none'
                    )}
                    aria-hidden="true"
                  />
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    );
  };
  const onAllowAll = async (allowAll: boolean) => {
    await allowAllItems(allowAll);
  };

  return (
    <div className="mx-auto px-2 md:px-20">
      <div className="flex items-center justify-between pl-2 pr-0 py-4">
        <h1 className="text-3xl text-primary-500 font-normal">Checkout</h1>
        <div className="flex items-center justify-end">
          <small className="text-primary-500 hidden md:inline-block">
            Not ready to check out yet?
          </small>
          <Link
            to="/"
            className="
            ml-3 inline-flex justify-center py-2 px-4
            text-primary-500 border border-primary-500
            hover:bg-primary-600 focus:outline-none
            disabled:opacity-50 disabled:pointer-events-none"
          >
            Back to Shop
          </Link>
        </div>
      </div>
      <Elements stripe={stripePromise}>
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12">
            {activeStep.id === 0 ? (
              <div className="bg-primary-100 p-8">
                <div className="space-y-8">
                  <h1 className="inline-block text-primary-500 text-2xl md:text-3xl font-serif font-thin">
                    1. Who is placing this order?
                  </h1>
                </div>
                <CheckoutAddress
                  address={billingAddress}
                  onSave={(a) => updateAddress(a, 'billing')}
                  buttonText="Continue to Shipping"
                />
              </div>
            ) : billingAddress ? (
              <AddressSummary
                stepNumber="1"
                stepTitle="Who is placing this order?"
                address={billingAddress}
                onEdit={() => setActiveStep(steps[0])}
              />
            ) : null}
            {activeStep.id === 1 ? (
              <div className="bg-primary-100 p-8">
                <div>
                  <h1 className="inline-block text-primary-500 text-2xl md:text-3xl font-serif font-thin">
                    2. Who is this order for?
                  </h1>
                </div>
                {renderGifteeOptions()}
                {giftDestination.id === 2 && (
                  <div className="pt-0md:pt-8">
                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-0 md:gap-x-4 sm:grid-cols-6 px-0">
                      <div className="sm:col-span-4">
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-primary-500"
                        >
                          Select Giftee
                        </label>
                        <small className="text-primary-500">
                          Don't have any giftees saved yet?{' '}
                          <Link to="/lists" className="underline">
                            Go create one.
                          </Link>
                        </small>
                        <div className="mt-1">
                          <Select
                            className="ProductOrderBy font-sans w-full"
                            classNamePrefix="ProductOrderBy"
                            isClearable={true}
                            options={listOptions}
                            styles={selectStyleFactory('primary', 1)}
                            value={{
                              value: selectedList,
                              label: find(lists, (l) => l.id === selectedList)
                                ?.name,
                            }}
                            onChange={(option) => {
                              updateSelectedList(option);
                            }}
                            placeholder="Sort By"
                          />
                        </div>
                      </div>
                      {selectedList && (
                        <div className="col-span-2 flex items-end justify-end">
                          <button
                            onClick={goNext}
                            type="button"
                            className="
                          inline-flex justify-center py-2 px-4
                          text-white bg-primary-500
                          hover:bg-primary-600 focus:outline-none
                          disabled:opacity-50 disabled:pointer-events-none"
                          >
                            Continue to Payment
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {giftDestination.id === 1 && (
                  <CheckoutAddress
                    address={billingAddress}
                    sameAsCheck={true}
                    onSave={(a) => updateAddress(a, 'shipping')}
                    buttonText="Continue to Payment"
                  />
                )}
              </div>
            ) : (
              <AddressSummary
                stepNumber="2"
                stepTitle="Who is this order for?"
                address={shippingAddress}
                onEdit={() => setActiveStep(steps[1])}
              />
            )}
            {activeStep.id === 2 ? (
              <>
                <div>
                  <h1 className="mr-2 inline-block text-primary-500 text-3xl font-serif font-thin">
                    3. Order confirmation &amp; payment
                  </h1>
                </div>
                <OrderSummary
                  shippingAddress={shippingAddress}
                  voucher={voucher}
                  voucherCheckInProgress={voucherChecking}
                  applyVoucher={applyVoucherToCart}
                  allowAll={onAllowAll}
                />
                <CheckoutPayment
                  total={total}
                  voucher={voucher}
                  hasRestrictedItems={checkForRestrictedItems()}
                  voucherCheckInProgress={voucherChecking}
                  shippingAddress={shippingAddress}
                  billingAddress={billingAddress}
                />
              </>
            ) : null}
          </div>
        </div>
      </Elements>
    </div>
  );
};
