import React from 'react';
import { FirestoreReducer } from 'redux-firestore';
import { List, PopulatedList } from '@giftery/api-interface';
import { Giftee } from './Giftee';
import { FiPlus } from 'react-icons/fi';

interface GifteesProps {
  lists: FirestoreReducer.EntityWithId<PopulatedList>[];
  toggle: () => void;
}

export const Giftees: React.FC<GifteesProps> = ({ lists, toggle }) => {
  return (
    <div className="bg-shaded rounded-theme p-3 mb-2 border-0">
      {lists?.map((list) => (
        <Giftee list={list} key={list.id} />
      ))}
      <button
        type="button"
        className="
        w-full text-primary-500 mt-2 text-xl
        flex flex-row items-center justify-center py-2 px-4
        hover:bg-primary-200"
        onClick={toggle}
      >
        <FiPlus /> Add a Giftee
      </button>
    </div>
  );
};
