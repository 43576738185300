import { RootState } from '@giftery/ui/interfaces';
import { PageLoader } from '@giftery/ui/page-loader';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  isLoaded,
  OrderByOptions,
  ReduxFirestoreQuerySetting,
  useFirestoreConnect,
  WhereOptions,
} from 'react-redux-firebase';
import { useHistory, useParams } from 'react-router';
import ProductSearch from './components/ProductSearch';
import ProductList from './components/ProductList';
import './ProductsPage.scss';
import OrderBy from './components/OrderBy';
import { Product, ShowcaseSettings, WithId } from '@giftery/api-interface';
import Filters from './components/Filters';
import Sticky from 'react-stickynode';
import { GeoLocationResults, useBreakpoint } from '@giftery/ui/hooks';
import { GeoFirestore, firebase } from '@giftery/firebase';
import { DBCollection, DBDoc } from '@giftery/enums';
import { chain, isEqual } from 'lodash';
import { Helmet } from 'react-helmet';
import { FiFilter } from 'react-icons/fi';
import { classNames } from '@giftery/utils';
import { Popover } from '@headlessui/react';
import { SearchResponse } from '@algolia/client-search';
import { ProductsShowcase } from './components/ProductsShowcase';
import { useSettings } from '../../hooks';
import { RetailRootState } from '../../store/reducers';
import SearchProductList from './components/SearchProductList';
import { NavigationService } from '@giftery/services/navigation';
import qs from 'qs';

const orderByPopularity: OrderByOptions = ['counters.views', 'desc'];
const itemsPerPage = 12;

export enum PaginationDirection {
  next = 1,
  prev = -1,
}

const ProductsPage = () => {
  const params = useParams<{ page: string }>();
  const history = useHistory();
  const breakpoint = useBreakpoint();
  const [products, setProducts] = useState<WithId<Product>[]>([]);
  const [page, setPage] = useState<number>(
    params.page ? Number(params.page) : 1
  );
  const [limit] = useState<number>(page * itemsPerPage);
  const [filtersOpen, setFiltersOpen] = useState<boolean>(false);
  const [geoLocation, setGeoLocation] = useState<GeoLocationResults>(null);
  const [locationEnabled, setLocationEnabled] = useState<boolean>(false);
  const showcaseSettings = useSettings<ShowcaseSettings>(DBDoc.showcase);
  const locationSearch = window.location.search;
  // Memoized queryString helpers
  const urlQueryStrings = useMemo(() => {
    const q = qs.parse(locationSearch, { ignoreQueryPrefix: true });
    return q as {
      search: string;
      orderBy: string;
      filters: string;
    };
  }, [locationSearch]);
  const getUrlQueryString = useCallback(
    <T extends unknown[]>(str: string, defaultValue: T): T => {
      if (!str || str.length <= 0) return defaultValue;
      return str.split(',') as T;
    },
    []
  );
  const filtersFromUrl = useMemo(() => {
    return getUrlQueryString<string[]>(urlQueryStrings.filters, null);
  }, [getUrlQueryString, urlQueryStrings]);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>(
    urlQueryStrings?.search?.length > 0 ? urlQueryStrings.search : ''
  );
  const [searchResults, setSearchResults] =
    useState<SearchResponse<WithId<Product>>>();

  // Initial values and filters loaded from url params
  const [filters, setFilters] = useState<string[]>(
    filtersFromUrl ? filtersFromUrl : null
  );
  const [orderBy, setOrderBy] = useState<OrderByOptions>(
    getUrlQueryString<OrderByOptions>(
      urlQueryStrings.orderBy,
      orderByPopularity
    )
  );
  const navigationService: NavigationService = new NavigationService(history);

  // Update filter list if url params change
  useEffect(() => {
    const filtersHaveChanged = filters?.length !== filtersFromUrl?.length;
    if (!filtersHaveChanged) return;
    setFilters(filtersFromUrl ? filtersFromUrl : null);
  }, [filtersFromUrl, filters]);

  // Pagination
  const onPage = (direction: PaginationDirection) => {
    const nextPage = Math.max(page + direction, 1);
    setPage(nextPage);
    navigationService.go(`/${nextPage}`, {
      preserveQueryStrings: true,
    });
  };

  const onSearch = (
    results: SearchResponse<WithId<Product>>,
    query: string
  ) => {
    if (query !== searchQuery) {
      setPage(1);
    }
    setIsSearching(true);
    setSearchResults(results);
    setSearchQuery(query);
    navigationService.go(`/${page}`, {
      preserveQueryStrings: true,
      state: {
        search: query,
      },
    });
  };
  const onClearSearch = () => {
    setSearchResults(null);
    setIsSearching(false);
    setPage(1);
    navigationService.go(`/1`, {
      preserveQueryStrings: true,
      state: {
        search: null,
      },
    });
  };

  const onToggleLocation = useCallback((enabled: boolean) => {
    setLocationEnabled(enabled);
  }, []);

  const onChangeFilters = useCallback(
    (newFilters: string[], geolocation?: GeoLocationResults) => {
      if (geolocation) setGeoLocation(geolocation);
      navigationService.go(`/1`, {
        preserveQueryStrings: true,
        state: {
          filters: newFilters.length > 0 ? newFilters.join(',') : null,
        },
      });
    },
    []
  );

  const onChangeOrderBy = useCallback((newOrderBy: OrderByOptions) => {
    setOrderBy(newOrderBy);
    navigationService.go(`/1`, {
      preserveQueryStrings: true,
      state: {
        orderBy: newOrderBy.length > 0 ? newOrderBy.join(',') : null,
      },
    });
  }, []);
  const regularProductQuery: ReduxFirestoreQuerySetting = {
    collection: 'products',
    where: [
      ['status.active', '==', true],
      ['status.deleted', '==', false],
      ['status.featured', '==', false],
      ['status.inStock', '==', true],
    ],
    orderBy,
    limit: page * limit,
    storeAs: 'productsRegular',
  };
  const featuredProductQuery: ReduxFirestoreQuerySetting = {
    collection: 'products',
    where: [
      ['status.active', '==', true],
      ['status.deleted', '==', false],
      ['status.featured', '==', true],
      ['status.inStock', '==', true],
    ],
    storeAs: 'productsFeatured',
    orderBy,
    limit: page * limit,
  };
  if (filters?.length > 0) {
    regularProductQuery.where.push(['filters', 'array-contains-any', filters]);
    featuredProductQuery.where.push(['filters', 'array-contains-any', filters]);
  }

  useFirestoreConnect([regularProductQuery, featuredProductQuery]);

  const productsRegular = useSelector(
    (state: RootState) => state.firestore.ordered.productsRegular
  );
  const productsFeatured = useSelector(
    (state: RootState) => state.firestore.ordered.productsFeatured
  );

  const getGeoFencedProducts = async () => {
    if (!geoLocation) return;
    const { latitude, longitude } = geoLocation.geolocation.coords;
    if (!latitude || !longitude) return;
    const geocollection = GeoFirestore.collection(DBCollection.products);
    const query = geocollection.near({
      center: new firebase.firestore.GeoPoint(latitude, longitude),
      radius: geoLocation.distance,
      limit: 1000,
    });
    let regularQuery = query.limit(page * 10);
    regularProductQuery.where.map((query: WhereOptions) => {
      return (regularQuery = regularQuery.where(...query));
    });
    const productList: Record<string, WithId<Product>> = {};
    const regularProductDocs = await regularQuery.get();
    // extract the docs
    regularProductDocs.docs.map((doc) => {
      return (productList[doc.id] = doc.data() as unknown as WithId<Product>);
    });
    let featuredQuery = query.limit(page * 10);
    featuredProductQuery.where.map((query: WhereOptions) => {
      return (featuredQuery = featuredQuery.where(...query));
    });
    const featuredProductDocs = await featuredQuery.get();
    featuredProductDocs.docs.map((doc) => {
      return (productList[doc.id] = doc.data() as unknown as WithId<Product>);
    });
    return chain(productList)
      .map((product, id) => ({ ...product, id }))
      .sortBy('featured')
      .value()
      .reverse();
  };

  const productsLoading = useSelector((state: RetailRootState) => {
    return (
      state.firestore.status.requesting.productsRegular ||
      state.firestore.status.requesting.productsFeatured
    );
  });

  useEffect(() => {
    const getProducts = async () => {
      if (!checkIfProductsLoaded()) return;
      if (isSearching) return;
      if (!geoLocation || !locationEnabled) {
        // Paginate in memory
        const allProducts = [
          ...(productsFeatured || []),
          ...(productsRegular || []),
        ];
        return setProducts(
          allProducts.slice((page - 1) * itemsPerPage, itemsPerPage * page)
        );
      }
      const p = await getGeoFencedProducts();
      if (p) setProducts(p);
    };
    getProducts().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    geoLocation,
    locationEnabled,
    filters,
    productsRegular,
    productsFeatured,
  ]);

  const checkIfProductsLoaded = () => {
    return isLoaded(productsRegular) || isLoaded(productsFeatured);
  };
  if (!checkIfProductsLoaded()) return <PageLoader />;

  const getShowcaseVendors = () => {
    return showcaseSettings?.suppliers || null;
  };

  const renderShowcase = () => {
    const vendors = getShowcaseVendors();
    if (!vendors) return null;
    return (
      <ProductsShowcase
        isSearching={isSearching}
        collection={DBCollection.suppliers}
        values={vendors}
      />
    );
  };

  const renderBrowseResults = () => {
    return (
      <ProductList
        loading={productsLoading}
        products={products}
        query={searchQuery}
        className="
            bg-white p-0 px-0 grid grid-cols-2
            sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-6
            md:py-8
            gap-4 z-50"
        page={page}
        limit={itemsPerPage}
        onPage={onPage}
        orderBy={orderBy[0]}
        filters={filters}
      />
    );
  };
  const renderSearchResults = () => {
    return (
      <SearchProductList
        loading={productsLoading}
        results={searchResults}
        query={searchQuery}
        className="
            bg-white p-0 px-0 grid grid-cols-2
            sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-6
            md:py-8
            gap-4 z-50"
        page={page}
        limit={itemsPerPage}
        onPage={onPage}
        onClearSearch={onClearSearch}
      />
    );
  };

  const renderResults = () => {
    return isSearching ? renderSearchResults() : renderBrowseResults();
  };

  const renderMobileMenu = () => {
    if (breakpoint !== 'sm') return;
    return (
      <Sticky
        top={60}
        className="block md:hidden top-0 z-50 bg-white"
        activeClass="stuck z-50 bg-white py-4"
        innerActiveClass=" bg-white py-4"
        releasedClass="stuck z-50 bg-white py-4"
      >
        <div className="grid grid-cols-12 pb-2 px-1 gap-2 pt-2 bg-white">
          <div className="col-span-7">
            <ProductSearch
              initial={searchQuery}
              isSearching={isSearching}
              onSearch={onSearch}
              onClearSearch={onClearSearch}
              filters={filtersFromUrl}
              orderBy={orderBy}
              page={page}
              limit={limit}
            />
          </div>
          <div className="col-span-4">
            {!isSearching && (
              <OrderBy initial={orderBy} onChange={onChangeOrderBy} />
            )}
          </div>
          <div className="col-span-1">
            <button
              className="p-2 stroke-current text-primary-500"
              onClick={() => setFiltersOpen(!filtersOpen)}
            >
              <FiFilter />
            </button>
          </div>
          <div
            className={classNames(
              filtersOpen ? 'h-auto' : 'h-0',
              'transition-all duration-100 ease-in-out col-span-12 overflow-hidden'
            )}
          >
            <Filters
              initial={filtersFromUrl}
              className="w-full"
              onChange={onChangeFilters}
              toggleLocation={onToggleLocation}
            />
          </div>
        </div>
      </Sticky>
    );
  };

  const renderDesktopMenu = () => {
    return (
      <div className="hidden md:flex justify-between z-40 py-2 pr-2">
        <div className="flex items-center justify-start z-50">
          <div className="">
            <Popover className="relative">
              <Popover.Button className="p-2 flex items-center justify-center text-primary-500 hover:text-primary-400">
                <FiFilter className="stroke-current" /> Filter{' '}
                {filters?.length > 0 ? `(${filters.length})` : ''}
              </Popover.Button>
              <Popover.Panel className="absolute z-10 shadow-lg p-4 bg-white w-96">
                <Filters
                  initial={filtersFromUrl}
                  className="w-full"
                  onChange={onChangeFilters}
                  toggleLocation={onToggleLocation}
                />

                <img src="/solutions.jpg" alt="" />
              </Popover.Panel>
            </Popover>
          </div>
          <div className="w-96">
            <ProductSearch
              isSearching={isSearching}
              initial={searchQuery}
              onSearch={onSearch}
              onClearSearch={onClearSearch}
              filters={filtersFromUrl}
              orderBy={orderBy}
              page={page}
              limit={itemsPerPage}
            />
          </div>
        </div>
        <div>
          {!isSearching && (
            <OrderBy initial={orderBy} onChange={onChangeOrderBy} />
          )}
        </div>
      </div>
    );
  };

  const renderMenu = () => {
    switch (breakpoint) {
      case 'sm':
        return renderMobileMenu();
      default:
        return renderDesktopMenu();
    }
  };

  return (
    <div className="ProductsPage">
      <Helmet>
        <title>The Giftery | Products</title>
      </Helmet>
      <div className="mx-auto px-2 lg:px-20 flex flex-col w-screen">
        {renderMenu()}
        <div className="grid grid-cols-12 gap-2 z-30">
          {renderShowcase()}
          <div className="col-span-12">{renderResults()}</div>
        </div>
      </div>
    </div>
  );
};

export default ProductsPage;
