import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useMailChimp } from 'react-use-mailchimp-signup';
import { Squiggly } from '@giftery/ui/squiggly';

const Footer = () => {
  const [inputs, setInputs] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const { error, loading, status, subscribe, message } = useMailChimp({
    action: `https://thegiftery.us6.list-manage.com/subscribe/post?u=25510a338536b8f76ccbd88be&amp;id=c580f2c4a7`,
  });

  const handleInputChange = (event) => {
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };
  const signup = async (event) => {
    if (event) {
      event.preventDefault();
    }
    setSubmitted(true);
    if (inputs) {
      await subscribe(inputs);
    }
  };

  const navigateParent = (url) => {
    const location = window.parent?.location ?? window.location;
    location.href = url;
  };

  return (
    <>
      <div className="min-w-screen flex flex-col md:flex-row">
        <div className="bg-primary-100 min-h-full w-full md:w-1/2 flex flex-col items-start justify-start p-8 md:p-20 pb-30 mx-auto px-8 md:px-20">
          <h1 className="text-primary-500 text-6xl font-sans font-medium">
            Stay in the loop!
          </h1>
          {(!submitted || (submitted && (status === 'failed' || !status))) && (
            <>
              <div className="border-t-0 border-l-0 border-r-0 border-b-2 border-primary-500 mt-5 flex flex-row items-center justify-start">
                <input
                  name="email"
                  onChange={handleInputChange}
                  className="bg-primary-100 rounded-none text-2xl text-primary-500
                  w-48 md:w-80 border-0 font-sans
          placeholder-primary-600 placeholder-primary-600::placeholder
          focus:outline-none focus:shadow-none"
                  placeholder="Enter your email"
                />
                <button
                  className="bg-primary-100 py-2 px-6 font-sans text-primary-500 flex flex-row items-center justify-center"
                  onClick={signup}
                >
                  Sign me up{' '}
                  <Squiggly className="ml-1" color="primary-500" height={18} />
                </button>
              </div>
              {message && (
                <div className="text-primary-500 py-2">
                  Oh no! Something went wrong submitting your email. {message}
                </div>
              )}
            </>
          )}
          {/* Submitting */}
          {submitted && status === 'sending' && (
            <div className="border-t-0 border-l-0 border-r-0 border-b-2 border-primary-500 mt-5 flex flex-row items-center justify-start">
              <input
                name="email"
                onChange={handleInputChange}
                className="bg-primary-100 rounded-none text-2xl text-primary-500
          w-48 md:w-80 border-0 font-sans
          placeholder-primary-600 placeholder-primary-600::placeholder
          focus:outline-none focus:shadow-none"
                placeholder="Enter your email"
              />
              <button
                className="bg-primary-100 py-2 px-6 font-sans text-primary-500 flex flex-row items-center justify-center"
                onClick={signup}
              >
                Submitting{' '}
                <Squiggly className="ml-1" color="primary-500" height={18} />
              </button>
            </div>
          )}
          {/* Success */}
          {submitted && (status === 'success' || status === 'duplicate') && (
            <div className="border-t-0 border-l-0 border-r-0 border-b-2 border-primary-500 mt-5 flex flex-row items-center justify-start">
              <h3 className="text-2xl text-primary-500">
                Success! You've been subscribed to our mailing list.
              </h3>
            </div>
          )}
        </div>
        <div className="min-h-full w-full md:w-1/2 p-8 md:p-20 bg-primary-100 text-primary-500 text-left flex flex-col justify-start items-center">
          <div className="grid grid-cols-2 md:grid-cols-3 md:gap-4">
            {/* Left */}
            <div className="grid grid-cols-1 font-sans font-normal">
              <a
                className="my-2 h-24px max-h-24px text-primary-500 hover:text-primary-400"
                href="https://shop.thegiftery.co.nz"
                target="_top"
              >
                Shop
              </a>
              <a
                className="my-2 h-24px max-h-24px text-primary-500 hover:text-primary-400"
                href="https://shop.thegiftery.co.nz/me"
                target="_top"
              >
                My Account
              </a>
              <a
                className="my-2 h-24px max-h-24px text-primary-500 hover:text-primary-400"
                href="https://thegiftery.co.nz/how-it-works"
                target="_top"
              >
                How It Works
              </a>
              <a
                className="my-2 h-24px max-h-24px text-primary-500 hover:text-primary-400"
                href="https://thegiftery.co.nz/about-us"
                target="_top"
              >
                About
              </a>
              <a
                className="my-2 h-24px max-h-24px text-primary-500 hover:text-primary-400"
                href="https://thegiftery.co.nz/faqs"
                target="_top"
              >
                FAQ
              </a>
            </div>
            {/* Middle */}
            <div className="grid grid-cols-1 font-sans font-normal">
              <a
                className="my-2 h-24px max-h-24px text-primary-500 hover:text-primary-400"
                href="https://thegiftery.co.nz/vendors/become-a-vendor"
                target="_top"
              >
                Become A Vendor
              </a>
              <a
                className="my-2 h-24px max-h-24px text-primary-500 hover:text-primary-400"
                href="https://supplier.thegiftery.co.nz/login"
                target="_top"
              >
                Vendor Login
              </a>
              <div className="my-2 h-24px max-h-24px text-primary-500 hover:text-primary-400"></div>
              <div className="my-2 h-24px max-h-24px text-primary-500 hover:text-primary-400"></div>
              <div className="my-2 h-24px max-h-24px text-primary-500 hover:text-primary-400"></div>
            </div>
            {/* Right */}
            <div className="grid grid-cols-1 font-sans font-normal">
              <a
                href="https://instagram.com/fromthegiftery"
                target="_blank"
                rel="noreferrer"
                className="my-2 h-24px max-h-24px text-primary-500 hover:text-primary-400"
              >
                Instagram
              </a>
              <a
                href="https://facebook.com/fromthegiftery"
                target="_blank"
                rel="noreferrer"
                className="my-2 h-24px max-h-24px text-primary-500 hover:text-primary-400"
              >
                Facebook
              </a>
              <div className="my-2 h-24px max-h-24px text-primary-500 hover:text-primary-400">
                &nbsp;
              </div>
              <div className="my-2 h-24px max-h-24px text-primary-500">
                Contact Us
              </div>
              <a
                href="mailto:hello@thegiftery.co.nz"
                className="my-2 h-24px max-h-24px text-primary-500 hover:text-primary-400 font-medium underline"
              >
                hello@thegiftery.co.nz
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="min-w-screen bg-primary-100 text-primary-500 flex flex-col items-start justify-center md:grid md:grid-cols-12 pb-2 font-sans border-t p-2 border-gray-200 mx-auto px-8 md:px-2 md:px-20">
        <div className="p-2 col-span-4 text-primary-500 hover:text-primary-400">
          &copy; 2021 The Giftery
        </div>
        <div className="hidden md:block p-2 text-primary-500 hover:text-primary-400"></div>
        <div className="hidden md:block p-2 text-primary-500 hover:text-primary-400"></div>
        <div className="p-2 col-span-2 text-primary-500 hover:text-primary-400">
          <a
            className="text-primary-500 hover:text-primary-400"
            href="https://thegiftery.co.nz/legal/terms-and-conditions"
            target="_top"
          >
            T&Cs
          </a>
        </div>
        <div className="p-2 col-span-2 text-primary-500 hover:text-primary-400">
          <a
            className="text-primary-500 hover:text-primary-400"
            href="https://thegiftery.co.nz/legal/privacy-policy"
            target="_top"
          >
            Privacy Policy
          </a>
        </div>
        <div className="p-2 col-span-2 text-primary-500 hover:text-primary-400 col-span-2">
          <a
            className="text-primary-500 hover:text-primary-400"
            href="https://bitlab.co.nz"
            rel="noreferrer"
            target="_blank"
          >
            Made with ♥ by Bitlab
          </a>
        </div>
      </div>
    </>
  );
};

export default Footer;
