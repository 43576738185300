import { Product, ShowcaseSettings, WithId } from '@giftery/api-interface';
import { DBCollection } from '@giftery/enums';
import { RootState } from '@giftery/ui/interfaces';
import { useSelector } from 'react-redux';
import { uniqBy, chunk } from 'lodash';
import {
  isLoaded,
  ReduxFirestoreQuerySetting,
  useFirestoreConnect,
} from 'react-redux-firebase';

export const useHomePageProducts = (): [WithId<Product>[], boolean] => {
  const settingsShowcaseQuery: ReduxFirestoreQuerySetting = {
    collection: 'settings',
    doc: 'showcase',
  };
  const showcaseSettings = useSelector((state: RootState) => {
    return (
      (state.firestore?.data?.settings?.showcase as ShowcaseSettings) || null
    );
  });

  const homePageQueries: ReduxFirestoreQuerySetting[] =
    showcaseSettings?.homepage.length > 0
      ? chunk(showcaseSettings?.homepage, 10).map((homepageChunk, index) => ({
          collection: DBCollection.products,
          where: [
            '__name__',
            'in',
            homepageChunk.length > 0 ? homepageChunk : ['LOADING_DOC'],
          ],
          storeAs: `showcaseHomepage_${index}`,
        }))
      : [];

  useFirestoreConnect([settingsShowcaseQuery, ...homePageQueries]);

  const regularProductQuery: ReduxFirestoreQuerySetting = {
    collection: 'products',
    where: [
      ['status.active', '==', true],
      ['status.deleted', '==', false],
      ['status.featured', '==', false],
    ],
    limit: 12,
  };
  const featuredProductQuery: ReduxFirestoreQuerySetting = {
    collection: 'products',
    where: [
      ['status.active', '==', true],
      ['status.deleted', '==', false],
      ['status.featured', '==', true],
    ],
    storeAs: 'productsFeatured',
    limit: 12,
  };
  useFirestoreConnect([regularProductQuery, featuredProductQuery]);

  const showcaseHomepage = useSelector((state: RootState) => {
    const one =
      (state.firestore?.ordered?.showcaseHomepage_0 as WithId<Product>[]) || [];
    const two =
      (state.firestore?.ordered?.showcaseHomepage_1 as WithId<Product>[]) || [];
    return [...one, ...two] || null;
  });
  const productsRegular = useSelector(
    (state: RootState) => state.firestore.ordered.products
  );
  const productsFeatured = useSelector(
    (state: RootState) => state.firestore.ordered.productsFeatured
  );
  let allProducts = [];
  const isLoading =
    !isLoaded(showcaseHomepage) ||
    !isLoaded(productsRegular) ||
    !isLoaded(productsFeatured);
  if (!isLoading) {
    allProducts = [
      ...showcaseHomepage,
      ...productsFeatured,
      ...productsRegular,
    ];
  }
  return [uniqBy(allProducts, 'id'), isLoading];
};
