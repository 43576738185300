import { CartProduct as CartProductType } from '@giftery/api-interface';
import { SanitizeHTML } from '@giftery/ui/sanitize-html';
import numeral from 'numeral';
import React from 'react';
import { FiMinus, FiPlus, FiTrash2 } from 'react-icons/fi';
import { Link } from 'react-router-dom';

interface CartProductProps {
  product: CartProductType;
  onDelete: (product: CartProductType) => void;
  onIncrement: (id: string, increment: number) => void;
}

export const CartProduct: React.FC<CartProductProps> = ({
  product,
  ...props
}) => {
  if (!product) return null;
  return (
    <div
      key={product.id}
      className="
      border-b border-primary-300
      grid grid-cols-12 col-span-12
      py-2
    "
    >
      <div className="col-span-6 md:col-span-4 flex flex-col justify-center items-start">
        <Link
          to={`/product/${product.id}`}
          className="font-medium font-serif text-primary-500 no-underline hover:text-primary-500"
        >
          {product.name}{' '}
        </Link>
        {product.variant && (
          <span className="hidden md:inline-block font-sans text-sm text-white w-auto bg-primary-400 px-2 mb-2">
            {product.variant?.type}
          </span>
        )}
        <div className="font-sans text-sm text-primary-400 overflow-hidden overflow-ellipsis whitespace-nowrap w-48 md:w-96">
          <SanitizeHTML html={product.description} summary={true} />
        </div>
      </div>
      <div className="group col-span-3 md:col-span-4 flex items-center justify-center">
        <div className="flex flex-col-reverse md:flex-row items-center justify-center">
          <button
            className="py-2 px-4"
            onClick={() =>
              product.quantity <= 1
                ? props.onDelete(product)
                : props.onIncrement(
                    `${product.id}-${product.variant?.type}`,
                    -1
                  )
            }
          >
            <FiMinus className="stroke-current text-primary-500" />
          </button>
          <div className="text-primary-500">{product.quantity}</div>
          <button
            className="py-2 px-4"
            onClick={() =>
              props.onIncrement(`${product.id}-${product.variant?.type}`, 1)
            }
          >
            <FiPlus className="stroke-current text-primary-500" />
          </button>
        </div>
        <button
          className="ml-3 hidden md:flex text-transparent text-sm items-center justify-center mr-2 group-hover:text-primary-500"
          onClick={() => props.onDelete(product)}
        >
          <FiTrash2 className="stroke-current -mt-1 mr-1 text-transparent group-hover:text-primary-500" />{' '}
          Remove
        </button>
      </div>
      <div className=" col-span-3 md:col-span-4  flex items-center justify-end text-primary-400 font-medium">
        <div>{numeral(product.quantity * product.price).format(`$0,0.00`)}</div>
      </div>
    </div>
  );
};
