import { BillingAddress, ProductVariant } from '@giftery/api-interface';
import { request } from '@giftery/axios';
import { AxiosResponse } from 'axios';

export const selectOrderProduct = async (
  id: string,
  product: string,
  variant: ProductVariant,
  address: BillingAddress
): Promise<AxiosResponse<unknown>> => {
  return await request('post', `/orders/${id}`, { product, variant, address });
};

export const convertToGiftVoucher = async (
  id: string
): Promise<AxiosResponse<unknown>> => {
  return await request('post', `/orders/${id}/convert`);
};

export const registerForUpdates = async (
  id: string,
  phone: string
): Promise<AxiosResponse<unknown>> => {
  return await request('post', `/orders/track/phone/${id}`, { phone });
};
