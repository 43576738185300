import { colors } from '@giftery/theme';
import React, { useEffect, useState } from 'react';
import { OrderByOptions } from 'react-redux-firebase';
import Select from 'react-select';
import { isEqual } from 'lodash';

interface OrderByProps {
  onChange: (orderBy: OrderByOptions) => void;
  initial: OrderByOptions;
}

type OrderBySelect = {
  value: OrderByOptions;
  label: string;
};

const options: OrderBySelect[] = [
  { value: ['counters.views', 'desc'], label: 'Popularity' },
  { value: ['price', 'asc'], label: 'Price - Low to High' },
  { value: ['price', 'desc'], label: 'Price - High to Low' },
  { value: ['audit.createdAt', 'desc'], label: 'Newest to Oldest' },
  { value: ['audit.createdAt', 'asc'], label: 'Oldest to Newest' },
];

const OrderBy: React.FC<OrderByProps> = ({ initial, ...props }) => {
  const [value, setValue] = useState<OrderBySelect>();

  useEffect(() => {
    const initialValue = initial
      ? options.find((o) => isEqual(o.value, initial))
      : undefined;
    setValue(initialValue);
  }, [initial]);

  const onChange = (selection: OrderBySelect) => {
    setValue(selection);
    props.onChange(selection.value);
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: 'white',
      border: 'none',
      color: colors.primary[500],
      fontWeight: state.isSelected ? 'bold' : 'normal',
    }),
    control: (provided) => ({
      ...provided,
      color: colors.primary[500],
      borderRadius: 0,
      background: colors.primary[100],
      border: 'none',
    }),
    input: (provided) => ({
      ...provided,
      borderRadius: 0,
      color: colors.primary[500],
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#ccc',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      return { ...provided, opacity, transition, color: colors.primary[500] };
    },
  };
  return (
    <div className="w-auto lg:w-96 flex items-center justify-end">
      <label className="text-primary-500 m-0 mr-2 leading-4 flex-none">
        Sort by
      </label>
      <Select
        className="ProductOrderBy font-sans grow w-72"
        classNamePrefix="ProductOrderBy"
        options={options}
        value={value}
        styles={customStyles}
        onChange={onChange}
        placeholder="Sort By"
      />
    </div>
  );
};

export default OrderBy;
