import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import LoginPage from './components/Auth/Login';
import LogoutPage from './components/Auth/Logout';
import ReactDOM from 'react-dom';
import { configureAppStore } from './store/store';
import reportWebVitals from './reportWebVitals';
import AppWithSideBar from './containers/AppWithSidebar';
import { Toaster } from 'react-hot-toast';
import { createBrowserHistory } from 'history';
import 'firebase/firestore';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';
import { firebase } from '@giftery/firebase';
import { AuthenticatedRoute, AuthIsLoaded } from '@giftery/ui/auth-check';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import 'react-tagsinput/react-tagsinput.css';
import 'tailwindcss/tailwind.css';
import ProductsPage from './components/Products/ProductsPage';
import ProductPage from './components/Product/ProductPage';
import ProfilePage from './components/Profile/ProfilePage';
import ListPage from './components/List/ListPage';
import CheckoutPage from './components/Checkout/CheckoutPage';
import GiftSelectionPage from './components/GiftSelection/GiftSelectionPage';
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/react';
import HomePage from './components/Home/HomePage';
import { OrderConfirmationPage } from './components/OrderConfirmation/OrderConfirmationPage';
Sentry.init({
  dsn: 'https://3aee359d6c60421cbc1a95a0fa19eae1@o976959.ingest.sentry.io/5933482',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const App = () => {
  const history = createBrowserHistory();
  const oldHistoryPush = history.push;
  history.push = (to, state?: Record<string, unknown>) => {
    oldHistoryPush(to.toString(), state);
  };
  // react-redux-firebase config
  const rrfConfig = {
    userProfile: 'users',
    useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
  };

  firebase.firestore();

  const store = configureAppStore(history);

  const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance,
  };
  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <Router>
          <AuthIsLoaded>
            <Toaster position="top-right" />
            <Switch>
              <Route path="/login">
                <LoginPage />
              </Route>
              <Route path="/home">
                <HomePage />
              </Route>
              <AuthenticatedRoute path="/order-confirmation">
                <AppWithSideBar component={OrderConfirmationPage} />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/checkout">
                <AppWithSideBar component={CheckoutPage} />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/logout">
                <AppWithSideBar component={LogoutPage} />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/lists/:id">
                <AppWithSideBar component={ListPage} />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/lists">
                <AppWithSideBar component={ListPage} />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/me">
                <AppWithSideBar component={ProfilePage} />
              </AuthenticatedRoute>
              <Route path="/gift/select/:id/:token?">
                <AppWithSideBar component={GiftSelectionPage} />
              </Route>
              <Route path="/product/:id">
                <AppWithSideBar component={ProductPage} />
              </Route>
              <Route path="/:page?">
                <AppWithSideBar component={ProductsPage} />
              </Route>
            </Switch>
          </AuthIsLoaded>
        </Router>
      </ReactReduxFirebaseProvider>
    </Provider>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
