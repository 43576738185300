import React from 'react';
import { Helmet } from 'react-helmet';
import './CheckoutPage.scss';
import { useCart } from '../../hooks';
import { CheckoutFlow } from './CheckoutFlow';
import { CheckoutEmpty } from './CheckoutEmpty';
import { PageLoader } from '@giftery/ui/page-loader';

const CheckoutPage: React.FC = () => {
  const { cart, loading } = useCart();
  if (loading) return <PageLoader />;

  return (
    <div className="">
      <Helmet>
        <title>The Giftery | Checkout</title>
      </Helmet>
      {Object.keys(cart?.products || {}).length > 0 ? (
        <div className="">
          <div className="mx-auto flex">
            <div className="flex-grow">
              <CheckoutFlow />
            </div>
          </div>
        </div>
      ) : (
        <CheckoutEmpty />
      )}
    </div>
  );
};

export default CheckoutPage;
