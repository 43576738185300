import { GiftVoucher, WithId } from '@giftery/api-interface';
import { request } from '@giftery/axios';
import { AxiosResponse } from 'axios';

export const completeCheckout = async (): Promise<
  AxiosResponse<{ success: boolean; secret: string; intent: string }>
> => {
  return await request('post', '/checkout/complete');
};

export const getVoucher = async (
  code: string
): Promise<AxiosResponse<WithId<GiftVoucher>>> => {
  return await request('get', `/vouchers/${code}`);
};
