import { OrderProduct, ProductVariant, WithId } from '@giftery/api-interface';
import { storage } from '@giftery/firebase';
import { RadioGroup } from '@headlessui/react';
import { useProduct } from '../../../hooks';
import React, { useEffect, useState } from 'react';
import { SanitizeHTML } from '@giftery/ui/sanitize-html';

interface ProductItemProps {
  product: WithId<OrderProduct>;
  checked: boolean;
  active: boolean;
  onSelectVariant: (variant: ProductVariant) => void;
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const ProductItem: React.FC<ProductItemProps> = ({
  product: rawProduct,
  checked,
  active,
  ...props
}) => {
  const [product, setProduct] = useState<OrderProduct>(null);
  const [selectedVariant, setSelectedVariant] = useState<ProductVariant>();
  const [p] = useProduct(rawProduct.id);

  useEffect(() => {
    if (rawProduct && !product) {
      const getImage = async () => {
        const p = Object.assign(
          {},
          {
            ...rawProduct,
            images: rawProduct.images ? [...rawProduct.images] : [],
          }
        );
        const mainImage = p.images[0];
        if (mainImage && mainImage.indexOf('http') < 0) {
          p.images[0] = await storage
            .ref(`/images/${mainImage}`)
            .getDownloadURL();
        }
        setProduct(p);
      };
      getImage().then();
    }
  }, [rawProduct, product]);

  if (!product) return null;

  const updateSelectedVariant = (variant: ProductVariant) => {
    setSelectedVariant(variant);
    props.onSelectVariant(variant);
  };

  return (
    <div className="flex flex-col items-start">
      <div className="mr-4 flex-shrink-0">
        <img
          className="h-32 w-32 border border-gray-300 bg-white text-gray-300"
          src={product?.images[0] || null}
          alt={product.name}
        />
      </div>
      <div>
        <RadioGroup.Label
          as="span"
          className={classNames(
            checked ? 'text-primary-600' : 'text-primary-500',
            'block text-xl font-bold'
          )}
        >
          {product.name}
        </RadioGroup.Label>
        <RadioGroup.Description
          as="span"
          className={classNames(
            checked ? 'text-primary-600' : 'text-primary-500',
            'block text-md'
          )}
        >
          <SanitizeHTML html={product.description} summary={true} />
          {p?.variants?.length > 1 && (
            <div>
              <label className="font-bold mt-3">Select an option:</label>
              <RadioGroup
                value={selectedVariant}
                onChange={updateSelectedVariant}
                className="mt-2"
              >
                <div className="grid grid-cols-1 gap-3 sm:grid-cols-2">
                  {p.variants?.map((variant) => (
                    <RadioGroup.Option
                      key={variant.type}
                      value={variant}
                      className={({ active, checked }) =>
                        classNames(
                          variant.quantity > 0
                            ? 'cursor-pointer focus:outline-none'
                            : 'opacity-25 cursor-not-allowed',
                          active ? 'border-primary-500' : '',
                          checked
                            ? 'border-primary-500 bg-primary-500 text-white hover:bg-primary-700'
                            : 'bg-white border-primary-400 text-primary-400 hover:bg-primary-200',
                          'border-2 py-3 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1 min-h-24'
                        )
                      }
                      disabled={variant.quantity <= 0}
                    >
                      <RadioGroup.Label as="div" className="text-center">
                        <div>{variant.type}</div>
                        {variant?.quantity <= 0 ? (
                          <div
                            className="text-xs"
                            style={{ fontSize: '.65em' }}
                          >
                            Out of Stock
                          </div>
                        ) : null}
                      </RadioGroup.Label>
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
            </div>
          )}
        </RadioGroup.Description>
      </div>
    </div>
  );
};
