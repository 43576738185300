import { Product, WithId, List, PopulatedList } from '@giftery/api-interface';
import React, { useEffect, useState } from 'react';
import './ProductItem.scss';
import numeral from 'numeral';
import { storage } from '@giftery/firebase';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '@giftery/ui/interfaces';
import { getRelation } from '@giftery/utils';
import { FiTrash2 } from 'react-icons/fi';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { DBCollection } from '@giftery/enums';
import { toast } from 'react-hot-toast';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Gifter } from '@giftery/ui/gifter';
import { useImage } from 'react-image';
import { ProductLoader } from './ProductLoader';

interface ProductItemProps {
  product: WithId<Product>;
  list: WithId<PopulatedList>;
}

const ProductItem: React.FC<ProductItemProps> = ({
  product: rawProduct,
  list,
}) => {
  const [product, setProduct] = useState<WithId<Product>>(null);
  const firestore = useFirestore();
  const firebase = useFirebase();
  const srcList: string[] = [
    ...(product?.images || []),
    `${window.location.protocol}//${window.location.hostname}:${window.location.port}/assets/mail-header.jpg`,
  ];

  const { src } = useImage({
    srcList,
  });
  console.log(src);

  useEffect(() => {
    if (rawProduct && !product) {
      const getImage = async () => {
        const p = Object.assign(
          {},
          {
            ...rawProduct,
            images: rawProduct.images ? [...rawProduct.images] : [],
          }
        );
        const mainImage = p.images[0];
        if (mainImage && mainImage.indexOf('http') < 0) {
          p.images[0] = await storage
            .ref(`/images/${mainImage}`)
            .getDownloadURL();
        }
        setProduct(p);
      };
      getImage().then();
    }
  }, [rawProduct, product]);

  useEffect(() => {
    return () => {
      setProduct(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const categoryData = useSelector(
    (state: RootState) => state.firestore.ordered.categories
  );

  const image = () => {
    return src.startsWith('http') ? (
      <LazyLoadImage
        effect="blur"
        alt="product"
        src={src}
        className="h-10 object-cover"

        // If the image we are creating here has the same src than before,
        // we can directly display it with no need to lazy-load.
      />
    ) : (
      <Gifter className="p-8 h-10 bg-white w-full object-cover object-center stroke-current fill-current max-w-full max-h-full text-primary-400" />
    );
  };

  const deleteFromList = async () => {
    try {
      await firestore
        .collection(DBCollection.lists)
        .doc(list.id)
        .update({
          products: firebase.firestore.FieldValue.arrayRemove(product.id),
        });
    } catch (err) {
      console.error(err);
      toast.error(`Error removing product from list!`);
    }
  };

  if (!product) return <ProductLoader />;

  return (
    <div className="grid grid-cols-12 gap-2 py-2 px-0" key={product.id}>
      <div className="col-span-2 md:col-span-1 text-center flex items-center justify-center rounded-full">
        {image()}
      </div>
      <div className="col-span-4 md:col-span-6 text-left flex items-center">
        <p>
          <Link to={`/product/${product.id}`}>{product.name}</Link>
        </p>
      </div>
      <div className="col-span-2 hidden md:flex text-center items-center">
        <p>{getRelation(categoryData, product.categories?.[0], 'name')}</p>
      </div>
      <div className="col-span-4 md:col-span-2 text-right flex items-center justify-end">
        <p>{numeral(product.variants?.[0]?.price).format('$0,0.00')}</p>
      </div>
      <div className="col-span-2 md:col-span-1 flex items-center justify-center">
        <div className="flex items-center h-10">
          <button
            className="stroke-current text-primary-500"
            onClick={deleteFromList}
          >
            <FiTrash2 />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductItem;
