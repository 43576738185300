import React, { useEffect, useState } from 'react';
import {
  FiGift,
  FiHome,
  FiLink,
  FiLock,
  FiMail,
  FiPower,
  FiShoppingBag,
  FiShoppingCart,
  FiUser,
} from 'react-icons/fi';
import { NavLink } from 'react-router-dom';
import { classNames, validURL } from '@giftery/utils';
import { IconType } from 'react-icons/lib';
import { useMe } from '../hooks';
import { storage } from '@giftery/firebase';
import profilePlaceholder from '../images/profile-placeholder.png';
import { useLocation, useRouteMatch } from 'react-router';

const navigation: NavigationItem[] = [
  { name: 'Home', icon: FiHome, href: 'https://thegiftery.co.nz' },
  {
    name: 'Shop',
    icon: FiShoppingBag,
    href: '/1',
  },
  {
    name: 'My Giftees',
    icon: FiGift,
    href: '/lists',
  },
  {
    name: 'About us',
    icon: FiLink,
    href: 'https://thegiftery.co.nz/about-us',
  },
  {
    name: 'Contact us',
    icon: FiMail,
    href: 'https://thegiftery.co.nz/contact-us',
  },
  {
    name: 'Become a vendor',
    icon: FiShoppingCart,
    href: 'https://thegiftery.co.nz/vendors/become-a-vendor',
  },
];

interface NavigationItem {
  name: string;
  icon: IconType;
  href: string;
}
interface MenuProps {
  onClose: () => void;
}

export const Menu: React.FC<MenuProps> = ({ ...props }) => {
  const me = useMe();
  const location = useLocation();
  const [profilePic, setProfilePic] = useState<string>(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded && me) {
      getProfilePic();
      setLoaded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me]);
  const getProfilePic = async (image = me?.avatarUrl) => {
    if (image) {
      let imageUrl = image;
      if (!validURL(imageUrl)) {
        imageUrl = await storage.ref(`/images/${image}`).getDownloadURL();
      }
      setProfilePic(imageUrl);
    } else {
      setProfilePic(profilePlaceholder);
    }
  };

  const isActive = (href: string): boolean => {
    return !!useRouteMatch(href);
  };

  const renderMenuLabel = (item: NavigationItem) => {
    return <span className="flex-1">{item.name}</span>;
  };

  const renderNavLink = (item: NavigationItem) => {
    return (
      <NavLink
        key={item.name}
        to={item.href}
        className={classNames(
          isActive(item.href)
            ? 'bg-primary-200'
            : 'text-white hover:bg-primary-200 hover:bg-opacity-75',
          'group flex items-center px-2 py-2 text-sm font-medium text-primary-500 hover:no-underline justify-center xl:justify-start'
        )}
      >
        <item.icon
          className="mr-2 xl:mr-3 flex-shrink-0 h-6 w-6 text-primary-300"
          aria-hidden="true"
        />
        {renderMenuLabel(item)}
      </NavLink>
    );
  };

  const renderHref = (item: NavigationItem) => {
    return (
      <a
        key={item.name}
        href={item.href}
        className={classNames(
          isActive(item.href)
            ? 'bg-primary-200'
            : 'text-white hover:bg-primary-200 hover:bg-opacity-75',
          'group flex items-center px-2 py-2 text-sm font-medium text-primary-500 hover:no-underline justify-center xl:justify-start'
        )}
      >
        <item.icon
          className="mr-2 xl:mr-3 flex-shrink-0 h-6 w-6 text-primary-300"
          aria-hidden="true"
        />
        {renderMenuLabel(item)}
      </a>
    );
  };

  const renderUser = () => {
    return (
      <>
        <NavLink to="/settings/profile" className="flex-shrink-0  group">
          <div className="flex items-center">
            <div>
              {me?.avatarUrl ? (
                <img className="h-8 w-8 rounded-full" src={profilePic} alt="" />
              ) : (
                <FiUser
                  className="inline-block h-9 w-9
                rounded-full stroke-current fill-current text-primary-500 bg-primary-50
                sm:h-6 sm:w-6
                "
                />
              )}
            </div>
            <div className="ml-2 hover:no-underline">
              <p className="text-sm font-medium text-primary-500 my-0 no-underline group-hover:no-underline hover:no-underline">
                {me?.displayName || me?.email?.split('@').shift()}
              </p>
              <p className="text-xs font-medium text-primary-500 my-0 no-underline group-hover:text-primary-400 group-hover:no-underline hover:no-underline">
                View profile
              </p>
            </div>
          </div>
        </NavLink>
        <NavLink
          to="/logout"
          className="flex-shrink-0 group text-primary-500 flex justify-end items-center text-sm px-2"
        >
          <FiPower title="Sign out" />
        </NavLink>
      </>
    );
  };

  return (
    <div>
      <div className="flex flex-col col-span-1 xl:col-span-2 h-0 flex-1 bg-primary-100 min-h-screen max-h-screen">
        <div className="flex-1 flex flex-col pt-2 pb-0 overflow-y-auto">
          <nav className="mt-0 flex-1 px-2 space-y-1" aria-label="Sidebar">
            {navigation.map((item) =>
              item.href.startsWith('http')
                ? renderHref(item)
                : renderNavLink(item)
            )}
          </nav>
          <div className="flex-shrink-0 flex border-t border-primary-200 p-4 justify-between">
            {me
              ? renderUser()
              : renderNavLink({
                  name: 'Sign in',
                  icon: FiLock,
                  href: '/login',
                })}
          </div>
        </div>
      </div>
    </div>
  );
};
