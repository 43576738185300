import { useExtendedProducts } from '../../../../hooks';
import React, { useEffect, useState } from 'react';
import { GiftChoice } from './components/GiftChoice';
import { useInView } from 'react-intersection-observer';
import {
  isLoaded,
  OrderByOptions,
  ReduxFirestoreQuerySetting,
  useFirestoreConnect,
  WhereOptions,
} from 'react-redux-firebase';
import { RootState } from '@giftery/ui/interfaces';
import { useSelector } from 'react-redux';
import { Product, WithId } from '@giftery/api-interface';
import { PageLoader } from '@giftery/ui/page-loader';
import { ChoiceList } from './components/ChoiceList';
import { DBCollection } from '@giftery/enums';

interface ExtendedGiftSelectionProps {
  orderValue: number;
  selected: string;
  onSelect: (p: WithId<Product>) => void;
}

const orderByPopularity: OrderByOptions = ['counters.views', 'desc'];

export const ExtendedGiftSelection: React.FC<ExtendedGiftSelectionProps> = ({
  orderValue,
  selected,
  ...props
}) => {
  const [page, setPage] = useState<number>(1);
  const [products, setProducts] = useState<WithId<Product>[]>([]);
  const [filters, setFilters] = useState<WhereOptions[]>([]);
  const [orderBy, setOrderBy] = useState<OrderByOptions>(orderByPopularity);
  // Tracks scroll to bottom to trigger pagination
  const [lastIntersecting, setLastIntersecting] = useState<boolean>();
  const { ref, entry } = useInView({ trackVisibility: true, delay: 100 });
  const isIntersecting = entry?.isIntersecting === true;
  if (lastIntersecting !== isIntersecting) {
    setLastIntersecting(isIntersecting);
    if (isIntersecting) setPage(page + 1);
  }
  const regularProductQuery: ReduxFirestoreQuerySetting = {
    collection: DBCollection.products,
    where: [
      ['status.active', '==', true],
      ['status.deleted', '==', false],
      ['status.featured', '==', false],
      ['price', '<=', orderValue],
      ...filters,
    ],
    storeAs: 'productsRegular',
    limit: 12 * page,
  };
  const featuredProductQuery: ReduxFirestoreQuerySetting = {
    collection: DBCollection.products,
    where: [
      ['status.active', '==', true],
      ['status.deleted', '==', false],
      ['status.featured', '==', true],
      ['price', '<=', orderValue],
      ...filters,
    ],
    storeAs: 'productsFeatured',
    limit: 12 * page,
  };
  useFirestoreConnect([regularProductQuery, featuredProductQuery]);

  const productsRegular = useSelector(
    (state: RootState) => state.firestore.ordered.productsRegular
  );
  const productsFeatured = useSelector(
    (state: RootState) => state.firestore.ordered.productsFeatured
  );

  useEffect(() => {
    const getProducts = async () => {
      if (!isLoaded(productsRegular) || !isLoaded(productsFeatured)) return;
      const newProducts = [...productsFeatured, ...productsRegular];
      if (newProducts.length >= products.length)
        return setProducts(newProducts);
    };
    getProducts().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, productsRegular, productsFeatured]);

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 pb-48">
        <ChoiceList
          products={products || []}
          onSelect={props.onSelect}
          selected={selected}
        />
      </div>
      <div className="PageTracker" ref={ref} />
    </>
  );
};
