import React, { useState } from 'react';
import Slider, { SliderTooltip, Handle } from 'rc-slider';
import { colors } from '@giftery/theme';
import 'rc-slider/assets/index.css';
import './ui-distance-slider.scss';

/* eslint-disable-next-line */
export interface DistanceSliderProps {
  onChange: (value: number) => void;
  toggle: (value: boolean) => void;
  disabled: boolean;
  min: number;
  max: number;
}

const handle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <SliderTooltip
      prefixCls="rc-slider-tooltip"
      overlay={`${value} km`}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </SliderTooltip>
  );
};

export const DistanceSlider = (props: DistanceSliderProps) => {
  const [distance, setDistance] = useState<number>(20);
  const [enabled, setEnabled] = useState<boolean>(false);
  const onChange = (value: number) => {
    setDistance(value);
    props.onChange(value);
  };

  const toggleGeoSearch = () => {
    setEnabled(!enabled);
    props.toggle(!enabled);
  };

  return (
    <div className="DistanceSliderContainer">
      <div className="relative flex items-start">
        <div className="flex items-center h-5">
          <input
            id="comments"
            aria-describedby="comments-description"
            name="comments"
            type="checkbox"
            checked={enabled}
            onChange={toggleGeoSearch}
            className="focus:ring-0 h-4 w-4 text-primary-500 border-gray-300"
          />
        </div>
        <div className="ml-3 text-sm">
          <label
            htmlFor="comments"
            className="text-primary-500 flex items-center justify-between"
          >
            {enabled ? (
              <span>
                Within <strong>{distance}km</strong>
              </span>
            ) : (
              <span>Local stores</span>
            )}
          </label>
        </div>
      </div>
      <div className="DistanceSlider">
        <div>{props.min}km</div>
        <div className="Slider">
          <Slider
            min={props.min}
            max={props.max}
            defaultValue={5}
            step={5}
            handle={handle}
            onChange={onChange}
            trackStyle={{ backgroundColor: colors.primary[500] }}
            handleStyle={{
              backgroundColor: colors.primary[500],
              borderColor: colors.primary[500],
            }}
            railStyle={{ backgroundColor: colors.background[500] }}
            disabled={!!props.disabled || !enabled}
          />
        </div>
        <div>{props.max}km+</div>
      </div>
    </div>
  );
};
