import { Order } from '@giftery/api-interface';
import { DBCollection } from '@giftery/enums';
import { useSelector } from 'react-redux';
import { useFirestoreConnect, populate } from 'react-redux-firebase';
import { RetailRootState } from '../store/reducers';

export function useOrder(id: string): Order {
  const populates = [{ child: 'giftGiver', root: DBCollection.users }];
  const collection = DBCollection.orders;
  useFirestoreConnect([
    {
      collection,
      doc: id,
      populates,
    },
  ]);
  const { orders } = useSelector((state: RetailRootState) => {
    return {
      orders: populate(state.firestore, collection, populates),
    };
  });
  return orders?.[id] || null;
}
