import { CartProduct, ProductVariant } from '@giftery/api-interface';
import { ETrackingType } from '@giftery/enums';
import { storage } from '@giftery/firebase';
import { AfterpayPlacement } from '@giftery/ui/afterpay-placement';
import { SanitizeHTML } from '@giftery/ui/sanitize-html';
import { Tracker } from '@giftery/ui/tracking';
import { validURL } from '@giftery/utils';
import { Popover, Transition } from '@headlessui/react';
import { isEmpty, uniq } from 'lodash';
import React, { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { Helmet } from 'react-helmet';
import { AiFillStar } from 'react-icons/ai';
import {
  FiHeart,
  FiMinus,
  FiPlus,
  FiShoppingCart,
  FiChevronLeft,
} from 'react-icons/fi';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { useCart, useMe, useProduct, useStore } from '../../hooks';
import storeLogoPlaceholder from '../../images/store-placeholder.png';
import { PopOverWishList } from './PopoverWishList';
import Rating from 'react-rating';
import numeral from 'numeral';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ProductReviews from './ProductReviews';
import ContentLoader from 'react-content-loader';
import { colors } from '@giftery/theme';
import { ProductVariants } from './ProductVariants';
import { useBreakpoint } from '@giftery/ui/hooks';
import { Carousel } from 'react-responsive-carousel';
import ShowMoreText from 'react-show-more-text';

const ImageLoader = (props) => {
  return (
    <ContentLoader
      viewBox="0 0 820 1600"
      height={1600}
      width={820}
      backgroundColor={colors.primary[100]}
      foregroundColor={colors.primary[200]}
      {...props}
    >
      <rect x="0" y="0" rx="5" ry="5" width="820" height="600" />
      <rect x="0" y="620" rx="5" ry="5" width="820" height="480" />
      <rect x="0" y="1120" rx="5" ry="5" width="820" height="580" />
    </ContentLoader>
  );
};

const ImageLoaderMobile = (props) => {
  return (
    <ContentLoader
      viewBox="0 0 820 400"
      height={400}
      width={820}
      backgroundColor={colors.primary[100]}
      foregroundColor={colors.primary[200]}
      {...props}
    >
      <rect x="0" y="0" rx="5" ry="5" width="820" height="400" />
    </ContentLoader>
  );
};

const ProductLoader = (props) => {
  return (
    <ContentLoader
      viewBox="0 0 800 400"
      backgroundColor={colors.primary[100]}
      foregroundColor={colors.primary[200]}
      {...props}
    >
      {/* Title + Subtitle */}
      <rect x="0" y="0" rx="4" ry="4" width="600" height="35" />
      <rect x="0" y="45" rx="4" ry="4" width="200" height="10" />
      {/* Reviews */}
      <rect x="0" y="65" rx="4" ry="4" width="180" height="10" />
      {/* Price */}
      <rect x="680" y="65" rx="4" ry="4" width="120" height="30" />
      {/* Afterpay */}
      <rect x="500" y="105" rx="4" ry="4" width="300" height="10" />
      {/* Description */}
      <rect x="0" y="140" rx="4" ry="4" width="750" height="10" />
      <rect x="0" y="160" rx="4" ry="4" width="550" height="10" />
      <rect x="0" y="180" rx="4" ry="4" width="650" height="10" />
      <rect x="0" y="200" rx="4" ry="4" width="200" height="10" />
      {/* Buttons */}
      <rect x="0" y="240" rx="4" ry="4" width="200" height="50" />
      <rect x="220" y="240" rx="4" ry="4" width="200" height="50" />
    </ContentLoader>
  );
};

const ProductsPage = () => {
  const { params } = useRouteMatch<{ id: string }>();
  const [product, reviews, rating, loading] = useProduct(params.id);
  const [images, setImages] = useState<string[]>([]);
  const [storeLogo, setStoreLogo] = useState<string>(undefined);
  const [showMoreText, setShowMoreText] = useState(false);
  const { cart, addToCart, incrementCartItem } = useCart();
  const store = useStore(product?.supplierId);
  const [selectedVariant, setSelectedVariant] = useState<ProductVariant>();
  const breakpoint = useBreakpoint();
  const history = useHistory();
  const me = useMe();
  const cartProduct =
    product && selectedVariant
      ? cart?.products?.[`${product.id}-${selectedVariant?.type}`]
      : null;

  useEffect(() => {
    const getProfilePic = async (image = store.logo) => {
      if (image) {
        let imageUrl = image;
        if (!validURL(imageUrl)) {
          imageUrl = await storage.ref(`/images/${image}`).getDownloadURL();
        }
        setStoreLogo(imageUrl);
      }
    };
    if (store && !storeLogo) {
      getProfilePic();
    }
  }, [store, storeLogo]);

  useEffect(() => {
    const getImage = async () => {
      const imageUrls: string[] = [];
      const mainImage = product?.images?.[0];
      if (!mainImage) return;
      for (const image of product.images) {
        try {
          let imageUrl = image;
          if (!validURL(imageUrl)) {
            imageUrl = await storage.ref(`/images/${image}`).getDownloadURL();
          }
          imageUrls.push(imageUrl);
        } catch (err) {
          console.error(err);
        }
      }
      setImages(imageUrls);
    };

    if (product && product.images.length > 0 && images.length === 0) {
      getImage();
    }
    if (product && !selectedVariant && product.variants.length === 1) {
      setSelectedVariant(product.variants?.[0]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);
  const addItemToCart = async () => {
    await addToCart(product, selectedVariant, store);
  };

  const incrementCart = async () => {
    await incrementCartItem(`${product.id}-${selectedVariant?.type}`, 1);
  };
  const decrementCart = async () => {
    await incrementCartItem(`${product.id}-${selectedVariant?.type}`, -1);
  };

  const renderCartQuantityButtons = () => {
    if (!cartProduct) return null;
    return (
      <div className="flex flex-row justify-between w-full bg-grey-50 py-3 mt-5">
        <button
          className="py-2 px-4 bg-transparent flex flex-row items-center justify-center"
          onClick={decrementCart}
        >
          <FiMinus className="fill-current text-grey-800" />
        </button>
        <p className="text-lg text-center font-sans font-bold px-4 flex-1">
          {cartProduct.quantity}
        </p>
        <button
          className="py-2 px-4 bg-transparent flex flex-row items-center justify-center"
          onClick={incrementCart}
        >
          <FiPlus className="fill-current text-grey-800" />
        </button>
      </div>
    );
  };

  const renderAddToCartButton = () => {
    return (
      <button
        className="flex-1 py-3 px-5 border-2 border-primary-500
        text-white bg-primary-500 flex flex-row items-center justify-center
        hover:bg-primary-400 hover:border-primary-400 disabled:pointer-events-none disabled:opacity-50"
        onClick={addItemToCart}
        disabled={!selectedVariant || !me}
      >
        <FiShoppingCart /> {me ? ' Add to Cart' : ' Login To Add To Cart'}
      </button>
    );
  };

  const renderProductNameDesktop = () => {
    return (
      <h2 className="text-4xl md:text-5xl font-thin text-primary-500">
        {product.name}
      </h2>
    );
  };

  const renderProductNameMobile = () => {
    return (
      <div className="flex items-center justify-start">
        <h2 className="text-3xl font-thin text-primary-500">{product.name}</h2>
        <Link
          className="text-primary-500 bg-transparent border-2 border-primary-500 hover:text-primary-200 ml-2 py-2 px-4"
          to="/1"
        >
          Back
        </Link>
      </div>
    );
  };

  const renderProductName = () => {
    console.log('breakpoint', breakpoint);
    switch (breakpoint) {
      case 'sm':
        return renderProductNameMobile();
      default:
        return renderProductNameDesktop();
    }
  };

  const renderProductBody = () => {
    return (
      <>
        {renderProductName()}
        {store && (
          <h3 className="">
            <div className="flex items-center">
              <img
                src={storeLogo || storeLogoPlaceholder}
                alt={store.tradingName}
                className="h-6 w-6 rounded-full mr-1"
              />
              <span className="text-gray-500 font-normal flex items-center">
                {store.tradingName}&nbsp;
                <span className="flex items-center">
                  (
                  <AiFillStar className="fill-current text-primary-500" />{' '}
                  {store.rating?.toFixed(1) ?? '5.0'})
                </span>
              </span>
            </div>
          </h3>
        )}

        <div className="grid grid-cols-2 md:flex md:flex-direction-row align-center justify-between py-2 my-3 border-b">
          <div className="font-normal text-left flex flex-row items-start">
            <div className="mr-2">{reviews?.length} Reviews</div>
            <Rating
              start={0}
              stop={5}
              step={1}
              readonly={true}
              initialRating={rating}
              emptySymbol={
                <AiFillStar
                  href="#icon-star-empty"
                  className="fill-current text-grey-300"
                />
              }
              fullSymbol={
                <AiFillStar
                  href="#icon-star-full"
                  className="fill-current text-primary-500"
                />
              }
            />
          </div>
          <div className="font-bold text-right text-2xl">
            <div>${numeral(selectedVariant?.price || 0).format('0,0.00')}</div>
            <AfterpayPlacement
              amount={
                (cartProduct?.quantity || 1) * selectedVariant?.price || 0
              }
              size="xs"
              className="my-0 py-0 font-xs font-normal"
            />
          </div>
        </div>
        <ShowMoreText
          /* Default options */
          lines={5}
          more="Show more"
          less="Show less"
          className="content-css"
          anchorClass="text-primary-500"
          onClick={() => setShowMoreText(!showMoreText)}
          expanded={showMoreText}
          truncatedEndingComponent={'... '}
        >
          <SanitizeHTML html={product.description} />
        </ShowMoreText>
        {product?.variants?.length > 1 && (
          <ProductVariants
            variants={product?.variants || []}
            options={product?.options || []}
            onSelect={setSelectedVariant}
          />
        )}

        {renderCartQuantityButtons()}
        <div className=" flex flex-row my-4">
          <Popover className="relative w-1/2 mr-2">
            {({ open }) => (
              <>
                <Popover.Button
                  disabled={!me}
                  className="group py-3 w-full px-5 bg-transparent border-2 border-primary-500
    text-primary-500 flex flex-row items-center justify-center
    hover:bg-primary-100 disabled:opacity-50"
                >
                  <FiHeart className="stroke-current group-hover:fill-current group-hover:text-primary-400" />{' '}
                  {me ? ' Add to Selection' : ' Login To Continue'}
                </Popover.Button>
                <Transition
                  as={React.Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute z-10 px-4 mt-3 sm:px-0 w-full left-0">
                    <div className="shadow-lg ring-0">
                      <div className="relative grid gap-8 bg-white p-7 lg:grid-cols-1">
                        <PopOverWishList product={product} />
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>

          {renderAddToCartButton()}
        </div>

        <ProductReviews id={product.id} reviews={reviews} />
      </>
    );
  };

  const renderProductBodyScroll = () => {
    return (
      <Scrollbars
        autoHeight
        className="lg:overscroll-contain"
        hideTracksWhenNotNeeded={true}
        renderTrackVertical={(props) => (
          <div {...props} className="track-vertical" />
        )}
        autoHeightMin="calc(90vh - 4rem)"
        autoHide={false}
      >
        {renderProductBody()}
      </Scrollbars>
    );
  };

  const renderProductInfo = () => {
    switch (breakpoint) {
      case 'sm':
        return renderProductBody();
      default:
        return renderProductBodyScroll();
    }
  };

  const renderImagesFull = () => {
    return (
      <div className="ProductImages col-span-12 md:col-span-6 p-2 md:p-8">
        <Scrollbars
          autoHeight
          renderTrackHorizontal={(props) => (
            <div
              {...props}
              style={{ display: 'none' }}
              className="track-horizontal"
            />
          )}
          className="pb-48"
          renderTrackVertical={(props) => (
            <div {...props} className="track-vertical" />
          )}
          autoHeightMin="calc(90vh - 4rem)"
          autoHide={false}
        >
          <div>
            {isEmpty(images) && product?.images?.length <= 0 && (
              <div className="NoImages">
                <h3 className="text-2xl">This product has no images</h3>
              </div>
            )}
            {!product ||
              loading ||
              (isEmpty(images) && product?.images?.length > 0 && (
                <ImageLoader />
              ))}
            {product?.images?.length > 0 && !isEmpty(images) && (
              <div className="flex md:grid md:grid-cols-1">
                {uniq(images).map((image) => (
                  <LazyLoadImage
                    effect="blur"
                    key={image}
                    alt={product.name}
                    src={image}
                    className="mb-8 flex-1 w-full h-90 flex-1"
                    // If the image we are creating here has the same src than before,
                    // we can directly display it with no need to lazy-load.
                  />
                ))}
              </div>
            )}
          </div>
        </Scrollbars>
      </div>
    );
  };

  const renderImagesMobile = () => {
    if (isEmpty(images) && product?.images?.length <= 0) {
      return (
        <div className="NoImages">
          <h3 className="text-2xl">This product has no images</h3>
        </div>
      );
    }
    if (
      !product ||
      loading ||
      (isEmpty(images) && product?.images?.length > 0)
    ) {
      return <ImageLoaderMobile />;
    }
    return (
      <Carousel
        className="col-span-12"
        showThumbs={false}
        emulateTouch={true}
        showArrows={false}
        showStatus={false}
      >
        {images.map((image) => (
          <div key={image} className="col-span-12">
            <LazyLoadImage
              effect="blur"
              alt={product?.name}
              src={image}
              className="mb-8 flex-1 w-full h-90 flex-1"
              // If the image we are creating here has the same src than before,
              // we can directly display it with no need to lazy-load.
            />
          </div>
        ))}
      </Carousel>
    );
  };

  const renderImages = () => {
    switch (breakpoint) {
      case 'sm':
        return renderImagesMobile();
      default:
        return renderImagesFull();
    }
  };

  return (
    <div className="ProductsPage">
      <Helmet>
        <title>The Giftery | Products</title>
      </Helmet>

      <div className="px-2 md:px-12">
        <Tracker type={ETrackingType.view} product={product} />

        <div className="grid grid-cols-12 gap-0 md:gap-4">
          {renderImages()}
          <div className="ProductInfo col-span-12 md:col-span-6 p-2 md:p-8">
            {!product || loading ? <ProductLoader /> : renderProductInfo()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsPage;
