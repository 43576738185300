import { DBCollection } from '@giftery/enums';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { RetailRootState } from '../store/reducers';

export function useSettings<T>(id: string): T {
  useFirestoreConnect([
    {
      collection: DBCollection.settings,
      doc: id || 'LOADING_DOC',
    },
  ]);
  const settings = useSelector((state: RetailRootState) => {
    return (state.firestore.data.settings?.[id] as T) || null;
  });
  return settings;
}
