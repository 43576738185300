import { WhereOptions } from 'react-redux-firebase';

const algoliaFacetTransforms = {
  '==': ':',
};

const algoliaNumericTransforms = {
  '==': '=',
  '!=': '!=',
  '>': '>',
  '>=': '>=',
  '<': '<',
  '<=': '<=',
};

const convertFilterArrayToAlgolia = (
  field: string,
  filters: string[]
): string => {
  const filterList: string[] = [];
  for (const filter of filters) {
    filterList.push(`${field}:${filter}`);
  }
  return filterList.join(' OR ');
};

export const convertFirebaseFiltersToAlgoliaFilters = (
  filters: WhereOptions[]
): string => {
  const algoliaFilters = filters
    .map((filter) => {
      const [field, operator, value] = filter;
      switch (typeof value) {
        case 'number':
          return `${field}${algoliaNumericTransforms[operator]}${value}`;
        case 'object':
          // Special case for handling arrays
          return convertFilterArrayToAlgolia(field, value);
        default:
          return `${field}${algoliaFacetTransforms[operator]}${value}`;
      }
    })
    .join(' AND ');

  return algoliaFilters;
};
