import { Review } from '@giftery/api-interface';
import { request } from '@giftery/axios';
import { AxiosResponse } from 'axios';

export const addProductReview = async (
  id: string,
  review: Partial<Review>
): Promise<AxiosResponse<unknown>> => {
  return await request('post', `/products/${id}/review`, review);
};
