import React from 'react';
import { isEmpty, chain, sortBy } from 'lodash';
import { FiX } from 'react-icons/fi';
import { useCart, useStore } from '../../hooks';
import { CartProduct } from './CartProduct';
import numeral from 'numeral';
import { Link } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars-2';
import './Cart.scss';
import { AfterpayPlacement } from '@giftery/ui/afterpay-placement';

interface CartProps {
  close: () => void;
}

export const Cart: React.FC<CartProps> = ({ ...props }) => {
  const { cart, cartTotal, removeFromCart, incrementCartItem } = useCart();

  const calculateShipping = () => {
    return 'Shipping calculated at checkout';
  };

  const renderCartTotals = () => {
    return (
      <>
        {/* Cart Summary */}
        <div className="col-span-4 hidden md:block mt-3"></div>
        <div className="col-span-4 hidden md:block mt-3"></div>
        <div className="col-span-12 md:col-span-4 mt-3">
          {/* Subtotal */}
          <div className="grid grid-cols-2 gap-2">
            <div className="col-span-2 text-primary-300 justify-end">
              {calculateShipping()}
            </div>
            <div className="text-primary-300 text-xl">Subtotal</div>
            <div className="text-primary-300 text-xl flex items-center justify-end font-bold">
              {numeral(cartTotal).format(`$0,0.00`)}
            </div>
          </div>
          <div className="">
            <AfterpayPlacement
              logoType="lockup"
              amount={cartTotal}
              size="xs"
              className="text-right text-primary-500"
            />
          </div>
          <div className="grid grid-cols-2 gap-2">
            <Link
              to="/checkout"
              className="
            mt-3 bg-primary-400 col-span-2 text-white py-2 px-4
            text-center no-underline hover:no-underline
            hover:bg-primary-500
            "
            >
              Checkout
            </Link>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="CartSummary px-2 md:px-10 py-6 relative bg-primary-100">
      <button
        onClick={props.close}
        className="text-primary-500 hover:text-primary-600 p-6 absolute top-0 right-0"
      >
        <FiX className="stroke-current h-8 w-8" />
      </button>
      {/* Product list */}
      <div className="grid grid-cols-12 w-full px-3 py-1">
        {cart && cart.products && !isEmpty(cart.products) ? (
          <>
            <div className="col-span-12 md:col-span-4 text-primary-500 text-2xl font-bold">
              Cart
            </div>
            <div className="col-span-4 hidden md:block text-primary-300 pl-4">
              Quantity
            </div>
            <div className="col-span-4 hidden md:block text-primary-300">
              &nbsp;
            </div>
            <Scrollbars
              autoHeight
              className="overscroll-contain col-span-12"
              hideTracksWhenNotNeeded={true}
              renderTrackVertical={(props) => (
                <div {...props} className="track-vertical" />
              )}
              autoHeightMax="35vh"
              autoHide={true}
            >
              {sortBy(cart.products, 'name').map((product) => (
                <CartProduct
                  key={product.id}
                  product={product}
                  onDelete={removeFromCart}
                  onIncrement={incrementCartItem}
                />
              ))}
            </Scrollbars>
            {renderCartTotals()}
          </>
        ) : (
          <div className="col-span-12 text-primary-500 flex items-center">
            You have no items in your cart
          </div>
        )}
      </div>
    </div>
  );
};
