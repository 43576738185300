import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useFirebase } from 'react-redux-firebase';

import 'react-nice-input-password/dist/react-nice-input-password.css';
import { PageLoader } from '@giftery/ui/page-loader';

const LogoutPage: React.FC = () => {
  const firebase = useFirebase();
  const history = useHistory();
  useEffect(() => {
    const logout = async () => {
      await firebase.logout();
    };
    logout().then(() => {
      setImmediate(() => history.push('/login'));
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <PageLoader />;
};

export default LogoutPage;
