import { Product, WithId } from '@giftery/api-interface';
import { DBCollection } from '@giftery/enums';
import { useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { RetailRootState } from '../store/reducers';

export function useExtendedProducts(
  value: number,
  limit: number
): [WithId<Product>[], boolean] {
  useFirestoreConnect([
    {
      collection: DBCollection.products,
      where: ['price', '<=', value],
      limit,
    },
  ]);
  const products = useSelector((state: RetailRootState) => {
    return (state.firestore.ordered.products as WithId<Product>[]) || [];
  });

  const loading = !isLoaded(products);
  return [products, loading];
}
