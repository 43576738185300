import { useHomePageProducts } from '../../../hooks';
import React from 'react';
import ProductItem from './ProductItem';

const ProductShowcase = () => {
  const [homePageProducts] = useHomePageProducts();

  return (
    <div className="min-w-screen flex flex-col pt-3 py-32 pb-0">
      <div className="w-full flex flex-col items-center justify-center text-center p-8 md:p-32">
        <h1 className="text-grey-800 text-7xl font-sans font-medium mb-5">
          Explore our curated gift collection.
        </h1>
        <p className="text-grey-800 text-2xl font-sans font-normal mb-5">
          Our Gifter has been working hard to bring New Zealand's best brands
          and products together in one place! Take a look at some of our
          favourite gift ideas right now.
        </p>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 w-full">
        {homePageProducts.slice(0, 15).map((product) => (
          <ProductItem key={product.id} product={product} />
        ))}
      </div>
    </div>
  );
};

export default ProductShowcase;
