import { CustomerChargePopulated, WithId } from '@giftery/api-interface';
import { DBCollection } from '@giftery/enums';
import { useSelector } from 'react-redux';
import { isLoaded, populate, useFirestoreConnect } from 'react-redux-firebase';
import { useMe } from './UseMe';
import { RetailRootState } from '../store/reducers';
import { chain } from 'lodash';

export function useCustomerCharge(
  id: string
): [CustomerChargePopulated, boolean] {
  const me = useMe();
  const populates = [{ child: 'order', root: DBCollection.orders }];
  const path = `${DBCollection.users}/${me?.id ?? 'PENDING'}/${
    DBCollection.charges
  }`;
  useFirestoreConnect([
    {
      collection: path,
      doc: id,
      populates,
    },
  ]);
  const { charge } = useSelector((state: RetailRootState) => {
    return {
      charge: populate(state.firestore, DBCollection.charges, populates),
    };
  });
  return charge || null;
}

export function useCustomerCharges(): [
  WithId<CustomerChargePopulated>[],
  boolean
] {
  const me = useMe();
  const populates = [{ child: 'order', root: DBCollection.orders }];
  const path = `${DBCollection.users}/${me?.id ?? 'PENDING'}/${
    DBCollection.charges
  }`;
  useFirestoreConnect([
    {
      collection: path,
      orderBy: ['audit.createdAt', 'desc'],
      populates,
      storeAs: 'charges',
    },
  ]);
  const { charges } = useSelector((state: RetailRootState) => {
    return {
      charges: populate(state.firestore, DBCollection.charges, populates),
    };
  });
  const chargesOrdered = chain(charges)
    .map((charge) => {
      return {
        ...charge,
        audit: {
          ...charge.audit,
          createdAt: (
            charge.audit.createdAt as FirebaseFirestore.Timestamp
          ).toDate(),
          updatedAt: (
            charge.audit.updatedAt as FirebaseFirestore.Timestamp
          ).toDate(),
        },
      };
    })
    .sortBy('audit.createdAt')
    .reverse()
    .value();
  return [chargesOrdered || null, !isLoaded(charges)];
}
