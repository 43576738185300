import React, { useState } from 'react';
import { Calendar as BigCalendar, dateFnsLocalizer } from 'react-big-calendar';
import { List, PopulatedList, WithId } from '@giftery/api-interface';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import isSameDay from 'date-fns/isSameDay';
import getDay from 'date-fns/getDay';
import { enNZ } from 'date-fns/locale';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Calendar.scss';
import { FiPlus } from 'react-icons/fi';
import { addBusinessDays, isBefore } from 'date-fns';

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales: { ...enNZ },
});

export interface CalendarEvent {
  title: string;
  start: Date;
  end: Date;
  allDay?: boolean;
  resource?: WithId<PopulatedList>;
}

export interface CalendarSlot {
  start: string | Date;
  end: string | Date;
  slots: Date[] | string[];
  action: 'select' | 'click' | 'doubleClick';
}

interface CalendarProps {
  events: CalendarEvent[];
  onEventClick: (event: CalendarEvent) => void;
  onSelectDate: (date: Date) => void;
}

export const Calendar: React.FC<CalendarProps> = ({
  events,
  onEventClick,
  onSelectDate,
}) => {
  const [hovered, setHovered] = useState<Date>(null);
  const handleHover = (date: Date | null) => {
    setHovered(date);
  };

  const DayWrapper = (props) => {
    return (
      <div
        className="rbc-day-bg"
        onMouseEnter={() => handleHover(props.value)}
        onMouseLeave={() => handleHover(null)}
      >
        {isSameDay(hovered, props.value) ? (
          <button
            className="stroke-current flex items-center justify-center text-primary-500"
            onClick={() => onSelectDate(props.value)}
          >
            <FiPlus />
            Add Giftee
          </button>
        ) : null}
      </div>
    );
    // return <div>{date?.getDate()}</div>;
  };

  return (
    <div className="CalendarContainer bg-shaded hidden sm:block">
      <div className="Calendar">
        <BigCalendar
          views={['month']}
          localizer={localizer}
          events={events}
          onSelectEvent={onEventClick}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
          components={{
            dateCellWrapper: DayWrapper,
          }}
        />
      </div>
    </div>
  );
};
