import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import HeroExplainer from './components/HeroExplainer';
import SentenceStarter from './components/SentenceStarter';
import ProductShowcase from './components/ProductShowcase';
import Testimonials from './components/Testimonials';
import Footer from './components/Footer';
import { Navbar } from '../../containers/Navbar/Navbar';
import Drawer from 'rc-drawer';
import { Menu } from '../../containers/Menu';

const HomePage = () => {
  const [checkoutOpen, setCheckoutOpen] = useState<boolean>(false);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  let autoCloseTimeout: NodeJS.Timeout | undefined;

  const toggleCheckout = (state: boolean, autoClose?: boolean) => {
    setCheckoutOpen(state);
    if (autoCloseTimeout) clearTimeout(autoCloseTimeout);
    if (state && autoClose) {
      autoCloseTimeout = setTimeout(() => setCheckoutOpen(false), 2000);
    }
  };
  return (
    <div className="min-w-screen bg-white">
      <Helmet>
        <title>The Giftery | Explore</title>
      </Helmet>
      <Drawer
        open={menuOpen}
        placement="left"
        width="250px"
        wrapperClassName="fixed top-0 left-0 bottom-0 z-10"
      >
        <Menu onClose={() => setMenuOpen(false)} />
      </Drawer>
      <Navbar
        toggleCheckout={(state, autoClose) => toggleCheckout(state, autoClose)}
        toggleMenu={() => setMenuOpen(!menuOpen)}
        checkoutOpen={checkoutOpen}
        menuOpen={menuOpen}
      />
      <HeroExplainer />
      <SentenceStarter />
      <ProductShowcase />
      <Testimonials />
      <Footer />
    </div>
  );
};

export default HomePage;
