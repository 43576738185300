import { Age, Category, Hobby, Filter, WithId } from '@giftery/api-interface';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { isEmpty, uniq } from 'lodash';
import { DistanceSlider } from '@giftery/ui/distance-slider';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { GeoLocationResults, useGeolocation } from '@giftery/ui/hooks';
import { useProductMetadata } from '../../../hooks';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';
import { FiChevronDown, FiChevronUp, FiX } from 'react-icons/fi';

interface FilterListProps {
  filters: WithId<Filter>[];
  disabled: boolean;
  selected: string[];
  addFilter: (filter: WithId<Filter>) => void;
  removeFilter: (id: string) => void;
}

const FilterList: React.FC<FilterListProps> = ({
  filters,
  selected,
  disabled,
  ...props
}) => {
  const isChecked = (filter: WithId<Filter>) => {
    return selected.includes(filter.id);
  };

  const toggleFilter = (filter: WithId<Filter>) => {
    if (isChecked(filter)) {
      props.removeFilter(filter.id);
    } else {
      props.addFilter(filter);
    }
  };

  if (!filters) return null;

  return (
    <>
      {filters.map((filter) => {
        return (
          <div key={filter.id} className="relative flex items-start">
            <div className="flex items-center h-5">
              <input
                id="comments"
                aria-describedby="comments-description"
                name="comments"
                type="checkbox"
                disabled={disabled && !isChecked(filter)}
                checked={isChecked(filter)}
                onChange={() => toggleFilter(filter)}
                className="focus:ring-0 h-4 w-4 text-primary-600 border-gray-300 disabled:bg-gray-100"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor="comments" className="font-medium text-gray-700">
                {filter.name}
              </label>
            </div>
          </div>
        );
      })}
    </>
  );
};

interface FiltersProps {
  className?: string;
  initial?: string[];
  onChange: (filters: string[], distance?: GeoLocationResults) => void;
  toggleLocation: (value: boolean) => void;
}

const Filters: React.FC<FiltersProps> = ({ className, initial, ...props }) => {
  const [categoriesLoaded, setCategoriesLoaded] = useState(false);
  const [categories, setCategories] = useState<WithId<Category>[]>([]);
  const [hobbiesLoaded, setHobbiesLoaded] = useState(false);
  const [hobbies, setHobbies] = useState<WithId<Hobby>[]>([]);
  const [agesLoaded, setAgesLoaded] = useState(false);
  const [ages, setAges] = useState<WithId<Age>[]>([]);
  const [locationEnabled, setLocationEnabled] = useState<boolean>(false);
  const geolocation = useGeolocation();
  const [categoryData, hobbyData, ageData] = useProductMetadata();
  const filterList = useMemo(() => {
    const filters = uniq([
      ...categories.map((c) => c.id),
      ...hobbies.map((h) => h.id),
      ...ages.map((a) => a.id),
    ]);
    return filters;
  }, [categories, hobbies, ages]);

  // Update geolocation if changed
  useEffect(() => {
    if (geolocation instanceof GeolocationPositionError) {
      if (locationEnabled) setLocationEnabled(false);
    }
    props.toggleLocation(locationEnabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geolocation, locationEnabled]);

  useEffect(() => {
    if (initial && hobbyData && !hobbiesLoaded) {
      setHobbiesLoaded(true);
      const hobbiesFiltered = hobbyData.filter((h) => initial.includes(h.id));
      setHobbies(hobbiesFiltered);
    }
  }, [initial, hobbyData, hobbies]);

  useEffect(() => {
    if (initial && categoryData && !categoriesLoaded) {
      setCategoriesLoaded(true);
      const categoriesFiltered = categoryData.filter((c) =>
        initial.includes(c.id)
      );
      setCategories(categoriesFiltered);
    }
  }, [initial, categoryData, categories]);

  useEffect(() => {
    if (initial && ageData && !agesLoaded) {
      setAgesLoaded(true);
      const agesFiltered = ageData.filter((a) => initial.includes(a.id));
      setCategories(agesFiltered);
    }
  }, [ageData, ages]);

  useEffect(() => {
    props.onChange(filterList);
  }, [filterList]);

  const clearFilters = () => {
    setAges([]);
    setCategories([]);
    setHobbies([]);
    props.onChange([]);
  };

  const hasFilters = () => {
    return !isEmpty(hobbies) || !isEmpty(categories) || !isEmpty(ages);
  };

  const updateFilter = <T extends Filter>(
    filter: WithId<T> | string,
    prevValue: WithId<T>[],
    setter: React.Dispatch<React.SetStateAction<WithId<T>[]>>
  ) => {
    switch (typeof filter) {
      case 'string':
        setter(prevValue.filter((f) => f.id !== filter));
        break;
      default:
        setter([...prevValue, filter]);
        break;
    }
  };

  const updateDistance = (d: number) => {
    if (geolocation instanceof Error) return;
    props.onChange(filterList, {
      distance: d,
      geolocation: geolocation,
    });
  };

  return (
    <div className="p-0 font-sans">
      <div className={className ? className : 'py-2'}>
        <div className="Filters">
          <Scrollbars
            autoHeight
            className="overscroll-contain"
            hideTracksWhenNotNeeded={true}
            renderTrackVertical={(props) => (
              <div {...props} className="track-vertical" />
            )}
            autoHeightMax="calc(75vh - 4rem)"
            autoHide={false}
          >
            <div className="flex row items-center justify-between">
              <h3 className="text-2xl text-primary-500 text-left font-serif font-thin">
                Filter
              </h3>
              {hasFilters() && (
                <button
                  className="bg-transparent text-xs text-primary-500 hover:text-primary-400 flex items-center justify-center"
                  onClick={clearFilters}
                >
                  <FiX /> Clear Filters
                </button>
              )}
            </div>
            <Accordion
              allowMultipleExpanded
              allowZeroExpanded
              preExpanded={[2]}
            >
              <AccordionItem uuid={1}>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <div className="FilterHeading mt-2 text-primary-500 flex items-center justify-between">
                      <div>
                        By Categories{' '}
                        {categories.length ? `(${categories.length})` : ''}
                      </div>
                      <AccordionItemState>
                        {({ expanded }) =>
                          expanded ? <FiChevronUp /> : <FiChevronDown />
                        }
                      </AccordionItemState>
                    </div>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <FilterList
                    filters={categoryData}
                    selected={categories.map((c) => c.id)}
                    addFilter={(filter) =>
                      updateFilter<Category>(filter, categories, setCategories)
                    }
                    removeFilter={(filter) =>
                      updateFilter<Category>(filter, categories, setCategories)
                    }
                    disabled={filterList.length >= 10}
                  />
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem uuid={2}>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <div className="FilterHeading mt-2 text-primary-500 flex items-center justify-between">
                      <div>
                        By Interests{' '}
                        {hobbies.length ? `(${hobbies.length})` : ''}
                      </div>
                      <AccordionItemState>
                        {({ expanded }) =>
                          expanded ? <FiChevronUp /> : <FiChevronDown />
                        }
                      </AccordionItemState>
                    </div>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <FilterList
                    filters={hobbyData}
                    addFilter={(filter) =>
                      updateFilter<Hobby>(filter, hobbies, setHobbies)
                    }
                    removeFilter={(filter) =>
                      updateFilter<Hobby>(filter, hobbies, setHobbies)
                    }
                    selected={hobbies.map((c) => c.id)}
                    disabled={filterList.length >= 10}
                  />
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem uuid={3}>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <div className="FilterHeading mt-2 text-primary-500 flex items-center justify-between">
                      <div>By Age {ages.length ? `(${ages.length})` : ''}</div>
                      <AccordionItemState>
                        {({ expanded }) =>
                          expanded ? <FiChevronUp /> : <FiChevronDown />
                        }
                      </AccordionItemState>
                    </div>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <FilterList
                    filters={ageData}
                    selected={ages.map((c) => c.id)}
                    addFilter={(filter) =>
                      updateFilter<Age>(filter, ages, setAges)
                    }
                    removeFilter={(filter) =>
                      updateFilter<Age>(filter, ages, setAges)
                    }
                    disabled={filterList.length >= 10}
                  />
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>

            <DistanceSlider
              min={0}
              max={200}
              onChange={updateDistance}
              disabled={!locationEnabled}
              toggle={setLocationEnabled}
            />
          </Scrollbars>
        </div>
      </div>
    </div>
  );
};

export default memo(Filters);
