import { BillingAddress, GiftVoucher } from '@giftery/api-interface';
import React, { useEffect, useState } from 'react';
import { useCart } from '../../hooks';
import { isEmpty, sortBy } from 'lodash';
import { CartProduct } from '../Cart/CartProduct';
import { AfterpayPlacement } from '@giftery/ui/afterpay-placement';
import numeral from 'numeral';
import { calculateGSTFromTotal, calculateShipping } from '@giftery/utils';
import { getVoucher } from '../../actions/Checkout';
import Loader from 'react-loader-spinner';
import { colors } from '@giftery/theme';
import toast from 'react-hot-toast';
import { FiX } from 'react-icons/fi';

interface OrderSummaryProps {
  shippingAddress: BillingAddress;
  voucher: GiftVoucher | undefined;
  voucherCheckInProgress: boolean;
  allowAll: (allowAll: boolean) => void;
  applyVoucher: (code: string) => void;
}

export const OrderSummary: React.FC<OrderSummaryProps> = ({
  shippingAddress,
  voucher,
  voucherCheckInProgress,
  ...props
}) => {
  const [voucherCode, setVoucherCode] = useState<string>('');
  const { cart, cartTotal, removeFromCart, incrementCartItem } = useCart();
  const isGift = !!cart.listId;
  const clearVoucher = async () => {
    props.applyVoucher(null);
  };

  const renderVoucherValue = (grandTotal: number) => {
    return (
      <div className="grid grid-cols-2 gap-2">
        <div className="text-primary-300 text-xl flex items-center">
          <span>Voucher</span>
          <button
            onClick={clearVoucher}
            className="text-primary-500 hover:text-primary-400 flex items-center text-xs"
          >
            Remove <FiX size={12} />
          </button>
        </div>
        <div className="text-primary-300 text-xl flex items-center justify-end">
          -{numeral(Math.min(voucher.value, grandTotal)).format(`$0,0.00`)}
        </div>
      </div>
    );
  };

  const renderVoucherInput = () => {
    return (
      <div className="grid grid-cols-2 gap-2">
        <div className="text-primary-300 text-xl flex items-center">
          Voucher
        </div>
        <div className="text-primary-300 text-xl flex items-center justify-end font-bold relative">
          <input
            id="voucher"
            name="voucher"
            type="text"
            autoComplete="false"
            value={voucherCode}
            onChange={(e) => setVoucherCode(e.target.value)}
            onBlur={() => props.applyVoucher(voucherCode)}
            className="
                my-2 py-2 px-2 shadow-none outline-none
                border-l-0 border-t-0 border-r-0 border-b-2 border-primary-300
                focus:border-primary-500 focus:outline-none focus:shadow-none focus:ring-0
                block w-full"
          />
          {voucherCheckInProgress && (
            <div className="absolute right-2">
              <Loader
                type="Oval"
                color={colors.primary[500]}
                height={24}
                width={24}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  const allowAllItemsInCart = () => {
    props.allowAll(!cart.allowAll);
  };

  const renderDisclaimer = () => {
    if (!isGift) {
      return null;
    }
    return (
      <div className="col-span-12 md:col-span-9">
        <p className="text-primary-300 text-md flex items-start p-0">
          We will place a hold on your card for the most expensive item from
          your selection, until your giftee has chosen a gift. When they choose
          a gift, we will automatically release the difference between the gift
          price and the hold, if any, when they make their selection.
        </p>
        <div className="relative flex items-start py-3">
          <div className="flex items-center h-5">
            <input
              id="comments"
              aria-describedby="comments-description"
              name="comments"
              type="checkbox"
              checked={cart?.allowAll}
              onChange={allowAllItemsInCart}
              className="focus:ring-primary-500 h-5 w-5 text-primary-600 border-primary-300"
            />
          </div>
          <div className="ml-3">
            <label htmlFor="comments" className="font-medium text-primary-300">
              Send the whole cart to my giftee
            </label>
          </div>
        </div>
      </div>
    );
  };

  const renderCartTotals = () => {
    const [shippingRate] = calculateShipping(cart, shippingAddress);
    const voucherTotal = voucher?.value || 0;
    const grandTotal = shippingRate + cartTotal;
    const totalDue = Math.max(grandTotal - voucherTotal, 0);
    return (
      <>
        {/* Cart Summary */}
        <div className="grid md:grid grid-cols-12 gap-2 col-span-12 md:col-span-8 mt-3">
          {renderDisclaimer()}
        </div>
        <div className="col-span-12 md:col-span-4 mt-3">
          {/* Subtotal */}
          <div className="grid grid-cols-2 gap-2">
            <div className="text-primary-300 text-xl">Subtotal</div>
            <div className="text-primary-300 text-xl flex items-center justify-end">
              {numeral(cartTotal).format(`$0,0.00`)}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-2">
            <div className="text-primary-300 text-xl">
              <div>Shipping</div>
            </div>
            <div className="text-primary-300 text-xl flex items-center justify-end">
              {numeral(shippingRate).format(`$0,0.00`)}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-2">
            <div className="text-primary-300 text-xl">Includes GST of</div>
            <div className="text-primary-300 text-xl flex items-center justify-end">
              {numeral(calculateGSTFromTotal(grandTotal)).format(`$0,0.00`)}
            </div>
          </div>
          {voucher ? renderVoucherValue(grandTotal) : renderVoucherInput()}
          <div className="grid grid-cols-2 gap-2">
            <div className="text-primary-300 text-xl">Total</div>
            <div className="text-primary-300 text-xl flex items-center justify-end font-bold">
              {numeral(totalDue).format(`$0,0.00`)}
            </div>
          </div>
          <div className="">
            <AfterpayPlacement
              logoType="lockup"
              amount={totalDue}
              size="xs"
              className="text-right text-primary-500"
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="bg-primary-100 p-8 mt-4">
      <div className="grid grid-cols-12 w-full px-3 py-1">
        {cart && cart.products && !isEmpty(cart.products) ? (
          <>
            <div className="col-span-12 md:col-span-4 text-primary-500 text-2xl font-bold">
              Order Summary
            </div>
            <div className="col-span-1 hidden md:block text-primary-300 pl-4"></div>
            <div className="col-span-2 hidden md:block text-primary-300 pl-4">
              Quantity
            </div>
            <div className="col-span-1 hidden md:block text-primary-300 pl-4"></div>
            <div className="col-span-4 hidden md:block text-primary-300">
              &nbsp;
            </div>
            <div className="col-span-12">
              {sortBy(cart.products, 'name').map((product) => (
                <CartProduct
                  key={`${product?.id}-${product?.variant?.type}`}
                  product={product}
                  onDelete={removeFromCart}
                  onIncrement={incrementCartItem}
                />
              ))}
            </div>
            {renderCartTotals()}
          </>
        ) : (
          <div className="col-span-12 text-primary-500 flex items-center">
            You have no items in your cart
          </div>
        )}
      </div>
    </div>
  );
};
