import { ProductVariant, ProductVariantOption } from '@giftery/api-interface';
import { cloneDeep, keyBy } from 'lodash';
import { classNames } from '@giftery/utils';
import { RadioGroup } from '@headlessui/react';
import React, { useEffect, useState } from 'react';

interface ProductVariantsProps {
  options: ProductVariantOption[];
  variants: ProductVariant[];
  onSelect: (variants: ProductVariant) => void;
}

export const ProductVariants: React.FC<ProductVariantsProps> = ({
  variants,
  options,
  ...props
}) => {
  const [selectedVariant, setSelectedVariant] = useState<ProductVariant>();
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const variantsByType = keyBy(variants || {}, 'type');

  useEffect(() => {
    const type = selectedOptions.join('/');
    const variant: ProductVariant | undefined = variantsByType[type];
    if (!variant) return;
    setSelectedVariant(variant);
    props.onSelect(variant);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions]);

  const updateOption = (value: string, index: number) => {
    let copySelectedOptions = cloneDeep(selectedOptions);
    copySelectedOptions[index] = value;
    copySelectedOptions = copySelectedOptions.slice(0, index + 1);
    setSelectedOptions(copySelectedOptions);
  };

  const renderVariant = (
    value: string,
    index: number,
    parentDisabled: boolean
  ) => {
    const prefix = selectedOptions.slice(0, index).join('/');
    const type = `${prefix}/${value}`;
    const variant: ProductVariant | undefined = variantsByType[type];
    const variantDisabled = variant && variant?.quantity <= 0;
    const optionDisabled = index > 0 && !selectedOptions[index - 1];
    const disabled = parentDisabled || variantDisabled || optionDisabled;
    return (
      <RadioGroup.Option
        key={`variant-${value}`}
        value={value}
        className={({ active, checked }) =>
          classNames(
            !disabled || variant?.quantity > 0
              ? 'cursor-pointer focus:outline-none'
              : 'opacity-25 cursor-not-allowed',
            active ? 'border-primary-500' : '',
            checked
              ? 'border-primary-500 text-primary-500 hover:bg-primary-700'
              : 'bg-white border-gray-200 text-gray-900 hover:bg-gray-50',
            'border-2 py-3 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1 min-h-24'
          )
        }
        disabled={disabled}
      >
        <RadioGroup.Label as="div" className="text-center">
          <div>{value}</div>
          {variant?.quantity <= 0 ? (
            <div className="text-xs" style={{ fontSize: '.65em' }}>
              Out of Stock
            </div>
          ) : null}
        </RadioGroup.Label>
      </RadioGroup.Option>
    );
  };

  const renderVariants = (option: ProductVariantOption, index: number) => {
    const disabled = index > 0 && !selectedOptions[index - 1];

    return (
      <RadioGroup
        value={selectedOptions[index]}
        onChange={(value) => updateOption(value, index)}
        className="mt-2"
      >
        <div className="grid grid-cols-3 gap-3 sm:grid-cols-4">
          {option.values.map((variant) =>
            renderVariant(variant, index, disabled)
          )}
        </div>
      </RadioGroup>
    );
  };

  const renderOption = (option: ProductVariantOption, index: number) => {
    return (
      <div className="py-2 px-1" key={`variantoption-${index}`}>
        <h2 className="text-xl capitalize">{option.name}</h2>
        {renderVariants(option, index)}
      </div>
    );
  };

  return <>{options?.map(renderOption) || null}</>;
};
