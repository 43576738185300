import { List } from '@giftery/api-interface';
import { request } from '@giftery/axios';
import { AxiosResponse } from 'axios';

export const createList = async (
  list: Partial<List>
): Promise<AxiosResponse<unknown>> => {
  return await request('post', '/lists', { list });
};

export const updateList = async (
  id: string,
  list: Partial<List>
): Promise<AxiosResponse<unknown>> => {
  return await request('put', `/lists/${id}`, { list });
};

export const deleteList = async (
  id: string
): Promise<AxiosResponse<unknown>> => {
  return await request('delete', `/lists/${id}`);
};
