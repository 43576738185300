import React, {
  Fragment,
  MouseEvent,
  Suspense,
  useMemo,
  useState,
} from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { List, PopulatedList, WithId } from '@giftery/api-interface';
import 'react-day-picker/lib/style.css';
import '../CreateGifteeModal.scss';
import { formatDistance } from 'date-fns';
import ProductItem from './ProductItem';
import './ViewListModal.scss';
import { ProductLoader } from './ProductLoader';
import { useCart, useProductMetadata } from '../../../../hooks';
import { updateList } from '../../../../actions/Lists';
import { Tag, TagsInput } from '@giftery/ui/tagsinput';
import { LoadingButton } from '@giftery/ui/loading-button';

interface ViewGifteeModalProps {
  open: boolean;
  className?: string;
  list: WithId<PopulatedList>;
  toggle: () => void;
}

export const ViewListModal: React.FC<ViewGifteeModalProps> = ({
  open,
  className,
  list,
  ...props
}) => {
  const { populateCart } = useCart();
  const [interests, setInterests] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [categories, hobbies] = useProductMetadata();

  const interestList = useMemo((): Tag[] => {
    return [...(categories || []), ...(hobbies || [])].map((i) => ({
      id: i.id,
      name: i.name,
    }));
  }, [categories, hobbies]);

  const selectedInterestsList = useMemo(() => {
    return list?.interests && interestList
      ? list.interests?.map((id) =>
          interestList?.find((interest) => interest.id === id)
        )
      : [];
  }, [interestList, list]);

  const renderDate = () => {
    if (!list.date) return null;
    return formatDistance(
      (list.date as FirebaseFirestore.Timestamp).toDate(),
      new Date(),
      {
        addSuffix: true,
      }
    );
  };

  if (!list) return null;

  const addItemsToCart = async () => {
    await populateCart(list.products, list);
    props.toggle();
  };

  const updateInterests = (tags: Tag[]) => {
    setInterests(tags.map((t) => t.id));
  };

  const saveAndClose = async () => {
    setLoading(true);
    await updateList(list.id, { interests });
    setLoading(false);
    props.toggle();
  };

  return (
    <Transition.Root show={open} as="div" key="ViewListModalTransition">
      <Dialog
        as="div"
        auto-reopen="true"
        className="fixed z-50 inset-0 overflow-y-auto p-0 md:p-6"
        onClose={props.toggle}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className="
              inline-block align-middle bg-white p-4 md:p-8 text-left w-full md:w-3/4 overflow-hidden shadow-xl transform transition-all"
            >
              {/* Body */}
              <h2 className="text-2xl">{list.name}</h2>
              <p className="my-0">
                {list.description}{' '}
                <span className="font-bold">{renderDate()}</span>
              </p>
              <div>
                <label>Interests</label>
                <TagsInput
                  containerClassName="my-2 border-2 border-primary-200 p-2 w-full focus:outline-none focus:border-primary-500"
                  tagClassName="lowercase"
                  initial={selectedInterestsList}
                  data={selectedInterestsList}
                  autocompleteData={interestList}
                  placeholder="Add an interest..."
                  onChange={updateInterests}
                />
              </div>
              {list.products?.length > 0 ? (
                <p className="my-0 mb-3">
                  Here's what you've got on their list so far:
                </p>
              ) : (
                <p className="my-0">
                  You currently have nothing saved to this list
                </p>
              )}
              <div className="ListProducts">
                {list.products?.map((product, idx) => {
                  return (
                    <Suspense
                      key={product?.id ?? idx}
                      fallback={<ProductLoader />}
                    >
                      <ProductItem
                        product={product}
                        list={list}
                        key={product.id}
                      />
                    </Suspense>
                  );
                })}
                {/* footer */}
                <div className="w-full flex justify-end">
                  <button
                    className="py-2 px-4 mr-2 bg-transparent text-primary-400 border border-2 border-primary-500 hover:bg-primary-500 hover:text-white active:bg-primary-600 rounded-0 focus:outline-none focus:ring-0 focus:shadow-none"
                    onClick={addItemsToCart}
                  >
                    Add Items To Cart
                  </button>
                  <LoadingButton
                    className="py-2 px-4 bg-primary-400 text-white hover:bg-primary-500 active:bg-primary-600 rounded-0 focus:outline-none focus:ring-0 focus:shadow-none"
                    onClick={saveAndClose}
                    disabled={loading}
                    loading={loading}
                    feedback="Saving..."
                  >
                    Save & Close
                  </LoadingButton>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
