import { Product, WithId } from '@giftery/api-interface';
import React, { useEffect, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import './ProductSearch.scss';
import { client } from '@giftery/algolia-client';
import { Hit, MultipleQueriesQuery } from '@algolia/client-search';
import { OrderByOptions } from 'react-redux-firebase';
import { isEqual, sortBy } from 'lodash';
import { FiX } from 'react-icons/fi';
import { SearchResponse } from '@algolia/client-search';
interface ProductSearchProps {
  onSearch: (results: SearchResponse<WithId<Product>>, query: string) => void;
  onClearSearch: () => void;
  initial: string;
  isSearching: boolean;
  filters: string[];
  orderBy: OrderByOptions;
  page: number;
  limit: number;
}

const ProductSearch: React.FC<ProductSearchProps> = ({
  page,
  limit,
  initial,
  filters,
  orderBy,
  isSearching,
  ...props
}) => {
  const [results, setResults] = useState<SearchResponse<WithId<Product>>>();
  const [query, setQuery] = useState<string>(initial);

  useEffect(() => {
    if (initial || initial.trim() !== '') {
      setQuery(initial);
      doSearch();
    }
  }, []);

  useEffect(() => {
    if (isSearching) doSearch();
  }, [filters]);

  useEffect(() => {
    if (!results) return;
    props.onSearch(results, query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results]);

  useEffect(() => {
    if (!query || query.trim() === '') return;
    doSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, page, limit]);

  const getFilterString = () => {
    const baseFilters = ['status.active:true'];
    const searchFilters =
      filters?.map((filter) => {
        return `filters:${filter}`;
      }) ?? null;
    if (searchFilters) {
      const searchFilterString = searchFilters.join(' OR ');
      baseFilters.push(searchFilterString);
    }
    return baseFilters.join(' AND ');
  };

  const doSearch = async () => {
    const searchFilters = getFilterString();
    const searchPayload: MultipleQueriesQuery = {
      indexName: 'products',
      params: {
        filters: searchFilters,
        page: Math.max(page - 1, 0),
        hitsPerPage: limit,
      },
      query,
    };
    const response = await client.search<WithId<Product>>([searchPayload]);
    const results = response.results[0];
    const hits = results.hits.map((hit) => {
      hit.id = hit.objectID;
      return hit;
    });
    results.hits = hits;
    setResults(results);
  };

  const clearSearch = () => {
    setResults(null);
    setQuery('');
    props.onClearSearch();
  };

  const onChange = async ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(value);
  };
  return (
    <div className="ProductSearch">
      <DebounceInput
        placeholder="Search for a product"
        type="text"
        className="w-full font-sans bg-primary-100 rounded-none text-primary-500"
        value={query}
        minLength={3}
        debounceTimeout={1000}
        onChange={onChange}
      />
      {query.length > 0 && (
        <button
          className="ClearSearch text-primary-500 hover:text-primary-500 hover:opacity-50"
          onClick={clearSearch}
        >
          <FiX />
        </button>
      )}
    </div>
  );
};

export default ProductSearch;
