import React, { useMemo, useState } from 'react';
import AutosizeInput from 'react-input-autosize';
import { Squiggly } from '@giftery/ui/squiggly';
import { useProductMetadata } from '../../../hooks';
import { WithContext as ReactTags, Tag } from 'react-tag-input';
import { useHistory } from 'react-router-dom';
import { NavigationService } from '@giftery/services/navigation';
import './SentenceStarter.scss';
const SentenceStarter = () => {
  const [person, setPerson] = useState('');
  const [occassion, setOccassion] = useState('');
  const [interests, setInterests] = useState<Tag[]>([]);
  const [categoryData, interestData] = useProductMetadata();
  const history = useHistory();
  const navigationService: NavigationService = new NavigationService(history);
  const interestList = useMemo((): Tag[] => {
    return [...(categoryData || []), ...(interestData || [])].map((i) => ({
      id: i.id,
      text: i.name,
    }));
  }, [categoryData, interestData]);

  const KeyCodes = {
    comma: 188,
    enter: [10, 13],
  };

  const delimiters = [...KeyCodes.enter, KeyCodes.comma];

  const submitSentence = () => {
    console.log(interests);
    navigationService.go('/1', {
      preserveQueryStrings: true,
      target: '_top',
      state: {
        filters: interests.map((i) => i.id).join(','),
      },
    });
  };

  const addInterest = (tag: Tag) => {
    //Limit to 10
    if (interestList.find((i) => i.id === tag.id)) {
      const newInterests = [...interests, tag].slice(0, 5);
      setInterests(newInterests);
    }
  };

  const deleteInterest = (index: number) => {
    setInterests(interests.filter((i, idx) => idx !== index));
  };

  return (
    <div className="h-512px min-w-screen flex flex-row pt-3 bg-primary-500">
      <div className="min-h-full w-full flex flex-col items-center justify-center text-center p-8 md:p-20">
        <h1 className="text-white text-5xl font-sans font-medium max-w-screen-lg">
          The gift is for&nbsp;
          <AutosizeInput
            inputClassName="bg-transparent rounded-none text-5xl text-white
             inline border-t-0 border-l-0 border-r-0 border-b-2 border-white
             placeholder-primary-100 placeholder-primary-100::placeholder
             focus:outline-none focus:shadow-none text-left"
            placeholderIsMinWidth
            value={occassion}
            onChange={(e) => setOccassion(e.target.value || '')}
            placeholder="a birthday  "
          />{' '}
          and their interests include&nbsp;
          <div className="inline">
            <ReactTags
              classNames={{
                activeSuggestion: 'bg-primary-100 text-primary-500',
                selected: 'inline-flex items-start justify-start',
                remove: 'text-3xl mx-2',
                tag: 'text-5xl mr-2 border-b-2 border-white flex items-center justify-center lowercase',
                tags: 'inline-flex',
                suggestions: 'text-2xl text-primary-500 bg-white suggestion',
                tagInput:
                  'text-white bg-transparent border-0 outline-0 ring-0 focus:ring-0 placeholder:text-white p-0',
                tagInputField:
                  'bg-transparent border-0 border-b-2 border-white outline-0 ring-0 focus:ring-0 focus:border-white text-5xl text-white placeholder:text-white p-0 taginputfield',
              }}
              allowDragDrop={false}
              placeholder="e.g. hiking, food..."
              tags={interests}
              suggestions={interestList}
              handleDelete={deleteInterest}
              handleAddition={addInterest}
              delimiters={delimiters}
            />
          </div>
        </h1>
        <button
          className="text-white text-4xl font-sans mt-12 text-center flex flex-row justify-center items-center underline inline w-full"
          onClick={submitSentence}
        >
          FIND GIFTS{' '}
          <Squiggly className="ml-1" color="primary-50" height={18} />
        </button>
      </div>
    </div>
  );
};

export default SentenceStarter;
