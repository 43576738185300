import React from 'react';
import './ui-afterpay-placement.module.scss';

/* eslint-disable-next-line */
export interface AfterpayPlacementProps {
  amount: number;
  className?: string;
  locale?: 'en_NZ' | 'en_AU';
  currency?: 'NZD' | 'AUD';
  showUpperLimit?: boolean;
  showLowerLimit?: boolean;
  logoType?: 'badge' | 'lockup';
  badgeTheme?:
    | 'black-on-mint'
    | 'mint-on-black'
    | 'black-on-white'
    | 'white-on-black';
  modalTheme?: 'white' | 'mint';
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

export const AfterpayPlacement: React.FC<AfterpayPlacementProps> = ({
  amount,
  className,
  locale = 'en_NZ',
  currency = 'NZD',
  showUpperLimit = true,
  showLowerLimit = true,
  logoType = 'badge',
  badgeTheme = 'black-on-mint',
  modalTheme = 'white',
  size = 'md',
}) => {
  const renderAfterPay = () => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: `<afterpay-placement
    class="${className || 'py-0 my-0'}"
    data-locale="${locale}"
    data-currency="${currency}"
    data-show-upper-limit="${showUpperLimit}"
    data-show-lower-limit="${showLowerLimit}"
    data-logo-type="${logoType}"
    data-badge-theme="${badgeTheme}"
    data-modal-theme="${modalTheme}"
    data-size="${size}"
    data-amount="${amount}"></afterpay-placement>`,
        }}
      ></div>
    );
  };
  return <>{renderAfterPay()}</>;
};
