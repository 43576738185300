import React from 'react';
import { Product, WithId } from '@giftery/api-interface';
import { Tracker } from '@giftery/ui/tracking';
import numeral from 'numeral';
import { FiHeart, FiStar } from 'react-icons/fi';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { ETrackingType } from '@giftery/enums';
import { useStore } from '../../../hooks';
import { AiFillStar } from 'react-icons/ai';
import ContentLoader from 'react-content-loader';
import { Gifter } from '@giftery/ui/gifter';

const StoreLoader = (props) => {
  return (
    <ContentLoader viewBox="0 0 500 40" height={40} {...props}>
      <rect x="0" y="13" rx="0" ry="0" width={1060} height="12" />
    </ContentLoader>
  );
};

interface ProductNormalProps {
  product: WithId<Product>;
  images: string[];
}

export const ProductNormal: React.FC<ProductNormalProps> = ({
  product,
  images,
  ...props
}) => {
  const store = useStore(product.supplierId);

  const renderStoreRating = () => {
    if (store && store.rating) {
      return (
        <span className="flex items-center">
          (<AiFillStar className="fill-current text-primary-500" />
          {store.rating.toFixed(1)})
        </span>
      );
    }
    return null;
  };

  const renderStore = () => {
    if (!store)
      return (
        <div className="relative overflow-hidden">
          <StoreLoader className="w-full px-6" />
        </div>
      );
    return (
      <div className="flex items-center justify-start text-left">
        <span className="text-gray-300 font-normal flex items-center justify-start text-left text-sm pt-2 capitalize">
          {store?.tradingName || ''}&nbsp;
          {renderStoreRating()}
        </span>
      </div>
    );
  };

  const renderFeaturedBadge = () => {
    if (!product.status.featured) return null;
    return (
      <div className="absolute z-30 flex flex-row w-1/3 items-center justify-center top-left bg-primary-500 p-1 -mx-2 top-8 text-white font-sans">
        <FiHeart /> Featured
      </div>
    );
  };
  const productImage = images?.[0];
  return (
    <div className="card relative flex flex-col bg-transparent transform duration-100 cursor-pointer justify-flex-start border-none p-2 min-h-full hover:bg-primary-100">
      {renderFeaturedBadge()}
      <Tracker type={ETrackingType.impression} product={product} />
      <div className="prod-img bg-white flex items-center justify-start overflow-hidden">
        {productImage ? (
          // If the image we are creating here has the same src than before,
          // we can directly display it with no need to lazy-load.
          <LazyLoadImage
            placeholder={
              <Gifter className="p-8 h-72 max-h-72 bg-white w-full object-cover object-center stroke-current fill-current max-w-full max-h-full text-primary-400" />
            }
            wrapperClassName="w-full"
            effect="blur"
            alt={product.name}
            src={productImage}
            className="h-72 max-h-72 bg-white w-full object-cover bg-gray-50"
          />
        ) : (
          <Gifter className="p-8 h-72 max-h-72 bg-white w-full object-cover object-center stroke-current fill-current max-w-full max-h-full text-primary-400" />
        )}
      </div>
      <div className="py-2">
        <div className="ProductBadges relative"></div>
        <div className="prod-title">
          {renderStore()}
          <p className="text-left text-gray-800 font-medium font-sans text-md pb-2 capitalize ">
            {product.name}
          </p>
        </div>

        <div className="bg-transparent rounded-lg">
          <div className="flex items-center justify-start">
            <p className="text-black font-black	text-md text-left">
              {numeral(product.variants?.[0]?.price || product.price).format(
                '$0,0.00'
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
