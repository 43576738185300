import { request } from '@giftery/axios';
import { AxiosResponse } from 'axios';

export const checkUserExists = async (
  email: string
): Promise<AxiosResponse<unknown>> => {
  return await request('post', '/users/check', { email });
};

export const exchangeToken = async (
  token: string
): Promise<AxiosResponse<string>> => {
  return await request('get', `/auth/token/${token}`);
};
