import { StoreMetadata, StoreProductCounts } from '@giftery/api-interface';
import { request } from '@giftery/axios';
import { AxiosResponse } from 'axios';

export const getMetadata = async (): Promise<AxiosResponse<StoreMetadata>> => {
  return await request('get', '/metadata');
};

export const getProductCount = async (
  filters?: string
): Promise<AxiosResponse<StoreProductCounts>> => {
  return await request('post', '/metadata', { filters });
};
