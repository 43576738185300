import { Product, WithId } from '@giftery/api-interface';
import React, { useEffect, useRef, useState } from 'react';
import { storage } from '@giftery/firebase';
import './ProductItem.scss';
import { ProductNormal } from './ProductItemNormal';
import { useHistory, useRouteMatch } from 'react-router';
import { validURL } from '@giftery/utils';
import { Link } from 'react-router-dom';

interface ProductItemProps {
  product: WithId<Product>;
}

const ProductItem = ({ product }: ProductItemProps) => {
  const [productImages, setProductImages] = useState<string[]>([]);
  const isMounted = useRef(false);
  const getImage = async () => {
    let mainImage = product?.images[0];
    let imageUrl = mainImage;
    if (!imageUrl) return;
    if (!validURL(imageUrl)) {
      imageUrl = await storage
        .ref(`/images/${imageUrl}_300x300`)
        .getDownloadURL();
    }
    if (isMounted.current) {
      setProductImages((existing) => [...existing, imageUrl]);
    }
  };

  useEffect(() => {
    isMounted.current = true;
    getImage().then();
    return () => {
      isMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Link to={`/product/${product.id}`}>
      <ProductNormal product={product} images={productImages} />
    </Link>
  );
};

export default ProductItem;
