import React from 'react';
import { formatDistance } from 'date-fns';
import { FiGift, FiShoppingBag, FiTrash } from 'react-icons/fi';
import { PopulatedList, WithId } from '@giftery/api-interface';
import { useHistory } from 'react-router';
import { deleteList } from '../../../actions/Lists';
import swal from 'sweetalert2';
import { toast } from 'react-hot-toast';
import { colors } from '@giftery/theme';
import { NavigationService } from '@giftery/services/navigation';

interface GifteeProps {
  list: WithId<PopulatedList>;
}

export const Giftee: React.FC<GifteeProps> = ({ list }) => {
  const history = useHistory();
  const navigationService: NavigationService = new NavigationService(history);
  const deleteGiftee = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const result = await swal.fire({
      title: `Are you sure?`,
      text: `This action cannot be undone! Please confirm you'd like to delete the following giftee: ${list.name}`,
      icon: 'error',
      cancelButtonText: 'No, Go Back',
      confirmButtonText: `Yes, Delete This Giftee`,
      confirmButtonColor: colors.danger,
      cancelButtonColor: colors.grey[100],
      showCancelButton: true,
    });
    if (!result.isConfirmed) return;
    toast.promise(deleteList(list.id), {
      loading: 'Deleting giftee...',
      success: `Success! We've deleted ${list.name} from your giftee list.`,
      error: `Something went wrong deleting this giftee. Please try again later`,
    });
  };

  const shop = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    navigationService.go(`/1`, {
      preserveQueryStrings: true,
      state: {
        filters: list?.interests?.length > 0 ? list.interests.join(',') : null,
      },
    });
  };

  return (
    <div
      className="card p-4 bg-white rounded-none mb-2 flex flex-row items-center justify-between border-0 hover:cursor-pointer hover:shadow-sm"
      onClick={() => history.push(`/lists/${list.id}`)}
    >
      <div className="Left">
        <h3 className="text-2xl cursor-pointer">{list.name}</h3>
        <div className="text-black flex flex-row items-center">
          <div className="flex flex-col items-start">
            <div className="flex items-center">
              <FiGift />
              &nbsp;
              {list.description?.trim().length !== 0
                ? list.description
                : 'Giftee'}
            </div>
            <div className="text-gray-500">
              {list.date
                ? formatDistance(
                    (list.date as FirebaseFirestore.Timestamp)?.toDate(),
                    new Date(),
                    {
                      addSuffix: true,
                    }
                  )
                : ''}
            </div>
          </div>
        </div>
      </div>
      <div className="Right">
        <div className="flex flex-row items-center justify-flex-end">
          <button
            onClick={deleteGiftee}
            className="bg-transparent flex min-h-8 flex-row py-1 px-2 mx-1 items-center justify-center text-sm text-primary-400 hover:text-primary-500"
          >
            <FiTrash className="stroke-current" />
          </button>
          <button
            className="bg-transparent flex min-h-8 flex-row py-1 px-2 mx-1 items-center justify-center text-sm text-primary-400 hover:text-primary-500"
            onClick={shop}
          >
            <FiShoppingBag className="stroke-current" />
            <div className="ml-2">Shop</div>
          </button>
        </div>
      </div>
    </div>
  );
};
