import React, { useEffect, useState } from 'react';
import { firestore, firebase } from '@giftery/firebase';
import { WithId, Product } from '@giftery/api-interface';
import { DBCollection, DBDoc, ETrackingType } from '@giftery/enums';
import { useInView } from 'react-intersection-observer';
import { format } from 'date-fns';
/* eslint-disable-next-line */
export interface TrackerProps {
  type: ETrackingType;
  product: WithId<Product>;
}

export const Tracker = ({ type, product }: TrackerProps) => {
  const [tracked, setTracked] = useState(false);
  const track = async () => {
    const increment = firebase.firestore.FieldValue.increment(1);
    const productRef = firestore
      .collection(DBCollection.products)
      .doc(product.id);
    // get the current month
    const today = new Date();
    const statsDateId = format(today, 'yyyy-MM');
    const statsMonthlyRef = firestore
      .collection(DBCollection.suppliers)
      .doc(product.supplierId)
      .collection(DBCollection.stats)
      .doc(statsDateId);
    const statsTotalRef = firestore
      .collection(DBCollection.suppliers)
      .doc(product.supplierId)
      .collection(DBCollection.stats)
      .doc(DBDoc.total);
    const batch = firestore.batch();
    batch.set(
      productRef,
      {
        counters: {
          [type]: increment,
        },
      },
      { merge: true }
    );
    batch.set(statsMonthlyRef, { [type]: increment }, { merge: true });
    batch.set(statsTotalRef, { [type]: increment }, { merge: true });
    await batch.commit();
  };

  useEffect(() => {
    // On mount, log tracking
    if (!product || tracked) return;
    setTracked(true);
    track();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  return null;
};
