import { isValidEmail } from '@giftery/utils';
import React, { useEffect, useState, Fragment, useMemo } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { FiArrowLeft, FiPlus } from 'react-icons/fi';
import { LoadingButton } from '@giftery/ui/loading-button';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { format } from 'date-fns';
import { enNZ } from 'date-fns/locale';
import 'react-day-picker/lib/style.css';
import './CreateGifteeModal.scss';
import { createList } from '../../../actions/Lists';
import CreatableSelect from 'react-select/creatable';
import toast from 'react-hot-toast';
import { Wizard, WizardStep } from 'react-wizard-primitive';
import { colors, selectStyleFactory } from '@giftery/theme';
import { Tag, TagsInput } from '@giftery/ui/tagsinput';
import { useProductMetadata } from '../../../hooks';

interface CreateGifteeModalProps {
  open: boolean;
  className?: string;
  initialDate: Date | null;
  toggle: () => void;
  onClose: () => void;
}

export const CreateGifteeModal: React.FC<CreateGifteeModalProps> = ({
  open,
  className,
  initialDate,
  ...props
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [age, setAge] = useState<number>(null);
  const [interests, setInterests] = useState<string[]>([]);
  const [description, setDescription] = useState<string>('');
  const [date, setDate] = useState<Date>();
  const [categories, hobbies] = useProductMetadata();

  const interestList = useMemo((): Tag[] => {
    return [...(categories || []), ...(hobbies || [])].map((i) => ({
      id: i.id,
      name: i.name,
    }));
  }, [categories, hobbies]);

  useEffect(() => {
    if (!initialDate) return;
    setDate(initialDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDate]);

  const create = async () => {
    try {
      setLoading(true);
      const newList = {
        name,
        description,
        users: [email],
        date,
        interests,
        age,
      };
      await createList(newList);
      props.toggle();
    } catch (err) {
      toast.error(
        `Unable to create list: ${err.message || 'An unknown error occurred'}`
      );
    } finally {
      setLoading(false);
    }
  };

  const validate = () => {
    return name.trim() !== '' && email.trim() !== '' && isValidEmail(email);
  };

  // TODO: Eventually populate this from the DB
  const occasionOptions = [
    { label: 'Birthday', value: 'Birthday' },
    { label: 'Christmas', value: 'Christmas' },
    { label: 'Anniversary', value: 'Anniversary' },
  ];

  const updateOccasion = (value) => {
    if (value.toLowerCase() === 'christmas') {
      const now = new Date();
      setDate(new Date(now.getFullYear(), 11, 25));
    }
    setDescription(value);
  };

  const updateInterests = (tags: Tag[]) => {
    setInterests(tags.map((t) => t.id));
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        auto-reopen="true"
        className="fixed z-50 inset-0 overflow-y-auto p-0 md:p-6"
        onClose={props.toggle}
      >
        <div className="flex items-start md:items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className="
              inline-block align-middle bg-white px-6 pt-12 pb-4 text-left w-full md:w-3/4 lg:w-1/2 overflow-hidden shadow-xl transform transition-all my-8"
            >
              {/* Body */}
              <Wizard>
                <WizardStep routeTitle="basic">
                  {({ isActive, nextStep }) => {
                    return (
                      isActive && (
                        <>
                          <div>
                            <h2 className="text-2xl py-2 flex items-center justify-start">
                              Add A Giftee
                            </h2>
                            <div>
                              <div className="w-full">
                                <div>
                                  <label className="my-0">
                                    <div className="font-bold">Their Name</div>

                                    <small>
                                      The name of the person you want to send a
                                      gift to.
                                    </small>
                                  </label>
                                  <input
                                    name="Name"
                                    className="my-2 border-2 border-primary-200 p-2 w-full focus:outline-none focus:border-primary-500"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                  />
                                </div>
                                <div>
                                  <label className="my-0">
                                    <div className="font-bold">
                                      Special Date
                                    </div>
                                    <small>
                                      We will send them their list a couple of
                                      weeks beforehand so their gift can arrive
                                      by this date.
                                    </small>
                                  </label>
                                  <DayPickerInput
                                    onDayChange={setDate}
                                    value={date}
                                    inputProps={{
                                      className:
                                        'my-2 border-2 border-primary-200 p-2 w-full focus:outline-none focus:border-primary-500',
                                    }}
                                    formatDate={(d) =>
                                      format(d, 'dd LLLL', { locale: enNZ })
                                    }
                                  />
                                </div>
                                <div>
                                  <label className="my-0">
                                    <div className="font-bold">Occasion</div>
                                    <small>
                                      We'll use this to track different events
                                      you may list for the same person
                                    </small>
                                  </label>
                                  <CreatableSelect
                                    placeholder="Occasion"
                                    className="mb-4"
                                    styles={selectStyleFactory('primary')}
                                    onChange={(value) =>
                                      updateOccasion(value.value)
                                    }
                                    options={occasionOptions}
                                  />
                                </div>
                                <div>
                                  <label className="my-0">
                                    <div className="font-bold">Interests</div>
                                    <small>
                                      Tell us a bit about your giftee and what
                                      interests they have
                                    </small>
                                  </label>
                                  <TagsInput
                                    containerClassName="my-2 border-2 border-primary-200 p-2 w-full focus:outline-none focus:border-primary-500"
                                    tagClassName="lowercase"
                                    autocompleteData={interestList}
                                    onChange={updateInterests}
                                    placeholder="Add an interest..."
                                  />
                                </div>
                                <div>
                                  <label className="my-0">
                                    <div className="font-bold">Email</div>
                                    <small>Their email address</small>
                                  </label>
                                  <input
                                    name="ListDescription"
                                    type="email"
                                    className="my-2 border-2 border-primary-200 p-2 w-full focus:outline-none focus:border-primary-500"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="py-6 flex justify-end items-center">
                            <button
                              className="text-xl py-2 px-6 text-primary-500"
                              onClick={() => props.toggle()}
                            >
                              Cancel
                            </button>{' '}
                            <button
                              disabled={!validate()}
                              className="text-xl py-2 px-6 bg-primary-500 text-white disabled:pointer-events-none disabled:opacity-50"
                              onClick={() => nextStep()}
                            >
                              Next
                            </button>
                          </div>
                        </>
                      )
                    );
                  }}
                </WizardStep>
                <WizardStep routeTitle="basic">
                  {({ isActive, previousStep, goToStep }) => {
                    return (
                      isActive && (
                        <>
                          <div>
                            <h2 className="text-2xl py-2 flex items-center justify-start">
                              <button
                                className="text-xl pr-2 text-primary-500"
                                onClick={() => previousStep()}
                              >
                                <FiArrowLeft />
                              </button>
                              Add A Giftee
                            </h2>
                            <div>
                              <div className="w-full">
                                <div>
                                  <label className="my-0">
                                    <div className="font-bold">Age</div>
                                    <small>Their age (optional)</small>
                                  </label>
                                  <input
                                    type="number"
                                    name="ListAge"
                                    className="my-2 border-2 border-primary-200 p-2 w-full focus:outline-none focus:border-primary-500"
                                    value={age}
                                    onChange={(e) =>
                                      setAge(
                                        e.target.value.trim() !== ''
                                          ? parseInt(e.target.value)
                                          : null
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="py-6 flex justify-end items-center">
                            <button
                              className="text-xl py-2 px-4 text-primary-500"
                              onClick={() => {
                                props.toggle();
                                goToStep(0);
                              }}
                            >
                              Cancel
                            </button>{' '}
                            <LoadingButton
                              className="text-xl py-2 px-4 bg-primary-500 text-white flex items-center justify-center"
                              onClick={() => create()}
                              loading={loading}
                              disabled={!validate()}
                              feedback="Creating Giftee.."
                            >
                              <FiPlus /> Create Giftee
                            </LoadingButton>
                          </div>
                        </>
                      )
                    );
                  }}
                </WizardStep>
              </Wizard>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
