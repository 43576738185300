import {
  applyMiddleware,
  compose,
  createStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import loggerMiddleware from 'redux-logger';
import rootReducer from './reducers';
import { History } from 'history';

const DEBUG = process.env.DEBUG || false;

export const configureAppStore = (history: History) => {
  const middlewares = [
    ...getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: {
        warnAfter: 128,
      },
    }),
  ];
  if (DEBUG) middlewares.unshift(loggerMiddleware);
  const createStoreWithMiddleware = compose(applyMiddleware(...middlewares))(
    createStore
  );

  return createStoreWithMiddleware(rootReducer(history));
};
