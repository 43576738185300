import { Product, WithId } from '@giftery/api-interface';
import React, { useEffect, useState } from 'react';
import ProductItem from './ProductItem';
import './ProductList.scss';
import { ProductsPageLoader } from './ProductsPageLoader';
import { getProductCount } from '../../../actions/Metadata';
import { WhereOptions } from 'react-redux-firebase';
import { convertFirebaseFiltersToAlgoliaFilters } from '@giftery/algolia-search';
import { PaginationDirection } from '../ProductsPage';
interface ProductListProps {
  className?: string;
  products: WithId<Product>[];
  limit: number;
  page: number;
  loading: boolean;
  orderBy: string;
  filters: string[];
  query: string;
  onPage: (cursor?: any) => void;
}

const ProductList = ({
  products,
  className,
  page,
  limit,
  loading,
  query,
  orderBy,
  filters,
  ...props
}: ProductListProps) => {
  const [totalProductCount, setTotalProductCount] = useState(0);
  useEffect(() => {
    // get the product count from the algolia index for browsing
    // to prevent having to maintain distributed counters
    const getMetadataAsync = async () => {
      const algoliaFilters = filters
        ? convertFirebaseFiltersToAlgoliaFilters([
            ['filters', 'array-contains', filters],
          ])
        : undefined;
      const { data: metadata } = await getProductCount(algoliaFilters);
      setTotalProductCount(metadata.total);
    };
    getMetadataAsync();
  }, [filters]);

  const canGoNext = () => {
    const maxInPage = Math.min(limit * page, totalProductCount);
    return maxInPage < totalProductCount;
  };

  const canGoPrev = () => {
    return page > 1;
  };

  const renderPagination = () => {
    return (
      <nav
        className="bg-white px-4 py-3 flex items-center justify-between
        border-t border-gray-200 sm:px-6
        col-span-2 sm:col-span-2 md:col-span-2 lg:col-span-4 xl:col-span-6
        "
        aria-label="Pagination"
      >
        <div className="hidden sm:block">
          <p className="text-sm text-gray-700 my-0">
            Showing{' '}
            <span className="font-bold">
              {Math.max((page - 1) * limit + 1, 1)}
            </span>{' '}
            to{' '}
            <span className="font-bold">
              {Math.min(limit * page, totalProductCount)}
            </span>{' '}
            of <span className="font-bold">{totalProductCount}</span> products
          </p>
        </div>
        <div className="flex-1 flex justify-between sm:justify-end">
          <button
            type="button"
            onClick={() => props.onPage(PaginationDirection.prev)}
            className="ml-0 sm:ml-3 relative inline-flex items-center
                      px-4 py-2 text-sm font-medium
                      text-primary-500 hover:bg-primary-50
                      disabled:pointer-events-none disabled:bg-gray-100 disabled:opacity-50
                      "
            disabled={!canGoPrev()}
          >
            Previous
          </button>
          <div className="block sm:hidden flex items-center">
            <p className="text-sm text-gray-700 my-0 text-center">
              <span className="font-bold">{limit * page - limit + 1}</span> to{' '}
              <span className="font-bold">
                {Math.min(limit * page, totalProductCount)}
              </span>{' '}
              of <span className="font-bold">{totalProductCount}</span>
            </p>
          </div>
          <button
            type="button"
            onClick={() => props.onPage(PaginationDirection.next)}
            disabled={!canGoNext()}
            className="ml-3 relative inline-flex items-center
                      px-4 py-2 text-sm font-medium
                      text-primary-500 hover:bg-primary-50
                      disabled:pointer-events-none disabled:bg-gray-100 disabled:opacity-50"
          >
            Next
          </button>
        </div>
      </nav>
    );
  };

  const renderNoResults = () => {
    if (products.length > 0) return null;
    return (
      <div className="search-results-empty-state">
        <h1 className="font-normal text-2xl text-primary-500">
          No Results found for your query "{query}"
        </h1>
      </div>
    );
  };

  const renderLoading = () => {
    return (
      <div className="loading-state">
        <ProductsPageLoader width="100%" height="100%" />
      </div>
    );
  };

  const renderProducts = () => {
    if (products.length < 0) return renderLoading();
    return (
      <div className={className}>
        {products
          .filter((p) => {
            return p.variants?.[0]?.price > 0;
          })
          .map((product) => (
            <ProductItem key={product.id} product={product} />
          ))}
      </div>
    );
  };

  return (
    <>
      {loading ? null : renderNoResults()}
      {loading ? renderLoading() : renderProducts()}
      {renderPagination()}
    </>
  );
};

export default ProductList;
