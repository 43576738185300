import { Address, BillingAddress } from '@giftery/api-interface';
import { classNames, isValidEmail } from '@giftery/utils';
import { isEmpty, find, includes, noop } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Place } from '@googlemaps/google-maps-services-js';
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from 'react-google-places-autocomplete';
import { colors } from '@giftery/theme';
import { useMe } from '../../hooks';
import { AddressSummary } from './AddressSummary';

interface CheckoutAddressProps {
  buttonText: string;
  onSave: (details: BillingAddress) => void;
  address?: BillingAddress;
  sameAsCheck?: boolean;
}

export const CheckoutAddress: React.FC<CheckoutAddressProps> = ({
  address: existingAddress,
  buttonText,
  sameAsCheck,
  ...props
}) => {
  const [address, setAddress] = useState(
    existingAddress?.address.address || ''
  );
  const [city, setCity] = useState(existingAddress?.address.city || '');
  const [postcode, setPostcode] = useState(
    existingAddress?.address.postcode || ''
  );
  const [region, setRegion] = useState(existingAddress?.address.region || '');
  const [firstName, setFirstName] = useState(
    existingAddress?.person.firstName || ''
  );
  const [lastName, setLastName] = useState(
    existingAddress?.person.lastName || ''
  );
  const [email, setEmail] = useState(existingAddress?.person.email || '');
  const [country, setCountry] = useState(
    existingAddress?.address.country || 'New Zealand'
  );
  const [sameAsBilling, setSameAsBilling] = useState<boolean>(false);

  const me = useMe();

  const [googlePlace, setGooglePlace] = useState<{
    label: string;
    value: Place;
  }>();

  const findAddressComponent = (
    addressComponents: google.maps.GeocoderAddressComponent[],
    type: string | string[]
  ) => {
    return find(addressComponents, (component) =>
      includes(component.types, type)
    );
  };

  useEffect(() => {
    if (googlePlace && googlePlace.value.place_id) {
      geocodeByPlaceId(googlePlace.value.place_id)
        .then((results) => {
          const addressComponents = results[0]?.address_components;
          if (!addressComponents) return;
          // Extract address details from results
          // 1. Get Address
          const streetNumber = findAddressComponent(
            addressComponents,
            'street_number'
          );
          const streetName = findAddressComponent(addressComponents, 'route');
          if (streetNumber && streetName) {
            setAddress(`${streetNumber.long_name} ${streetName.long_name}`);
          } else if (!streetNumber && streetName) {
            setAddress(`${streetName.long_name}`);
          } else if (streetNumber && !streetName) {
            setAddress(`${streetName.long_name}`);
          }

          // 2. Get city
          const city =
            findAddressComponent(addressComponents, 'locality') ||
            findAddressComponent(addressComponents, 'political');
          setCity(city?.long_name ?? null);
          // 3. Get postcode
          const postcode = findAddressComponent(
            addressComponents,
            'postal_code'
          );
          setPostcode(postcode?.long_name ?? null);
          // 4. Get Region
          const region = findAddressComponent(
            addressComponents,
            'administrative_area_level_1'
          );
          console.warn('region', region, region?.long_name);
          console.warn('city', city, city?.long_name);
          setRegion(region?.long_name ?? null);
        })
        .catch((error) => console.error(error));
    }
  }, [googlePlace]);

  const save = (e?: React.FormEvent, overrideAddress?: Address) => {
    e?.preventDefault();

    const stateAddress: Address = {
      address,
      city,
      postcode,
      region,
      country,
      isRural: false,
    };
    const payload = {
      address: overrideAddress || stateAddress,
      person: {
        firstName,
        lastName,
        email,
      },
    };
    props.onSave(payload);
  };

  const validate = () => {
    const isInValid =
      isEmpty(address) ||
      isEmpty(city) ||
      isEmpty(postcode) ||
      isEmpty(firstName) ||
      isEmpty(lastName) ||
      isEmpty(email) ||
      !isValidEmail(email) ||
      isEmpty(region);
    return !isInValid;
  };

  const getAddressLabel = () => {
    return `${address}, ${city}, ${region} ${postcode}`;
  };

  const useAccountAddress = (e?: React.FormEvent) => {
    setAddress(me.address.address);
    setCity(me.address.city);
    setPostcode(me.address.postcode);
    setRegion(me.address.region);
    setCountry(me.address.country);
    if (!validate()) return;
    save(e, me.address);
  };

  const getAddressSummary = (): BillingAddress => {
    const addressSummary: BillingAddress = {
      person: {
        firstName,
        lastName,
        email,
      },
      address: {
        address,
        city,
        postcode,
        region,
        country,
        isRural: false,
      },
    };
    return addressSummary;
  };

  const renderAddressInputs = () => {
    return (
      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 px-0">
        <div className="sm:col-span-4">
          <label
            htmlFor="email"
            className="block text-sm font-medium text-primary-500"
          >
            Email address
          </label>
          <div className="mt-1">
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="
                py-2 px-0 shadow-none outline-none
                border-l-0 border-t-0 border-r-0 border-b border-primary-300
                focus:border-primary-500 focus:outline-none focus:shadow-none focus:ring-0
                block w-full sm:text-xl"
            />
          </div>
        </div>
        <div className="sm:col-span-3">
          <label
            htmlFor="first_name"
            className="block text-sm font-medium text-primary-500"
          >
            First name
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="first_name"
              id="first_name"
              autoComplete="first-name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              className="
                py-2 px-0 shadow-none outline-none
                border-l-0 border-t-0 border-r-0 border-b border-primary-300
                focus:border-primary-500 focus:outline-none focus:shadow-none focus:ring-0
                block w-full sm:text-xl"
            />
          </div>
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="last_name"
            className="block text-sm font-medium text-primary-500"
          >
            Last name
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="last_name"
              id="last_name"
              autoComplete="last-name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className="
                py-2 px-0 shadow-none outline-none
                border-l-0 border-t-0 border-r-0 border-b border-primary-300
                focus:border-primary-500 focus:outline-none focus:shadow-none focus:ring-0
                block w-full sm:text-xl"
            />
          </div>
        </div>
        <div className="sm:col-span-3">
          <label
            htmlFor="first_name"
            className="block text-sm font-medium text-primary-500 mb-2"
          >
            Address{' '}
            {me?.address && (
              <button
                className="bg-primary-500 px-2 py-1 text-white hover:bg-primary-400 text-sm"
                onClick={useAccountAddress}
                type="button"
              >
                Use my account address
              </button>
            )}
          </label>
          <div>
            <AddressSummary
              stepNumber={null}
              stepTitle={null}
              address={getAddressSummary()}
              onEdit={noop}
              className="p-0"
              showHeader={false}
              showPerson={false}
            />
          </div>
          <GooglePlacesAutocomplete
            apiOptions={{ language: 'en', region: 'NZ' }}
            autocompletionRequest={{
              componentRestrictions: {
                country: ['nz'],
              },
            }}
            apiKey="AIzaSyBOt_oMTA05lsT5SgmOFs27iXokXZJAKJo"
            selectProps={{
              googlePlace,
              onChange: setGooglePlace,
              placeholder: 'Search for address...',
              styles: {
                container: (existing) => ({
                  ...existing,
                  boxShadow: 'none',
                }),
                dropdownIndicator: () => ({
                  display: 'none',
                }),
                indicatorSeparator: () => ({
                  display: 'none',
                }),
                control: (existing) => ({
                  ...existing,
                  borderRadius: 0,
                  borderWidth: '0 0 1px 0',
                  borderColor: colors.primary[300],
                  boxShadow: 'none',
                  'input:hover': {
                    borderColor: colors.primary[500],
                    boxShadow: 'none',
                  },
                  'input:focus': {
                    outline: 'none',
                    borderColor: colors.primary[500],
                    boxShadow: 'none',
                  },
                }),
                singleValue: () => ({
                  outline: 'none',
                  border: 'none',
                  boxShadow: 'none',
                }),
                input: (value) => ({
                  ...value,
                  borderRadius: 0,
                  outline: 0,
                  border: 0,
                  boxShadow: 'none',
                  minWidth: '100px',
                  fontSize: '1.25rem',
                  lineHeight: '1.75rem',
                  'input:hover': {
                    outline: 'none',
                    border: 'none',
                    boxShadow: 'none',
                  },
                  'input:focus': {
                    outline: 0,
                    borderWidth: 0,
                    boxShadow: 'none',
                    borderColor: colors.primary[500],
                  },
                }),
              },
              openMenuOnClick: false,
              noOptionsMessage: () => 'Start typing to find an address',
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <form className="space-y-8" onSubmit={save}>
      <div className={classNames(sameAsBilling ? '' : 'pt-2 md:pt-8')}>
        {!sameAsBilling ? renderAddressInputs() : null}
        {sameAsCheck && (
          <div className="sm:col-span-6">
            <div className="relative flex items-start py-3">
              <div className="flex items-center h-5">
                <input
                  id="comments"
                  aria-describedby="comments-description"
                  name="comments"
                  type="checkbox"
                  checked={sameAsBilling}
                  onChange={() => setSameAsBilling(!sameAsBilling)}
                  className="focus:ring-primary-500 h-5 w-5 text-primary-600 border-gray-300"
                />
              </div>
              <div className="ml-3 text-sm">
                <label htmlFor="comments" className="font-medium text-gray-700">
                  Same as billing address
                </label>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <button
            disabled={!validate()}
            type="submit"
            className="
            ml-3 inline-flex justify-center py-2 px-4
            text-white bg-primary-500
            hover:bg-primary-600 focus:outline-none
            disabled:opacity-50 disabled:pointer-events-none"
          >
            {buttonText}
          </button>
        </div>
      </div>
    </form>
  );
};
