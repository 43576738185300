import { colors } from '@giftery/theme';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Loader from 'react-loader-spinner';
import { useCustomerCharges } from '../../hooks';

export const OrderConfirmationPage = () => {
  const [charges, loading] = useCustomerCharges();

  const lastCharge = charges[0];

  const renderOrderConfirmation = () => {
    return (
      <div className="relative rounded-theme px-6 py-10  overflow-hidden sm:px-12 sm:py-20">
        <div aria-hidden="true" className=""></div>
        <div className="relative">
          <div className="sm:text-center flex flex-col">
            <h2 className="text-3xl font-extrabold text-primary tracking-tight sm:text-4xl text-primary-500">
              Order Confirmation
            </h2>
            <p className="mt-6 mx-auto max-w-2xl text-lg text-primary-400">
              Woohoo! Your order has been placed. Your order number is{' '}
              <strong>{lastCharge?.order.id ?? ''}</strong>.
            </p>
            <p className="mx-auto max-w-2xl text-lg text-primary-400">
              You will receive an email confirmation shortly with your order
              details.
            </p>
            <a
              href="/"
              className="mt-6 flex-shrink-0 border border-transparent px-5 py-3 bg-primary-500 font-medium text-white hover:bg-primary-400 focus:outline-none w-96 inline-block mx-auto"
            >
              Continue Shopping
            </a>
          </div>
        </div>
      </div>
    );
  };

  const renderLoading = () => {
    return (
      <div className="relative rounded-theme px-6 py-10  overflow-hidden sm:px-12 sm:py-20">
        <div aria-hidden="true" className=""></div>
        <div className="relative">
          <div className="sm:text-center flex flex-col">
            <div className="mx-auto mb-6">
              <Loader type="Oval" color={colors.primary[500]} />
            </div>
            <h2 className="text-3xl font-extrabold text-primary tracking-tight sm:text-4xl text-primary-500">
              Confirming your order...
            </h2>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="mx-auto px-6 md:px-20">
      <Helmet>
        <title>The Giftery | Order Confirmed</title>
      </Helmet>
      <div className="bg-transparent py-16 sm:py-24">
        <div className="relative sm:py-16">
          <div className="mx-auto max-w-md sm:max-w-3xl lg:max-w-7xl">
            {loading ? renderLoading() : renderOrderConfirmation()}
          </div>
        </div>
      </div>
    </div>
  );
};
