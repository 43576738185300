import { Review, User, WithId } from '@giftery/api-interface';
import { DBCollection } from '@giftery/enums';
import { map, chain } from 'lodash';
import { useSelector } from 'react-redux';
import { useFirestoreConnect, populate } from 'react-redux-firebase';
import { RetailRootState } from '../store/reducers';

export function useReviews(
  id: string,
  limit = 3
): [WithId<Review & { user: WithId<User> }>[], number] {
  const populates = [{ child: 'user', root: DBCollection.users }];
  const path = 'productReviews';
  useFirestoreConnect([
    {
      collection: DBCollection.reviews,
      where: ['ref', '==', id || 'TEMP_PLACEHOLDER'],
      populates,
      limit,
      storeAs: path,
    },
  ]);
  const {
    productReviews,
  }: { productReviews: Record<string, Review & { user: WithId<User> }> } =
    useSelector((state: RetailRootState) => {
      return {
        productReviews: populate(state.firestore, path, populates),
      };
    });
  const reviews: WithId<Review & { user: WithId<User> }>[] =
    map(productReviews, (review, id) => ({ ...review, id })) || [];
  const rating = reviews
    ? chain(reviews)
        .map((r) => r.rating)
        .sum()
        .value() / reviews.length
    : 0;
  return [reviews, rating];
}
