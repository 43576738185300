import React from 'react';
import { Helmet } from 'react-helmet';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';

export const GiftConverted = () => {
  return (
    <div className="mx-auto px-6 md:px-20">
      <Helmet>
        <title>The Giftery | Gift Converted</title>
      </Helmet>
      <div className="bg-transparent py-16 sm:py-24">
        <div className="relative sm:py-16">
          <div className="mx-auto max-w-md sm:max-w-3xl lg:max-w-7xl">
            <div className="bg-primary-100 relative rounded-theme px-6 py-10  overflow-hidden sm:px-12 sm:py-20">
              <div className="relative">
                <div className="sm:text-center">
                  <h2 className="text-3xl font-extrabold text-primary tracking-tight sm:text-4xl text-primary-500">
                    Whoops!
                  </h2>
                  <p className="mt-6 mx-auto max-w-2xl text-lg text-primary-400">
                    Your gift has already been converted to a gift voucher.
                    Please check your inbox for your voucher code.
                  </p>
                </div>
              </div>
              <div className="flex items-center justify-center">
                <Link
                  to="/"
                  className="bg-primary-500 hover:bg-primary-400 text-white px-6 py-4 mt-8"
                >
                  Continue Shopping
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
