import { DBCollection } from '@giftery/enums';
import { useShowcase } from '../../../hooks';
import React from 'react';
import { ProductShowcaseVendor } from '../ProductShowcaseVendor';
import { ProductShowcaseName } from '../ProductShowcaseVendorName';

interface ProductShowcaseProps {
  isSearching: boolean;
  collection: DBCollection.categories | DBCollection.suppliers;
  values: string[];
}

export const ProductsShowcase: React.FC<ProductShowcaseProps> = ({
  isSearching,
  collection,
  values,
}) => {
  const [products, loading] = useShowcase(collection, values);
  if (loading) return <div>loading</div>;

  if (isSearching) return null;
  return (
    <div className="bg-primary-100 p-8 p-4 col-span-12 mt-4 mx-2">
      <div className="grid grid-cols-10 col-span-12">
        {values?.map((showcaseGroup) => (
          <ProductShowcaseName
            key={showcaseGroup}
            loading={loading}
            supplierId={showcaseGroup}
          />
        ))}
        {values?.map((showcaseGroup) => (
          <ProductShowcaseVendor
            key={showcaseGroup}
            loading={loading}
            products={products[showcaseGroup]}
            supplierId={showcaseGroup}
          />
        ))}
      </div>
    </div>
  );
};
