import { Product, WithId, User, Review } from '@giftery/api-interface';
import { DBCollection } from '@giftery/enums';
import { useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { RetailRootState } from '../store/reducers';
import { useReviews } from './UseReviews';

export function useProduct(id: string): [
  WithId<Product>,
  WithId<
    Review & {
      user: WithId<User>;
    }
  >[],
  number,
  boolean
] {
  useFirestoreConnect([
    {
      collection: DBCollection.products,
      doc: id,
      storeAs: `gift_choice_${id}`,
    },
  ]);
  const product = useSelector((state: RetailRootState) => {
    const product = state.firestore.data?.[
      `gift_choice_${id}`
    ] as WithId<Product>;
    return product && product.name ? { ...product, id } : null;
  });

  const [reviews, rating] = useReviews(product?.id);

  const loading = !isLoaded(product) || !isLoaded(reviews);
  return [product || null, reviews || null, rating || null, loading];
}
