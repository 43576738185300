import { combineReducers, Reducer } from 'redux';
import { connectRouter } from 'connected-react-router';
import { firebaseReducer } from 'react-redux-firebase';
import { FirestoreReducer, firestoreReducer } from 'redux-firestore';
import { Entity, FirestoreSchema, RootState } from '@giftery/ui/interfaces';
import { Product, ShoppingCart } from '@giftery/api-interface';
import { History } from 'history';

export interface RetailFireStoreSchema extends FirestoreSchema {
  productsList: Entity<Product>;
  cart: Entity<ShoppingCart>;
  [key: string]: unknown;
}

export interface RetailRootState extends RootState {
  firestore: FirestoreReducer.Reducer<RetailFireStoreSchema>;
}

const rootReducer = (history: History) =>
  combineReducers<RetailRootState>({
    router: connectRouter(history) as Reducer,
    firebase: firebaseReducer,
    firestore: firestoreReducer,
  });

export default rootReducer;
