import { Supplier } from '@giftery/api-interface';
import { DBCollection } from '@giftery/enums';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { RetailRootState } from '../store/reducers';

export function useStore(id: string): Supplier {
  useFirestoreConnect([
    {
      collection: DBCollection.suppliers,
      doc: id || 'TEMP_PLACEHOLDER',
    },
  ]);
  const suppliers = useSelector((state: RetailRootState) => {
    return state.firestore.data.suppliers?.[id] || null;
  });
  return suppliers;
}
