import React, { useState } from 'react';
import Content from './Content';
import Drawer from 'rc-drawer';
import './AppWithNavbar.scss';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { DBCollection, DBDoc } from '@giftery/enums';
import { Navbar } from './Navbar/Navbar';
import { connect } from 'react-redux';
import { RootState } from '@giftery/ui/interfaces';
import { Cart } from '../components/Cart/Cart';
import { useHistory } from 'react-router-dom';
import { RestrictedSplash } from '@giftery/ui/restricted-splash';
import { Menu } from './Menu';
interface AppWithNavbarProps {
  component: React.FC | React.ComponentClass;
}

const AppWithNavbar: React.FC<AppWithNavbarProps> = (
  props: AppWithNavbarProps
) => {
  const [ageConfirmed, setAgeConfirmed] = useState(
    localStorage.getItem('giftery-age-confirmation') || false
  );
  const [checkoutOpen, setCheckoutOpen] = useState<boolean>(false);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const { component: ChildComponent, ...rest } = props;
  const history = useHistory();
  let autoCloseTimeout: NodeJS.Timeout | undefined;

  const toggleCheckout = (state: boolean, autoClose?: boolean) => {
    setCheckoutOpen(state);
    if (autoCloseTimeout) clearTimeout(autoCloseTimeout);
    if (state && autoClose) {
      autoCloseTimeout = setTimeout(() => setCheckoutOpen(false), 2000);
    }
  };

  history.listen(() => {
    setTimeout(() => setCheckoutOpen(false), 0);
  });

  const confirmUserAge = () => {
    localStorage.setItem('giftery-age-confirmation', '1');
    setAgeConfirmed(true);
  };

  if (!ageConfirmed) return <RestrictedSplash onConfirm={confirmUserAge} />;

  return (
    <div className="AppWithNavbar">
      <Drawer
        open={checkoutOpen}
        placement="top"
        width="100vw"
        wrapperClassName="fixed top-0 left-0 right-0 z-10"
      >
        <Cart close={() => setCheckoutOpen(false)} />
      </Drawer>
      <Drawer
        open={menuOpen}
        placement="left"
        width="250px"
        wrapperClassName="fixed top-0 left-0 bottom-0 z-10"
      >
        <Menu onClose={() => setMenuOpen(false)} />
      </Drawer>
      <Navbar
        toggleCheckout={(state, autoClose) => toggleCheckout(state, autoClose)}
        toggleMenu={() => setMenuOpen(!menuOpen)}
        checkoutOpen={checkoutOpen}
        menuOpen={menuOpen}
      />
      <Content>
        <ChildComponent {...rest} />
      </Content>
    </div>
  );
};

export default compose<React.ComponentType<AppWithNavbarProps>>(
  connect((state: RootState) => {
    return { userId: state.firebase?.auth.uid || null };
  }),
  firestoreConnect(({ userId }: { userId: string }) => {
    const base = [
      {
        collection: DBCollection.categories,
      },
      {
        collection: DBCollection.hobbies,
      },
      {
        collection: DBCollection.ages,
      },
      {
        collection: DBCollection.genders,
      },
    ];
    if (!userId) return base;
    return [
      ...base,
      {
        collection: DBCollection.lists,
        where: ['owner', '==', userId],
      },
      {
        collection: DBCollection.users,
        doc: userId,
        storeAs: 'me',
      },
      {
        collection: DBCollection.users,
        subcollections: [
          {
            collection: DBCollection.cart,
            doc: DBDoc.cart,
          },
        ],
        doc: userId,
        storeAs: 'cart',
      },
    ];
  })
)(AppWithNavbar);
