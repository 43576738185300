import { Product, WithId } from '@giftery/api-interface';
import { DBCollection } from '@giftery/enums';
import { useSelector } from 'react-redux';
import { flatten } from 'lodash';
import {
  isLoaded,
  ReduxFirestoreQuerySetting,
  useFirestoreConnect,
  WhereOptions,
} from 'react-redux-firebase';
import { RetailRootState } from '../store/reducers';

export function useShowcase(
  collection: DBCollection,
  values: string[],
  max = 5
): [Record<string, WithId<Product>[]>, boolean] {
  const getWhereClause = (value: string): WhereOptions[] => {
    switch (collection) {
      case DBCollection.categories:
        return [
          ['status.active', '==', true],
          ['status.deleted', '==', false],
          ['status.inStock', '==', true],
          ['categories', 'array-contains', value],
        ];
      case DBCollection.suppliers:
        return [
          ['status.active', '==', true],
          ['status.deleted', '==', false],
          ['status.inStock', '==', true],
          ['supplierId', '==', value],
        ];
      default:
        throw new Error(
          'Unable to showcase without a collection ref or value!'
        );
    }
  };
  const generateQuery = (value: string): ReduxFirestoreQuerySetting => {
    return {
      collection: DBCollection.products,
      where: getWhereClause(value),
      orderBy: ['counters.views', 'desc'],
      storeAs: `productsShowcase.${value}`,
      limit: 5,
    };
  };
  useFirestoreConnect(values.map(generateQuery));
  const productsRaw = useSelector((state: RetailRootState) => {
    const products = values.map((value) => {
      const ordered = state.firestore.ordered[`productsShowcase.${value}`];
      return (ordered as unknown as WithId<Product>[]) || [];
    });
    return flatten(products);
  });

  const products = {};

  const assignCategories = (product: WithId<Product>) => {
    product.categories.map((category) => {
      if (!values.includes(category)) return;
      if (!products[category]) products[category] = [];
      products[category].push(product);
    });
  };

  const assignSupplier = (product: WithId<Product>) => {
    const supplierId = product.supplierId;
    if (!products[supplierId]) products[supplierId] = [];
    if (products[supplierId].length >= max) return;
    products[supplierId].push(product);
  };

  // Group products by each of the showcase items
  if (productsRaw) {
    productsRaw.map((product) => {
      switch (collection) {
        case DBCollection.categories:
          return assignCategories(product);
        case DBCollection.suppliers:
          return assignSupplier(product);
        default:
          return null;
      }
    });
  }

  const loading = !isLoaded(products);
  return [products || null, loading];
}
