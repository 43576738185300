import React from 'react';
import { BillingAddress } from '@giftery/api-interface';
import { FiEdit2 } from 'react-icons/fi';
import { classNames } from '@giftery/utils';

interface AddressSummaryProps {
  address: BillingAddress;
  stepNumber: string;
  stepTitle: string;
  onEdit: () => void;
  showHeader?: boolean;
  showPerson?: boolean;
  className?: string;
}

export const AddressSummary: React.FC<AddressSummaryProps> = ({
  address,
  stepNumber,
  stepTitle,
  showHeader = true,
  showPerson = true,
  className,
  ...props
}) => {
  const renderHeader = () => {
    if (!showHeader) return null;
    return (
      <div className="flex items-center justify-between">
        <div>
          <h1 className="inline-block text-primary-500 text-2xl md:text-3xl font-serif font-thin">
            {stepNumber}. {stepTitle}
          </h1>
        </div>
        <button
          type="button"
          className="text-primary-500 text-xl py-2 px-4 flex items-center"
          onClick={props.onEdit}
        >
          <FiEdit2 className="stroke-current" /> Edit
        </button>
      </div>
    );
  };

  const renderPerson = () => {
    if (!showPerson) return null;
    return (
      <>
        <div className="text-primary-400 text-xl font-serif">
          {address.person.firstName} {address.person.lastName}
        </div>
        <div className="text-primary-400">{address.person.email}</div>
      </>
    );
  };

  const renderAddress = () => {
    if (!address.address.address) return null;
    return (
      <div className="text-primary-400">
        {`${address.address.address}, ${address.address.city}, ${address.address.region}, ${address.address.postcode}, ${address.address.country}`}
      </div>
    );
  };

  if (!address) return null;
  return (
    <div
      className={classNames(className ? className : 'bg-primary-100 p-8 mb-4')}
    >
      {renderHeader()}
      <div className="py-3 pl-2">
        {renderPerson()}
        {renderAddress()}
      </div>
    </div>
  );
};
