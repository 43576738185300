import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { RootState } from '@giftery/ui/interfaces';

import 'react-nice-input-password/dist/react-nice-input-password.css';
import { PageLoader } from '@giftery/ui/page-loader';
import { LoginForm } from './components/LoginForm';
import { SignupForm } from './components/SignupForm';

export enum LoginState {
  login = 'login',
  signup = 'signup',
  forgotPassword = 'forgotPassword',
}
const LoginPage: React.FC = () => {
  const [state, setState] = useState<LoginState>(LoginState.login);
  const [error, setError] = useState('');
  const history = useHistory();
  const user = useSelector((state: RootState) => state.firebase.profile);
  useEffect(() => {
    if (user.isLoaded && user.email) {
      history.push('/products');
    }
  }, [history, user]);

  const renderLoginForm = () => {
    return (
      <LoginForm
        onError={(error) => setError(error.message)}
        onChangeState={(state) => setState(state)}
      />
    );
  };

  const renderSignupForm = () => {
    return (
      <SignupForm
        onError={(error) => console.error(error)}
        onChangeState={(state) => setState(state)}
      />
    );
  };

  const renderForgotPasswordForm = () => {
    return <div>Forgot password</div>;
  };

  const renderState = () => {
    switch (state) {
      case LoginState.login:
        return renderLoginForm();
      case LoginState.signup:
        return renderSignupForm();
      case LoginState.forgotPassword:
        return renderForgotPasswordForm();
      default:
        return <PageLoader />;
    }
  };

  return (
    <div className="LoginComponent">
      <Helmet>
        <title>The Giftery | Login</title>
      </Helmet>
      {renderState()}
    </div>
  );
};

export default LoginPage;
