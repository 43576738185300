import {
  BillingAddress,
  Product,
  ProductVariant,
  User,
  WithId,
} from '@giftery/api-interface';
import { PageLoader } from '@giftery/ui/page-loader';
import { RadioGroup } from '@headlessui/react';
import { CheckIcon, ThumbUpIcon, UserIcon } from '@heroicons/react/outline';
import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useRouteMatch } from 'react-router';
import { useOrder } from '../../hooks';
import { ProductItem } from './components/ProductItem';
import { selectOrderProduct } from '../../actions/Orders';
import { GiftAlreadySelected } from './GiftAlreadySelected';
import { CheckoutAddress } from '../Checkout/CheckoutAddress';
import { GiftConverted } from './GiftConverted';
import { ExtendedGiftSelection } from './components/ExtendedGiftSelection/ExtendedGiftSelection';
import { useFirebase } from 'react-redux-firebase';
import { exchangeToken } from '../../actions/Auth';
import { LoadingButton } from '@giftery/ui/loading-button';
import toast from 'react-hot-toast';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
const GiftSelectionPage = () => {
  const { params } = useRouteMatch<{ id: string; token: string }>();
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState<WithId<Product>>(null);
  const [selectedVariant, setSelectedVariant] = useState<ProductVariant>();
  const [gifter, setGifter] = useState<User>();
  const [address, setAddress] = useState<BillingAddress>();
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const order = useOrder(params.id);
  const firebase = useFirebase();
  useEffect(() => {
    if (order) {
      if (order?.giftGiver && !gifter)
        setGifter(order.giftGiver as unknown as User);
      if (order.logistics.shipping && !address)
        setAddress(order.logistics.shipping);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, gifter]);

  useEffect(() => {
    const init = async () => {
      if (params.token) {
        const { data } = await exchangeToken(params.token.trim());
        await firebase.auth().signInWithCustomToken(data);
      }
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!order || !order.products) return <PageLoader />;

  if (order.status.voucher) return <GiftConverted />;

  if (order.status.selected) return <GiftAlreadySelected />;

  const validate = () => {
    return !selected || !address || !selectedVariant;
  };

  const makeSelection = async () => {
    setLoading(true);
    const promise = selectOrderProduct(
      params.id,
      selected.id,
      selectedVariant,
      address
    );
    await toast.promise(promise, {
      loading: 'Sit tight...',
      success: `Success, we're getting your gift ready!`,
      error: `Oh no! Something went wrong with selecting your gift, please try again.`,
    });
    setLoading(false);
  };

  const convertGift = async () => {
    setShowMoreOptions(true);
  };

  const timeline = [
    {
      id: 1,
      content: `Step 1`,
      target: `${
        gifter?.displayName ?? 'The person who sent you this'
      } knows you, and selected a list of gifts they think you may like.`,
      icon: UserIcon,
      iconBackground: 'bg-primary-200',
    },
    {
      id: 2,
      content: 'Step 2',
      target:
        'You received an email with this link, and you can choose any item on the list, already paid for.',
      icon: ThumbUpIcon,
      iconBackground: 'bg-primary-200',
    },
    {
      id: 3,
      content: 'Step 3',
      target:
        "Once you've made your selection, your gift is dispatched to you!",
      icon: CheckIcon,
      iconBackground: 'bg-primary-500',
    },
  ];

  const onSelectVariant = (variant: ProductVariant) => {
    setSelectedVariant(variant);
  };

  const updateSelected = (selected: WithId<Product>) => {
    setSelected(selected);

    if (selected.variants) {
      setSelectedVariant(selected.variants[0]);
    }
    if (selected.variant) {
      setSelectedVariant(selected.variant as ProductVariant);
    }
  };

  const calculateOrderAmount = (): number => {
    const { products } = order;
    let total = 0;
    // There are 2 main ways we will charge customers:
    // 1. We are authorizing and sending a list
    // Find the most expensive item in the cart
    const prices = map(products, (p) => p.price * p.quantity);
    total = Math.max(...prices);

    // We want it in dollars, stripe charges in the
    // smallest common currency unit
    return parseFloat(total.toFixed(2));
  };

  const onSelectAlternateGift = (p: WithId<Product>) => {
    updateSelected(p);
  };

  const renderMoreOptions = () => {
    return (
      <div className="mx-auto px-6 md:px-20">
        <ExtendedGiftSelection
          selected={selected?.id ?? null}
          orderValue={calculateOrderAmount()}
          onSelect={onSelectAlternateGift}
        />
      </div>
    );
  };

  const renderInitialOptions = () => {
    return (
      <div className="mx-auto px-6 md:px-20">
        <h1 className="text-3xl font-serif font-light text-primary-500">
          Select a gift
        </h1>
        <RadioGroup value={selected} onChange={updateSelected} className="my-4">
          <div className="bg-white -space-y-px grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {map(order?.products, (product) => {
              const isSelected = selected?.id === product.id;
              return (
                <RadioGroup.Option
                  key={product.id}
                  value={product}
                  className={({ active }) =>
                    classNames(
                      'relative block border-2 mb-2 border-transparent shadow-none outline-none ring-0',
                      'bg-primary-100 px-6 py-4 cursor-pointer',
                      'flex flex-col justify-between focus:outline-none',
                      isSelected ? 'border-2 border-primary-500' : ''
                    )
                  }
                >
                  {({ checked, active }) => (
                    <>
                      <ProductItem
                        product={product}
                        checked={isSelected}
                        active={active}
                        onSelectVariant={onSelectVariant}
                      />
                      {checked}
                      {active}
                    </>
                  )}
                </RadioGroup.Option>
              );
            })}
          </div>
        </RadioGroup>
        <div className="py-4">
          <h3 className="text-xl text-gray-400 font-normal">
            Nothing on this list takes your fancy?
          </h3>
          <button
            onClick={convertGift}
            className="bg-transparent text-primary-500 py-2 px-0"
          >
            Choose something else instead.
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="mx-auto px-6 md:px-20">
        <Helmet>
          <title>The Giftery | Select Your Gift</title>
        </Helmet>
        <div className="grid gap-4 grid-cols-12">
          <div className="col-span-12 order-1 md:order-1 md:col-span-4">
            <h1 className="text-3xl font-light text-primary-500">
              How does this work?
            </h1>

            <ul className="p-6">
              {timeline.map((event, eventIdx) => (
                <li key={event.id}>
                  <div className="relative pb-8">
                    {eventIdx !== timeline.length - 1 ? (
                      <span
                        className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                        aria-hidden="true"
                      />
                    ) : null}
                    <div className="relative flex space-x-3 items-center">
                      <div>
                        <span
                          className={classNames(
                            event.iconBackground,
                            'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
                          )}
                        >
                          <event.icon
                            className="h-5 w-5 text-white"
                            aria-hidden="true"
                          />
                        </span>
                      </div>
                      <div className="min-w-0 flex-1 pt-1.5 pl-3 flex justify-between space-x-4">
                        <div>
                          <div className="font-normal font-serif text-2xl text-primary-500">
                            {event.content}
                          </div>
                          <div className="font-normal font-sans text-md text text-gray-500">
                            {event.target}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex flex-col col-span-12 md:col-span-8 order-2 md:order-2">
            <h1 className="text-3xl font-serif font-light text-primary-500">
              Where would you like your gift delivered?
            </h1>

            <div>
              {address?.address?.address && (
                <div className="">
                  <p>{address.address.address}</p>
                  <p>
                    {address.address.city}, {address.address.postcode}
                  </p>
                  <p>{address.address.country}</p>
                </div>
              )}
              <CheckoutAddress
                address={address}
                onSave={(a) => setAddress(a)}
                buttonText={
                  address?.address?.address
                    ? 'Save Address'
                    : 'Continue To Gift Selection'
                }
              />
            </div>
          </div>
        </div>
      </div>
      {address?.address.address && renderInitialOptions()}
      {address?.address.address && showMoreOptions ? renderMoreOptions() : null}
      {selected ? (
        <div className="fixed bottom-0 left-0 right-0 bg-white p-8 flex items-center justify-between z-50 shadow-2xl  px-6 md:px-20">
          <div>
            <p className="text-primary-500 text-md m-0">You've selected:</p>
            <p className="text-primary-500 text-xl m-0 font-bold">
              {selected.name}
            </p>
          </div>
          <div>
            <LoadingButton
              className="bg-primary-500 py-4 px-8 text-xl text-white hover:bg-primary-400"
              onClick={() => makeSelection()}
              disabled={!validate() || loading}
              loading={loading}
              feedback="Sit tight..."
            >
              Send Me My Gift
            </LoadingButton>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default GiftSelectionPage;
