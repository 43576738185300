import React from 'react';

const testimonials = [
  {
    text: 'Choosing gifts is the hardest part! So glad I found The Giftery, my client loved their options.',
    author: 'Lucy Kirkwood',
    color: 'gray-500',
  },
  {
    text: 'Is this website literally the most handy platform I have ever seen? I think so!',
    author: 'Emily Arnold',
    color: 'gray-500',
  },
  {
    text: 'The Giftery is genius. I have only had the best feedback from the amazing selection on here',
    author: 'Denise Arnold',
    color: 'gray-500',
  },
];

const Testimonials = () => {
  return (
    <div className="h-512px min-w-screen flex flex-col md:flex-row p-10 bg-white mx-auto px-2 md:px-20">
      {testimonials.map((t, i) => {
        return (
          <div
            key={`testimonial-${i}`}
            className={`text-${t.color} grid grid-col-3 grid-gap-4 p-5`}
          >
            {/* <h1 className="text-8xl font-serif font-medium my-0 leading-none">
              "
            </h1> */}
            <h2 className="text-3xl font-serif font-thin">{t.text}</h2>
            <p className="text-xl font-sans font-bold mt-3">{t.author}</p>
          </div>
        );
      })}
    </div>
  );
};

export default Testimonials;
