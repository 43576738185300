import { List, Product, WithId } from '@giftery/api-interface';
import { RootState } from '@giftery/ui/interfaces';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { AiFillHeart } from 'react-icons/ai';
import { firestore } from '@giftery/firebase';
import { DBCollection } from '@giftery/enums';
import { FirestoreReducer } from 'redux-firestore';
import { remove, uniq } from 'lodash';
import Loader from 'react-loader-spinner';
import { colors } from '@giftery/theme';
import './PopoverWishList.scss';

interface PopOverWishListProps {
  product: WithId<Product>;
}

export const PopOverWishList: React.FC<PopOverWishListProps> = ({
  product,
}) => {
  const [loading, setLoading] = useState<string>(null);
  const allLists = useSelector(
    (state: RootState) => state.firestore.ordered.lists
  );

  const addToList = async (list: FirestoreReducer.EntityWithId<List>) => {
    setLoading(list.id);
    const existingProducts = list.products || [];
    const exists = existingProducts.includes(product.id);
    const update = {
      products: exists
        ? remove(existingProducts, product.id)
        : uniq([...existingProducts, product.id]),
    };
    await firestore.collection(DBCollection.lists).doc(list.id).update(update);
    setLoading(null);
  };

  return (
    <div className="PopoverWishListAdder">
      <div className="PopoverWishlists">
        {allLists?.map((list) => {
          return (
            <div
              key={list.id}
              className="group PopoverWishlistItem flex items-center"
              onClick={() => addToList(list)}
            >
              {loading === list.id ? (
                <Loader
                  type="Oval"
                  width={16}
                  height={16}
                  color={colors.primary[500]}
                />
              ) : list.products?.includes(product.id) ? (
                <AiFillHeart className="fill-current text-primary-500" />
              ) : (
                <AiFillHeart className="fill-current stroke-current text-primary-200 group-hover:text-primary-400" />
              )}
              &nbsp;{list.name}
            </div>
          );
        }) ?? null}
      </div>
    </div>
  );
};
