import { Review, WithId } from '@giftery/api-interface';
import React from 'react';
import Rating from 'react-rating';
import { AiFillStar } from 'react-icons/ai';
import { formatDistance } from 'date-fns';

interface ProductReviewProps {
  review: WithId<Review>;
}

const ProductReview: React.FC<ProductReviewProps> = ({ review }) => {
  return (
    <div className="mb-3 text-grey-800">
      <div className="flex flex-row items-center">
        <Rating
          start={0}
          stop={5}
          step={1}
          readonly={true}
          initialRating={review.rating}
          emptySymbol={
            <AiFillStar
              href="#icon-star-empty"
              className="fill-current text-grey-300"
            />
          }
          fullSymbol={
            <AiFillStar
              href="#icon-star-full"
              className="fill-current text-primary-500"
            />
          }
        />
        <div className="ml-3 text-grey-600">
          {formatDistance(
            (review.audit.createdAt as FirebaseFirestore.Timestamp).toDate(),
            new Date(),
            { addSuffix: true }
          )}
        </div>
      </div>
      <h3 className="text-2xl font-bold">{review.title}</h3>
      <p className="font-normal">{review.description}</p>
    </div>
  );
};

export default ProductReview;
