import React from 'react';
import { Logo } from '@giftery/ui/logo';
import './ui-restricted-splash.scss';

/* eslint-disable-next-line */
export interface RestrictedSplashProps {
  onConfirm: () => void;
}

export function RestrictedSplash(props: RestrictedSplashProps) {
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 bg-primary-100">
      <div className="flex flex-col items-center justify-center w-full h-full">
        <Logo className="text-primary-500" />
        <div className="grid grid-cols-1 gap-4 py-20 text-center">
          <p className="text-2xl font-bold">
            You are about to enter our website, where we sell restricted items
            like alcohol.
          </p>
          <p className="text-xl">
            In order to purchase these items, you will need to be over the age
            of 18 and provide photo ID on delivery.
          </p>
          <p className="text-xl">Are you over 18?</p>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <button
            onClick={props.onConfirm}
            className="border-2 border-primary-500 bg-primary-500 text-white py-3 px-6"
          >
            I am aged 18 or over
          </button>
          <button
            onClick={() => (window.location.href = 'https://google.com')}
            className="border-2 border-primary-500 text-primary-500 py-3 px-6"
          >
            I am under 18
          </button>
        </div>
      </div>
    </div>
  );
}

export default RestrictedSplash;
