import React, { useState } from 'react';
import { Logo } from '@giftery/ui/logo';
import { Gifter } from '@giftery/ui/gifter';
import { FaGoogle, FaFacebook, FaTwitter } from 'react-icons/fa';
import { Link, useHistory } from 'react-router-dom';
import { useFirebase } from 'react-redux-firebase';
import { LoginState } from '../Login';
import { useSelector } from 'react-redux';
import { RootState } from '@giftery/ui/interfaces';

interface LoginFormProps {
  onChangeState: (state: LoginState) => void;
  onError: (error: Error) => void;
}

export const LoginForm: React.FC<LoginFormProps> = ({ ...props }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const firebase = useFirebase();
  const history = useHistory();
  const user = useSelector((state: RootState) => state.firebase.profile);

  const renderErrors = () => {
    if (!error) return null;
    return <p className="bg-white text-primary-500 p-4 mb-4">{error}</p>;
  };

  const loginWithGoogle = async () => {
    await firebase.login({ provider: 'google', type: 'popup' });
  };

  const loginWithTwitter = async () => {
    await firebase.login({ provider: 'twitter', type: 'popup' });
  };

  const loginWithFacebook = async () => {
    try {
      await firebase.login({ provider: 'facebook', type: 'popup' });
    } catch (err) {
      if (err.credential && user) {
        await firebase.linkWithCredential(err.credential);
      } else {
        setError(err.message);
      }
    }
  };

  const login = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const res = await firebase.login({
        email,
        password,
      });
      if (res.user) return history.push('/');
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-0 pb-4 md:py-12 px-4 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md max-h-48 md:max-h-auto flex flex-col items-center justify-center">
        <Gifter className="fill-current text-primary-500 max-w-36 w-36" />
        <Logo
          className="fill-current text-primary-500 h-12 max-h-12"
          color="primary-500"
        />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-primary-500">
          Sign in to your account
        </h2>
        <p className="mt-2 text-center font-thin text-sm text-primary-500">
          Looking for the vendor login?{' '}
          <a
            className="font-bold"
            href="https://supplier.thegiftery.co.nz/login"
          >
            Click here.
          </a>
        </p>
      </div>

      <div className="mt-2 md:mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-primary-100 py-8 px-4 sm:px-10">
          <form className="space-y-6" onSubmit={login}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-primary-500"
              >
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  onChange={(e) => setEmail(e.target.value || '')}
                  placeholder="you@youremail.co.nz"
                  className="appearance-none block w-full px-3 py-2 border-0 border-b-2 border-primary-300 placeholder-primary-200 focus:outline-none focus:ring-0 focus:border-primary-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-primary-500"
              >
                Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  placeholder="************"
                  onChange={(e) => setPassword(e.target.value || '')}
                  className="appearance-none block w-full px-3 py-2 border-0 border-b-2 border-primary-300 placeholder-primary-200 focus:outline-none focus:ring-0 focus:border-primary-500 sm:text-sm"
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="text-sm">
                <button
                  type="button"
                  onClick={() => props.onChangeState(LoginState.signup)}
                  className="font-medium text-primary-600 hover:text-primary-500"
                >
                  Sign up
                </button>
              </div>

              <div className="text-sm">
                <button
                  type="button"
                  onClick={() => props.onChangeState(LoginState.forgotPassword)}
                  className="font-medium text-primary-600 hover:text-primary-500"
                >
                  Forgot your password?
                </button>
              </div>
            </div>

            <div>
              {renderErrors()}
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
              >
                Sign in
              </button>
            </div>
          </form>

          <div className="mt-6">
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-primary-300" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-primary-100 text-primary-500">
                  Or continue with
                </span>
              </div>
            </div>

            <div className="mt-6 grid grid-cols-3 gap-3">
              <div>
                <button
                  type="button"
                  onClick={loginWithFacebook}
                  className="w-full inline-flex justify-center py-2 px-4 border border-primary-300 bg-white text-sm font-medium text-primary-500 hover:bg-primary-600 hover:text-white"
                >
                  <span className="sr-only">Sign in with Facebook</span>
                  <FaFacebook className="w-5 h-5" />
                </button>
              </div>
              <div>
                <button
                  type="button"
                  onClick={loginWithGoogle}
                  className="w-full inline-flex justify-center py-2 px-4 border border-primary-300 bg-white text-sm font-medium text-primary-500 hover:bg-primary-600 hover:text-white"
                >
                  <span className="sr-only">Sign in with Google</span>
                  <FaGoogle className="w-5 h-5" />
                </button>
              </div>
              <div>
                <button
                  type="button"
                  onClick={loginWithTwitter}
                  className="w-full inline-flex justify-center py-2 px-4 border border-primary-300 bg-white text-sm font-medium text-primary-500 hover:bg-primary-600 hover:text-white"
                >
                  <span className="sr-only">Sign in with Google</span>
                  <FaTwitter className="w-5 h-5" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
