/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useEffect, useState } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { BellIcon } from '@heroicons/react/outline';
import { Link, useLocation } from 'react-router-dom';
import { FirestoreReducer } from 'redux-firestore';
import { User } from '@giftery/api-interface';
import { storage } from '@giftery/firebase';
import { FiShoppingCart, FiX } from 'react-icons/fi';
import { validURL } from '@giftery/utils';
import { Logo } from '@giftery/ui/logo';
import { useCart, useMe } from '../../hooks';
import numeral from 'numeral';
import { isEmpty } from 'lodash';
import Hamburger from 'hamburger-react';
import profilePlaceholder from '../../images/profile-placeholder.png';
import { colors } from '@giftery/theme';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

interface MeProps {
  me: FirestoreReducer.EntityWithId<User>;
  className?: string;
}

const Me: React.FC<MeProps> = ({ className, me }) => {
  const [profilePic, setProfilePic] = useState<string>(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded && me) {
      getProfilePic();
      setLoaded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me]);
  const getProfilePic = async (image = me?.avatarUrl) => {
    if (image) {
      let imageUrl = image;
      if (!validURL(imageUrl)) {
        imageUrl = await storage.ref(`/images/${image}`).getDownloadURL();
      }
      setProfilePic(imageUrl);
    } else {
      setProfilePic(profilePlaceholder);
    }
  };
  return (
    <Menu as="div" className={classNames(className, 'ml-3 relative')}>
      {({ open }) => (
        <>
          <div>
            <Menu.Button className="bg-white flex text-sm flex flex-row items-center rounded-full focus:outline-none focus:bg-primary-50">
              <img className="h-8 w-8 rounded-full" src={profilePic} alt="" />
              <span className="ml-2 text-primary-400">My Account</span>
            </Menu.Button>
          </div>
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="z-top origin-top-right absolute right-0 mt-2 w-48 shadow-lg py-1 bg-white focus:outline-none"
            >
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to="/me"
                    className={classNames(
                      active ? 'bg-gray-100' : '',
                      'block px-4 py-2 text-sm text-gray-700 no-underline hover:text-primary-600'
                    )}
                  >
                    Profile
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to="/lists"
                    className={classNames(
                      active ? 'bg-gray-100' : '',
                      'block px-4 py-2 text-sm text-gray-700 no-underline hover:text-primary-600'
                    )}
                  >
                    My Giftees
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to="/logout"
                    className={classNames(
                      active ? 'bg-gray-100' : '',
                      'block px-4 py-2 text-sm text-gray-700 no-underline hover:text-primary-600'
                    )}
                  >
                    Sign out
                  </Link>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

interface NavbarProps {
  toggleCheckout: (state: boolean, autoClose?: boolean) => void;
  toggleMenu: () => void;
  checkoutOpen: boolean;
  menuOpen: boolean;
}

export const Navbar: React.FC<NavbarProps> = ({
  checkoutOpen,
  menuOpen,
  ...props
}) => {
  const me = useMe();
  const { cart, cartTotal } = useCart();
  const location = useLocation();

  return (
    <Disclosure as="nav" className="bg-white font-sans px-2 md:px-20 ">
      <div className="mx-auto pt-12 md:pt-16">
        <div className="fixed top-0 left-0 right-0 flex items-center justify-between h-16 my-3 mt-0 mb-0 md:mb-3 md:mt-0 bg-white z-50 px-2 md:px-20">
          <div className="flex items-center">
            <Hamburger
              size={24}
              color={colors.primary[500]}
              toggled={menuOpen}
              toggle={props.toggleMenu}
            />
            <Link to="/" className="pl-0 md:pl-2">
              <Logo
                color="primary-500"
                className="justify-start items-center text-center h-8 md:h-12 text-primary-500"
              />
            </Link>
          </div>
          <div className="flex-1 flex md:hidden items-center justify-end md:items-stretch md:justify-start">
            {/* Cart */}
            <button
              onClick={() => {
                window.scrollTo({ top: 0 });
                props.toggleCheckout(!checkoutOpen, isEmpty(cart?.products));
              }}
              className="relative bg-transparent p-1 text-primary-400
                    flex items-center
                    hover:text-primary-300 focus:bg-primary-50"
            >
              <span className="sr-only">View Cart</span>
              <FiShoppingCart className="h-6 w-6" aria-hidden="true" />
              {Object.keys(cart?.products || {}).length > 0 ? (
                <div className="rounded-full text-white bg-red-700 absolute bottom-0 left-6 w-4 h-4 text-xs text-center">
                  {Object.keys(cart?.products || {}).length}
                </div>
              ) : null}
              <div className="text-primary-300 flex items-center justify-end ml-6 font-bold">
                {numeral(cartTotal).format(`$0,0.00`)}
              </div>
            </button>
          </div>
          <div
            className="
              flex inset-y-0 right-0 flex items-center pr-2
              hidden md:flex"
          >
            <button className="bg-transparent p-1 rounded-full text-primary-400 hover:text-primary-300 focus:outline-none focus:bg-primary-50">
              <BellIcon className="h-6 w-6" aria-hidden="true" />
            </button>

            {me ? (
              <Me className="mr-4" me={me} />
            ) : (
              <div className="hidden md:block mr-4">
                <div className="flex space-x-4 justify-center">
                  <a
                    href="/login"
                    className={classNames(
                      'bg-transparent p-1 rounded-full text-primary-400 hover:text-primary-300 focus:outline-none focus:bg-primary-50'
                    )}
                  >
                    Sign In
                  </a>
                </div>
              </div>
            )}
            {/* Cart */}
            <button
              onClick={() => {
                window.scrollTo({ top: 0 });
                props.toggleCheckout(!checkoutOpen, isEmpty(cart?.products));
              }}
              className="relative bg-transparent p-1 text-primary-400
                    flex items-center
                    hover:text-primary-300 focus:bg-primary-50"
            >
              <span className="sr-only">View Cart</span>
              <FiShoppingCart className="h-6 w-6" aria-hidden="true" />
              {Object.keys(cart?.products || {}).length > 0 ? (
                <div className="rounded-full text-white bg-red-700 absolute bottom-0 left-6 w-4 h-4 text-xs text-center">
                  {Object.keys(cart?.products || {}).length}
                </div>
              ) : null}
              <div className="text-primary-300 flex items-center justify-end ml-2 font-bold">
                {numeral(cartTotal).format(`$0,0.00`)}
              </div>
            </button>
          </div>
        </div>
      </div>
    </Disclosure>
  );
};
