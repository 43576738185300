import { DBCollection } from '@giftery/enums';
import { useSelector } from 'react-redux';
import { useFirestoreConnect, populate } from 'react-redux-firebase';
import { RetailRootState } from '../store/reducers';

export function useList(id: string) {
  const populates = [
    { child: 'products', root: DBCollection.products },
    { child: 'owner', root: DBCollection.users },
  ];
  const collection = DBCollection.lists;
  useFirestoreConnect([
    {
      collection: DBCollection.lists,
      doc: id,
      populates,
      storeAs: 'selectionList',
    },
  ]);
  const { lists } = useSelector((state: RetailRootState) => {
    return {
      lists: populate(state.firestore, collection, populates),
    };
  });
  return lists?.[id] || null;
}
