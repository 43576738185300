import React from 'react';

/* eslint-disable-next-line */
export interface UiSquigglyProps {
  className?: string;
  width?: number;
  height?: number;
  color?: string;
}

export function Squiggly(props: UiSquigglyProps) {
  return (
    <svg
      className={`stroke-current fill-current max-w-full max-h-full text-${
        props.color || 'secondary-800'
      } ${props.className || ''}`}
      height={props.height || 96}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 92.25 20.67"
    >
      <path
        d="M129,46.71l-9.1-5.86a1.5,1.5,0,1,0-1.63,2.52l3.89,2.5a71.32,71.32,0,0,0-37.19-.72.61.61,0,0,0-.07-.14,9.94,9.94,0,0,0-5.94-4.55C65.12,36,48.62,39.76,37.88,49.8a1.5,1.5,0,0,0,2,2.19c10-9.32,25.27-12.8,38.07-8.67A7.62,7.62,0,0,1,82,46c-3,.94-6.8,2.7-8.46,6.78a5.57,5.57,0,0,0-.46,3.09,3.93,3.93,0,0,0,2,2.9,4.64,4.64,0,0,0,2.22.53,6.43,6.43,0,0,0,2.49-.52c3.51-1.46,5.05-5.53,5.55-7.21a9.93,9.93,0,0,0,.41-3.5,68.26,68.26,0,0,1,38.71,1.69l-5.84,5.09a1.5,1.5,0,1,0,2,2.26l6.22-5.41a12.23,12.23,0,0,0,2.69-3A1.49,1.49,0,0,0,129,46.71Zm-46.56,4c-.78,2.64-2.21,4.62-3.82,5.29a2.69,2.69,0,0,1-2.11.13,1,1,0,0,1-.47-.78,2.87,2.87,0,0,1,.27-1.45c1-2.3,2.94-3.86,6.43-5A7.29,7.29,0,0,1,82.41,50.7Z"
        transform="translate(-37.41 -38.61)"
      />
    </svg>
  );
}
