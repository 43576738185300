import { List, PopulatedList, WithId } from '@giftery/api-interface';
import { DBCollection } from '@giftery/enums';
import { useSelector } from 'react-redux';
import { useFirestoreConnect, populate } from 'react-redux-firebase';
import { RetailRootState } from '../store/reducers';
import { useMe } from './UseMe';
import { map } from 'lodash';

export function useLists(): WithId<PopulatedList>[] {
  const me = useMe();
  const populates = [
    { child: 'products', root: DBCollection.products },
    { child: 'owner', root: DBCollection.users },
  ];
  const collection = DBCollection.lists;
  useFirestoreConnect([
    {
      collection: DBCollection.lists,
      where: ['owner', '==', me?.id || 'LOADING'],
      populates,
      storeAs: collection,
    },
  ]);

  const { lists } = useSelector((state: RetailRootState) => {
    return {
      lists: populate(state.firestore, collection, populates),
    };
  });
  return lists ? map(lists, (list, id) => ({ ...list, id })) : null;
}
