import React from 'react';
import { Squiggly } from '@giftery/ui/squiggly';
import ReactPlayer from 'react-player';

const HeroExplainer = () => {
  const intViewportHeight = window.innerHeight;

  const navigateParent = (url) => {
    const location = window.parent?.location ?? window.location;
    location.href = url;
  };

  return (
    <div className="h-512px min-w-screen flex flex-col md:flex-row pt-3">
      <div className="bg-primary-50 min-h-full w-full md:w-1/2 flex flex-col items-end justify-center p-8 md:p-20">
        <h1 className="text-primary-500 text-6xl font-sans font-medium text-right max-w-screen-sm">
          We take the guesswork out of gift giving.
        </h1>
        <button
          onClick={() =>
            navigateParent('https://thegiftery.co.nz/how-it-works')
          }
          className="text-primary-500 text-xl font-sans mt-5 flex flex-row justify-end items-center underline inline w-full  text-right"
        >
          FIND OUT HOW IT WORKS{' '}
          <Squiggly className="ml-1" color="primary-500" height={18} />
        </button>
      </div>
      <div
        className="min-h-full w-full md:w-1/2 p-0	bg-primary-50 text-center flex flex-row justify-center items-center"
        style={{ backgroundColor: '#cfd9e2' }}
      >
        <ReactPlayer
          url="https://player.vimeo.com/video/590718408?h=25d46d15b0"
          controls={true}
          height={intViewportHeight / 2}
        />
      </div>
    </div>
  );
};

export default HeroExplainer;
