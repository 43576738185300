import { Product, WithId } from '@giftery/api-interface';
import React from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { Carousel } from 'react-responsive-carousel';
import ProductItem from './components/ProductItem';
import './ProductShowcaseVendor.scss';

interface ProductShowcaseVendorProps {
  supplierId: string;
  loading: boolean;
  products: WithId<Product>[];
}

export const ProductShowcaseVendor: React.FC<ProductShowcaseVendorProps> = ({
  supplierId,
  products,
  loading,
}) => {
  if (loading) return null;

  const renderArrowPrev = (
    clickHandler: () => void,
    hasPrev: boolean,
    label?: string
  ) => {
    return (
      <div className="absolute top-0 bottom-0 left-0 flex items-end z-50">
        <button
          className="px-4 pb-2 text-primary-200 hover:text-primary-500"
          onClick={clickHandler}
          disabled={!hasPrev}
        >
          <FiChevronLeft size={32} className="stroke-current" />
        </button>
      </div>
    );
  };

  const renderArrowNext = (
    clickHandler: () => void,
    hasNext: boolean,
    label?: string
  ) => {
    return (
      <div className="absolute top-0 bottom-0 right-0 flex items-end z-50">
        <button
          className="px-4 pb-2 text-primary-200 hover:text-primary-500 disabled"
          onClick={clickHandler}
          disabled={!hasNext}
        >
          <FiChevronRight size={32} className="stroke-current" />
        </button>
      </div>
    );
  };

  const renderItem = (item: React.ReactNode) => {
    return <div className="px-4 pb-4">{item}</div>;
  };

  return (
    <div
      className="grid grid-cols-12 col-span-10 md:col-span-2"
      key={supplierId}
    >
      <Carousel
        className="col-span-12 h-full"
        showThumbs={false}
        emulateTouch={true}
        showArrows={true}
        infiniteLoop={true}
        renderArrowPrev={renderArrowPrev}
        renderArrowNext={renderArrowNext}
        showStatus={false}
        renderItem={renderItem}
      >
        {products?.map((product) => (
          <ProductItem key={product.id} product={product} />
        ))}
      </Carousel>
    </div>
  );
};
