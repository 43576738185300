import { Product, WithId } from '@giftery/api-interface';
import React, { useState } from 'react';
import ProductItem from './ProductItem';
import './ProductList.scss';
import { classNames } from '@giftery/utils';
import { FiX } from 'react-icons/fi';
import { ProductsPageLoader } from './ProductsPageLoader';
import { SearchResponse } from '@algolia/client-search';
import { PaginationDirection } from '../ProductsPage';

interface ProductListProps {
  className?: string;
  results: SearchResponse<WithId<Product>>;
  limit: number;
  page: number;
  loading: boolean;
  query: string;
  onPage: (cursor?: any) => void;
  onClearSearch: () => void;
}

const ProductList = ({
  results,
  className,
  page,
  limit,
  loading,
  query,
  ...props
}: ProductListProps) => {
  const canGoNext = () => {
    const maxInPage = Math.min(limit * page, results.nbHits);
    return maxInPage < results.nbHits;
  };

  const canGoPrev = () => {
    return page > 1;
  };

  const renderSearchSummary = () => {
    return (
      <div className="w-full">
        <h1 className="text-2xl text-primary-500 m-0 pl-4 pt-4 md:pt-0 flex items-center">
          {results.nbHits} Results{' '}
          <button
            className="text-primary-500 ml-4 flex items-center justify-center text-xs"
            onClick={props.onClearSearch}
          >
            <FiX /> Clear Search
          </button>
        </h1>
      </div>
    );
  };

  const renderPagination = () => {
    return (
      <nav
        className="bg-white px-4 py-3 flex items-center justify-between
        border-t border-gray-200 sm:px-6
        col-span-2 sm:col-span-2 md:col-span-2 lg:col-span-4 xl:col-span-6
        "
        aria-label="Pagination"
      >
        <div className="hidden sm:block">
          <p className="text-sm text-gray-700 my-0">
            Showing{' '}
            <span className="font-bold">
              {Math.max((page - 1) * limit + 1, 1)}
            </span>{' '}
            to{' '}
            <span className="font-bold">
              {Math.min(limit * page, results.nbHits)}
            </span>{' '}
            of <span className="font-bold">{results.nbHits}</span> products
          </p>
        </div>
        <div className="flex-1 flex justify-between sm:justify-end">
          <button
            type="button"
            onClick={() => props.onPage(PaginationDirection.prev)}
            className="ml-0 sm:ml-3 relative inline-flex items-center
                      px-4 py-2 text-sm font-medium
                      text-primary-500 hover:bg-primary-50
                      disabled:pointer-events-none disabled:bg-gray-100 disabled:opacity-50
                      "
            disabled={!canGoPrev()}
          >
            Previous
          </button>
          <div className="block sm:hidden flex items-center">
            <p className="text-sm text-gray-700 my-0 text-center">
              <span className="font-bold">{limit * page - limit + 1}</span> to{' '}
              <span className="font-bold">
                {Math.min(limit * page, results.nbHits)}
              </span>{' '}
              of <span className="font-bold">{results.nbHits}</span>
            </p>
          </div>
          <button
            type="button"
            onClick={() => props.onPage(PaginationDirection.next)}
            disabled={!canGoNext()}
            className="ml-3 relative inline-flex items-center
                      px-4 py-2 text-sm font-medium
                      text-primary-500 hover:bg-primary-50
                      disabled:pointer-events-none disabled:bg-gray-100 disabled:opacity-50"
          >
            Next
          </button>
        </div>
      </nav>
    );
  };

  const renderNoResults = () => {
    if (results.hits.length > 0) return null;
    return (
      <div className="search-results-empty-state">
        <h1 className="font-normal text-2xl text-primary-500">
          No Results found for your query "{query}"
        </h1>
      </div>
    );
  };

  const renderLoading = () => {
    return (
      <div className="loading-state">
        <ProductsPageLoader width="100%" height="100%" />
      </div>
    );
  };

  const renderProducts = () => {
    if (results.hits.length < 0) return renderLoading();
    return (
      <div className={classNames(className, 'md:pt-0')}>
        {results.hits
          .filter((p) => {
            return p.variants?.[0]?.price > 0;
          })
          .map((product) => (
            <ProductItem key={product.id} product={product} />
          ))}
      </div>
    );
  };

  return (
    <>
      {renderSearchSummary()}
      {loading ? null : renderNoResults()}
      {loading ? renderLoading() : renderProducts()}
      {renderPagination()}
    </>
  );
};

export default ProductList;
