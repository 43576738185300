import React from 'react';
import { map } from 'lodash';
import { WithId, Product } from '@giftery/api-interface';
import { GiftChoice } from './GiftChoice';

interface ChoiceListProps {
  products: WithId<Product>[];
  selected: string;
  onSelect: (p: WithId<Product>) => void;
}

export const ChoiceList: React.FC<ChoiceListProps> = ({
  products,
  selected,
  ...props
}) => {
  return (
    <>
      {map(products, (p) => (
        <GiftChoice
          product={p}
          key={p.id}
          onSelect={props.onSelect}
          selected={selected}
        />
      ))}
    </>
  );
};
