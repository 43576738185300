import { Product, WithId } from '@giftery/api-interface';
import { useStore } from '../../../../../hooks';
import React, { useEffect, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { AiFillStar } from 'react-icons/ai';
import { FiStar } from 'react-icons/fi';
import { Tracker } from '@giftery/ui/tracking';
import { ETrackingType } from '@giftery/enums';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Gifter } from '@giftery/ui/gifter';
import { classNames, validURL } from '@giftery/utils';
import { storage } from '@giftery/firebase';

interface GiftChoiceProps {
  product: WithId<Product>;
  selected: string;
  onSelect: (p: WithId<Product>) => void;
}

const StoreLoader = (props) => {
  return (
    <ContentLoader viewBox="0 0 500 40" height={40} {...props}>
      <rect x="0" y="13" rx="0" ry="0" width={1060} height="12" />
    </ContentLoader>
  );
};

export const GiftChoice: React.FC<GiftChoiceProps> = ({
  product,
  selected,
  ...props
}) => {
  const [productImages, setProductImages] = useState<string[]>([]);
  const store = useStore(product.supplierId);

  const isSelected = selected === product.id;

  useEffect(() => {
    const getImage = async () => {
      const mainImage = product.images[0];
      if (!mainImage) return;
      let imageUrl = mainImage;
      if (!validURL(imageUrl)) {
        imageUrl = await storage.ref(`/images/${imageUrl}`).getDownloadURL();
      }
      setProductImages((existing) => [...existing, imageUrl]);
    };
    getImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderStore = () => {
    if (!store)
      return (
        <div className="relative overflow-hidden">
          <StoreLoader className="w-full px-6" />
        </div>
      );
    return (
      <div className="flex items-center justify-start text-left">
        <span
          className={classNames(
            isSelected ? 'text-primary-300' : 'text-gray-300',
            'text-gray-300 font-normal flex items-center justify-start text-left text-sm pt-2 capitalize'
          )}
        >
          {store?.tradingName || ''}&nbsp;
          <span className="flex items-center">
            (
            <AiFillStar className="fill-current text-primary-500" />{' '}
            {store?.rating?.toFixed(1) ?? '5.0'})
          </span>
        </span>
      </div>
    );
  };

  const renderFeaturedBadge = () => {
    if (!product.status.featured) return null;
    return (
      <div className="absolute z-30 flex flex-row w-1/3 items-center justify-center top-left bg-primary-500 p-1 -mx-2 top-8 text-white font-sans">
        <FiStar /> Featured
      </div>
    );
  };
  const productImage = productImages?.[0];

  return (
    <div
      onClick={() => props.onSelect(product)}
      className={classNames(
        isSelected
          ? 'bg-primary-100 border-2 border-primary-500'
          : 'bg-transparent border-2 border-transparent',
        'card relative flex flex-col transform duration-100 cursor-pointer justify-flex-start p-2 min-h-full hover:bg-primary-100'
      )}
    >
      {renderFeaturedBadge()}
      <Tracker type={ETrackingType.impression} product={product} />
      <div
        className={classNames(
          isSelected
            ? 'bg-primary-100 border-2 border-primary-200'
            : 'bg-transparent border-2 border-transparent hover:border-primary-100',
          'prod-img bg-white flex items-center justify-start overflow-hidden'
        )}
      >
        {productImage ? (
          // If the image we are creating here has the same src than before,
          // we can directly display it with no need to lazy-load.
          <LazyLoadImage
            placeholder={
              <Gifter className="p-8 h-72 max-h-72 bg-white w-full object-cover object-center stroke-current fill-current max-w-full max-h-full text-primary-400" />
            }
            wrapperClassName="w-full"
            effect="blur"
            alt={product.name}
            src={productImage}
            className="h-72 max-h-72 bg-white w-full object-cover bg-gray-50"
          />
        ) : (
          <Gifter className="p-8 h-72 max-h-72 bg-white w-full object-cover object-center stroke-current fill-current max-w-full max-h-full text-primary-400" />
        )}
      </div>
      <div className="py-2">
        <div className="ProductBadges relative"></div>
        <div className="prod-title">
          {renderStore()}
          <p
            className={classNames(
              isSelected ? 'text-primary-500' : 'text-gray-800',
              'text-left font-medium font-sans text-md pb-2 capitalize'
            )}
          >
            {product.name}
          </p>
        </div>
      </div>
    </div>
  );
};
