import { colors } from '@giftery/theme';
import React from 'react';
import ContentLoader from 'react-content-loader';
import { useStore } from '../../hooks';

interface ProductShowcaseVendorNameProps {
  supplierId: string;
  loading: boolean;
}

const ProductLoader = (props) => {
  return (
    <ContentLoader
      viewBox="0 0 800 35"
      backgroundColor={colors.primary[100]}
      foregroundColor={colors.primary[200]}
      {...props}
    >
      {/* Title + Subtitle */}
      <rect x="0" y="0" rx="4" ry="4" width="600" height="35" />
    </ContentLoader>
  );
};

export const ProductShowcaseName: React.FC<ProductShowcaseVendorNameProps> = ({
  supplierId,
  loading,
}) => {
  const store = useStore(supplierId);
  if (loading) return null;

  return (
    <div
      className="grid grid-cols-12 col-span-10 md:col-span-2"
      key={`${supplierId}-name`}
    >
      <h2 className="text-xl text-primary-500 capitalize col-span-12 px-6">
        {store?.tradingName || <ProductLoader />}
      </h2>
    </div>
  );
};
