import {
  BillingAddress,
  CartProduct,
  PopulatedList,
  Product,
  ProductVariant,
  ShoppingCart,
  Supplier,
  WithId,
} from '@giftery/api-interface';
import { DBCollection, DBDoc } from '@giftery/enums';
import { map, chain, sortBy, last, omit } from 'lodash';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { isLoaded, useFirestore } from 'react-redux-firebase';
import { RetailRootState } from '../store/reducers';
import { useMe } from './UseMe';

export function useCart() {
  const cartRef = useRef<FirebaseFirestore.DocumentReference>();
  const me = useMe();
  const firestore = useFirestore();

  useEffect(() => {
    if (!me) return;
    cartRef.current = firestore
      .collection(DBCollection.users)
      .doc(me.id)
      .collection(DBCollection.cart)
      // Weird but ok
      .doc(DBDoc.cart) as unknown as FirebaseFirestore.DocumentReference;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me]);

  const cart = useSelector(
    (state: RetailRootState) => state.firestore.ordered.cart?.[0] || null
  );

  const getCartTotal = () => {
    if (!cart) return;
    let total =
      chain(cart?.products)
        .map()
        .reduce((v, p) => (v += p.price * p.quantity || 0), 0)
        .value() || 0;

    // If we have a list, we will only show the largest amount?
    if (cart.listId && !cart.allowAll)
      total = chain(cart?.products)
        .map((p) => p.price * p.quantity)
        .max()
        .value();

    return total;
  };

  const incrementCartItem = async (id: string, increment: number) => {
    return await cartRef.current.set(
      {
        products: {
          [id]: {
            quantity: firestore.FieldValue.increment(increment),
          },
        },
      },
      { merge: true }
    );
  };

  const addToCart = async (
    product: WithId<Product>,
    variant: ProductVariant,
    store: Supplier
  ) => {
    if (cart) {
      const cartProductId = `${product.id}-${variant.type}`;
      const cartProduct: CartProduct = {
        price: variant?.price,
        quantity: cart.products[cartProductId]?.quantity
          ? cart.products[cartProductId]?.quantity + 1
          : 1,
        id: product.id,
        hasCommission: true,
        images: product.images,
        name: product.name,
        description: product.description,
        storeId: product.supplierId,
        variant,
        shipping: {
          product: {
            type: product.shipping?.type || null,
            override: product.shipping?.override || null,
          },
          supplier: store?.shipping,
        },
        isRestricted: product.isRestricted || false,
      };
      return cartRef.current.set(
        {
          products: {
            ...(cart.products || {}),
            [cartProductId]: cartProduct,
          },
        },
        { merge: true }
      );
    }
    return await cartRef.current.set({
      owner: me.id,
      address: {
        billing: null,
        shipping: null,
      },
      products: {
        [`${product.id}-${variant.type}`]: {
          price: variant?.price,
          quantity: 1,
          id: product.id,
          images: product.images,
          name: product.name,
          description: product.description,
          storeId: product.supplierId,
          variant,
        },
      },
      listId: null,
    });
  };

  const removeFromCart = async (product: CartProduct) => {
    const products = omit(cart.products, [
      product.variant
        ? `${product.id}-${product.variant.type}`
        : `${product.id}`,
    ]);

    const update: Partial<ShoppingCart> = {
      products,
    };
    if (Object.keys(products).length <= 0) {
      update.listId = null;
    }
    return await cartRef.current.update(update);
  };

  const clearCart = async () => {
    return await cartRef.current.update({
      products: [],
    });
  };

  const updateCartAddress = (
    type: 'billing' | 'shipping',
    address: BillingAddress,
    isGift: boolean
  ) => {
    cartRef.current.set(
      {
        logistics: {
          [type]: address,
        },
        listId: isGift ? cart.listId : null,
      },
      { merge: true }
    );
  };

  const updateCartVoucher = async (id: string | null) => {
    await cartRef.current.set(
      {
        voucherCode: id,
      },
      { merge: true }
    );
  };

  const updateCartList = async (id: string | null) => {
    await cartRef.current.set(
      {
        listId: id || null,
      },
      { merge: true }
    );
  };

  const allowAllItems = async (allowAll: boolean) => {
    await cartRef.current.set(
      {
        allowAll,
      },
      { merge: true }
    );
  };

  const findMostExpensiveVariant = (product: Product) => {
    const { variants } = product;
    const sorted = sortBy(variants, 'price');
    return last(sorted);
  };

  const populateCart = async (
    products: WithId<Product>[],
    list?: WithId<PopulatedList>
  ) => {
    return await cartRef.current.set({
      listId: list.id,
      products: chain(products)
        .map((product) => ({
          price: findMostExpensiveVariant(product).price,
          quantity: 1,
          id: product.id,
          images: product.images,
          name: product.name,
          description: product.description,
          storeId: product.supplierId,
        }))
        .keyBy('id')
        .value(),
    });
  };

  return {
    cart,
    loading: !isLoaded(cart) || !me,
    cartTotal: getCartTotal(),
    incrementCartItem,
    addToCart,
    removeFromCart,
    populateCart,
    clearCart,
    updateCartList,
    updateCartVoucher,
    updateCartAddress,
    allowAllItems,
  };
}
